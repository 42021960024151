import React, {useState, useEffect, forwardRef, useRef, useReducer} from 'react'
import {Markdown} from "./components/MarkdownUtils"
import {useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {closeDialog, ModalDialog} from "./components/DialogUtils"
import {ActionButton, FormBody, FormControl, FormGroup, FormGroups, FormText, useAlert} from "./components/FormComps"
import {pathReducer, simpleReducer} from "./components/ReactUtils.js"
import {useReactToPrint} from "react-to-print"
import {mkHref} from "./components/AccountUtils"
import CreditTransactionBill from "./CreditTransactionBill"
import getLogger from "./components/Logging.js"
import './App.css'
import {clearAllErrors, InfoBlock, InfoText, validateField} from "./components/ValidationUtils";

const log = getLogger("CreditsOfflinePaymentDialog")

const CreditsOfflinePaymentDialog = forwardRef((props, ref) => {

  const {api, session: {user}, refreshSignin, env, setFatal, setBusy} = useSolv()

  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert(null)

  const [creditTransaction, setCreditTransaction] = useState(null)
  const [purchaseOrderNo, setPurchaseOrderNo] = useState(null)
  const [billingInfo, updateBillingInfo] = useReducer(pathReducer, props.tenant?.billingInfo ?  props.tenant?.billingInfo : props.tenant ? {name: props.tenant.displayName, address: props.tenant.contactInfo} : {})
  const [bankAccounts, setBankAccounts] = useState(null)

  const markdown = new Markdown({
    homePage: props.tenant?.homePage,
    clickUrls: props.tenant?.clickUrls,
    allowedDomains: props.tenant?.allowedDomains,
    disallowedDomains: props.tenant?.disallowedDomains,
    allowLinks: props.tenant?.allowLinks || true,
  })

  const invoiceRef = useRef(null)
  const handlePrint = useReactToPrint({
    contentRef: invoiceRef,
    documentTitle: creditTransaction?.invoiceNo,
    onAfterPrint: handleAfterPrint
  });

  useEffect(() => {
    if (creditTransaction != null) {
      api.getBankAccountsByRegionId(creditTransaction.tenant?.regionId)
        .then(({data}) => {
          setBankAccounts(data)
        })
    }
  }, [creditTransaction])

  useEffect(() => {
    if (bankAccounts != null) {
      handlePrint()
    }
  }, [bankAccounts])

  function handleAfterPrint() {
    window.location = mkHref({suffix: "/credits", tenant: props.tenant, user: user})
  }

  async function handleRegisterClick() {

    if (validate()) {
      const payload = {
        paymentMethodId: "BANK",
        credits: props.credits,
        description: intl.msg("credit_transaction_description_register_purchase_credits"),
        purchaseOrderNo: purchaseOrderNo,
        billingInfo: billingInfo,
      }

      setBusy(intl.msg("working"))

      try {
        const {data} = await api.registerCreditTransaction(props.tenant.tenantId, payload)
        setCreditTransaction(data)
      }
      catch (error) {
        setAlert({error: intl.msg("error_failed")})
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_billingName", billingInfo?.name && billingInfo.name.trim().length > 0, "required", opt)
    validateField("inp_billingAddress", billingInfo?.address && billingInfo.address.trim().length > 0, "required", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  function handleOpenDialog() {
  }

  function handleCloseDialog() {
    closeDialog("dlg_credits_offline_payment")
  }

  function handlePurchaseOrderNoChange(e) {
    setPurchaseOrderNo(e.target.value)
  }

  function handleBillingNameChange(e) {
    updateBillingInfo({path: "name", value: e.target.value})
  }

  function handleBillingAddressChange(e) {
    updateBillingInfo({path: "address", value: e.target.value})
  }
  return (

    props.tenant && props.credits &&
      <>
        <ModalDialog
          dialogId="dlg_credits_offline_payment"
          size={props.size || "lg"}
          keyboard={"true"}
          backdrop={props.backdrop || "static"}
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}>

          <ModalDialog.Header
            title={intl.msg("credits_offline_payment_title")}
            description={intl.msg("credits_offline_payment_subtitle")}
            alert={alert}
          />

          <ModalDialog.Body>

            <div className="bank-transfer-instructions-container">
              {intl.msg("credits_bank_transfer_instructions", {contact_for_other_methods: (chunks) => <a className="text-link" href="mailto:support@solv.technology?subject=Offline+Payment">{chunks}</a>})}
            </div>

            <FormGroups text={intl.msg("additional_info")}>

              <FormGroup>
                <FormGroup.Label text={intl.msg("credit_transaction_purchase_order_no")} description={intl.msg("credit_transaction_purchase_order_no_description")}/>
                <FormGroup.Controls>
                  <input id="inp_bankAccountName" type="text" className="form-control" value={purchaseOrderNo} onChange={handlePurchaseOrderNoChange}/>
                  <InfoBlock>
                    <InfoText validate="optional">{intl.msg("helptext_optional")}</InfoText>
                  </InfoBlock>
                </FormGroup.Controls>
              </FormGroup>

              <FormGroup>
                <FormGroup.Label htmlFor="inp_billingName" text={intl.msg("credit_transaction_billing_name")} description={intl.msg("credit_transaction_billing_name_description")}/>
                <FormGroup.Controls>
                  <input id="inp_billingName" type="text" className="form-control" value={billingInfo?.name} onChange={handleBillingNameChange}/>
                  <InfoBlock>
                    <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                  </InfoBlock>
                </FormGroup.Controls>
              </FormGroup>

              <FormGroup>
                <FormGroup.Label htmlFor="inp_billingAddress" text={intl.msg("credit_transaction_billing_address")} description={intl.msg("credit_transaction_billing_address_description")}/>
                <FormGroup.Controls>
                  <textarea id="inp_billingAddress" className="form-control" value={billingInfo?.address} onChange={handleBillingAddressChange}/>
                  <InfoBlock>
                    <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                  </InfoBlock>
                </FormGroup.Controls>
              </FormGroup>

            </FormGroups>

          </ModalDialog.Body>

          <ModalDialog.Footer>
            <ModalDialog.Footer.Controls>
              <ActionButton name="cancel" onClick={handleCloseDialog}/>
              {
                !props.creditTransaction &&
                  <ActionButton text={intl.msg("register")} icon="ok" flavor="primary" onClick={handleRegisterClick}/>
              }
            </ModalDialog.Footer.Controls>
          </ModalDialog.Footer>

        </ModalDialog>

        <CreditTransactionBill
          ref={invoiceRef}
          tenant={props.tenant}
          creditTransaction={creditTransaction}
          // billingInfo={billingInfo}
          bankAccounts={bankAccounts}
          // purchaseOrderNo={purchaseOrderNo}
        />

      </>
  )
})

export default CreditsOfflinePaymentDialog;