import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {FormHeader, FormBody, MainContainer, FormGroup} from "../components/FormComps.js"
import {validateField, showError, clearAllErrors, InfoBlock, InfoText} from '../components/ValidationUtils.js';
import {simpleReducer} from "../components/ReactUtils";
import MainMenu from "../MainMenu"
import {default as Select} from "react-select";
import {AsyncPaginate, reduceGroupedOptions} from "react-select-async-paginate";
import {mkExtraParams} from "../components/TableComps";
import {brandOpts, environmentOpts, tenantPlanOpts, tenantTypeOpts} from "./PropsUtils";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Prop")

export default function Prop(props) {

  const {api, session: {user}, refreshSignin, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [propsId, setPropsId] = useState(params.propsId)
  const [isAdding, setAdding] = useState(params.propsId === "_new")

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  const [row, updateRow] = useReducer(simpleReducer, {})
  const [environmentId, setEnvironmentId] = useState(null)
  const [regionId, setRegionId] = useState(null)
  const [industryId, setIndustryId] = useState(null)
  const [tenantTypeId, setTenantTypeId] = useState(null)
  const [tenantPlanId, setTenantPlanId] = useState(null)
  const [brandId, setBrandId] = useState(null)
  const [flagIds, setFlagIds] = useState(null)
  const [propsJson, setPropsJson] = useState(null)

  useEffect(() => {

    if (propsId && !isAdding) {
      setBusy(intl.msg("loading"))
      api.getProps(propsId)
        .then(({data}) => {
          setEnvironmentId(findOpt(environmentOpts, data.environmentId))
          setTenantTypeId(findOpt(tenantTypeOpts, data.tenantTypeId))
          setTenantPlanId(findOpt(tenantPlanOpts, data.tenantPlanId))
          setBrandId(findOpt(brandOpts, data.brandId))
          setFlagIds(data.flagIds?.map(e => {
            return ({
              label: e,
              value: e,
            })
          }))
          setPropsJson(JSON.stringify(data.props,null,2))
          setRegionAndIndustry(data)
        })
        .catch((err) => {
          log.error(err)
          setAlert({error: intl.msg("error_failed")})
        })
        .finally(() => {
          setBusy(null)
        })
    }

  }, [propsId]);

  function findOpt(opts, value) {
    return opts.find(e => e.value === value)
  }

  async function setRegionAndIndustry(row) {

    if (row.regionId !== null) {
      log.debug(">>>Z: regionId=", row.regionId)
      try {
        const {data} = await api.getRegion(row.regionId)
        log.debug(">>>Z: region loaded: data=", data)
        setRegionId({
          label: data.regionName,
          value: data.regionId,
        })
      }
      catch (e) {
        log.error(e)
      }
    }
    else {
      setRegionId(null)
    }

    if (row.industryId !== null) {
      try {
        const {data} = await api.getIndustry(row.industryId)
        log.debug(">>>Z: industry loaded: data=", data)
        setIndustryId({
          label: data.industryName.en,
          value: data.industryId,
        })
      }
      catch (e) {
        log.error(">>>Z: error=", e)
      }
    }
    else {
      setIndustryId(null)
    }
  }

  function handleEnvironmentIdChange(v) {
    setEnvironmentId(v)
    setTouched(true)
  }

  function handleRegionIdChange(v) {
    setRegionId(v)
    setTouched(true)
  }

  function handleIndustryIdChange(v) {
    setIndustryId(v)
    setTouched(true)
  }

  function handleTenantTypeIdChange(v) {
    setTenantTypeId(v)
    setTouched(true)
  }

  function handleTenantPlanIdChange(v) {
    setTenantPlanId(v)
    setTouched(true)
  }

  function handleBrandIdChange(v) {
    setBrandId(v)
    setTouched(true)
  }

  function handleFlagIdsChange(v) {
    setFlagIds(v)
    setTouched(true)
  }

  function handlePropsChange(e) {
    setPropsJson(e.target.value)
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_props", propsJson && propsJson.trim().length > 0, "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  let locationPat = /^\/sys\/regions\/([A-Za-z][A-Za-z])\/locations(\/?.*)/

  async function handleSaveClick() {

    log.debug("handleSaveClick: invoked", propsJson)

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        if (isAdding) {

          const payload = {
            environmentId: environmentId?.value,
            regionId: regionId?.value,
            industryId: industryId?.value,
            tenantTypeId: tenantTypeId?.value,
            tenantPlanId: tenantPlanId?.value,
            brandId: brandId?.value,
            flagIds: flagIds?.map(e => e.value),
            props: JSON.parse(propsJson),
          }

          log.debug("handleSaveClick: inserting: ", payload)

          const {data} = await api.insertProps(payload)

        }
        else {

          const payload = {
            propsId: propsId,
            environmentId: environmentId?.value,
            regionId: regionId?.value,
            industryId: industryId?.value,
            tenantTypeId: tenantTypeId?.value,
            tenantPlanId: tenantPlanId?.value,
            brandId: brandId?.value,
            flagIds: flagIds?.map(e => e.value),
            props: JSON.parse(propsJson)
          }

          log.debug("handleSaveClick: updating: ", payload)

          const {data} = await api.updateProps(propsId, payload)

        }

        window.location = `/sys/props`

      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    window.location = `/sys/props`
  }

  async function handleDeleteClick() {
    setBusy(intl.msg("saving"))
    try {
      const {data} = api.deleteProps(propsId)
      window.location = `/sys/props`
    }
    catch (error) {
      handleError(error)
    }
    finally {
      setBusy(null)
    }
  }

  function handleError(error) {
    if (error && error.code) {
      switch (error.code) {
        case "DUPLICATE_KEY":
          setAlert({error: intl.msg("props_error_duplicate")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditing() {
    return user.isSystem() && user.isAdminOrAbove()
  }

  function loadRegions(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {
    return api.listRegions({extraParams: mkExtraParams({filterParams:{regionName: search}}, {sortParams: {regionName: "ASC"}}), cursor: additional?.cursor, limit: 50})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.regionId,
            label: c.regionName,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
      .catch((error) => {
        log.error("Error loading regions: ", error)
      })
  }

  function loadIndustries(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {
    return api.listIndustries({extraParams: mkExtraParams({filterParams:{industryName: search}}, {sortParams: {industryName: "ASC"}}), cursor: additional?.cursor, limit: 50})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.industryId,
            label: c.industryName,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
      .catch((error) => {
        log.error("Error loading industries: ", error)
      })
  }

  function loadFlags(search, loadedOptions, additional) {
    return api.listFlags({extraParams: mkExtraParams({filterParams:{flagId: search}}, {sortParams: {flagId: "ASC"}}), cursor: additional?.cursor})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.flagId,
            label: c.flagId,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
      .catch((error) => {
        log.error("Error loading flags: ", error)
      })
  }

  function MoreButton(props) {
    const comps = []
    comps.push(
      <a className="dropdown-item" href="#" data-toggle="modal" onClick={handleDeleteClick}>
        <i className="fas fa-trash mr-2"></i>
        {intl.msg("delete")}
      </a>
    )
    return (
      <>
        {
          comps.length > 0 &&
          <>
            <button className="btn btn-secondary" type="button" id="dropdownMenuButton" title="More" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              {comps}
            </div>
          </>
        }
      </>
    )
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>
          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("props_title")} {" - " + (isAdding ? "(new)" : propsId)}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  touched || isAdding ? (
                    <>
                      <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick}>
                        <i className="fas fa-check-circle"></i>{intl.msg("save")}
                      </button>
                      <button key="key_btn_reset" className="btn btn-secondary" onClick={handleCancelClick}>
                        <i className="fas fa-times-circle"></i>{intl.msg("cancel")}
                      </button>
                    </>
                  ) : (
                    <MoreButton/>
                  )
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_environmentId" text={intl.msg("environment")}/>
              <FormGroup.Controls>
                <div style={{width: "200px"}}>
                  <Select id="inp_environmentId" className="react-select" classNamePrefix="react-select" value={environmentId} options={environmentOpts} isClearable placeholder={""} onChange={handleEnvironmentIdChange} isDisabled={!isEditing()}/>
                </div>
                <InfoBlock>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_regionId" text={intl.msg("region")}/>
              <FormGroup.Controls>
                <AsyncPaginate id="inp_regionId" className="react-select" classNamePrefix="react-select" cacheOptions isClearable placeholder={""} value={regionId} debounceTimeout={800} defaultOptions={true} loadOptions={loadRegions} reduceOptions={reduceGroupedOptions} onChange={handleRegionIdChange} isDisabled={!isEditing()}/><InfoBlock></InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_industryId" text={intl.msg("industry")}/>
              <FormGroup.Controls>
                <AsyncPaginate id="inp_industryId" className="react-select" classNamePrefix="react-select" cacheOptions isClearable placeholder={""} value={industryId} debounceTimeout={800} defaultOptions={true} loadOptions={loadIndustries} reduceOptions={reduceGroupedOptions} onChange={handleIndustryIdChange} isDisabled={!isEditing()}/><InfoBlock></InfoBlock>
                <InfoBlock>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="tenantTypeId" text={intl.msg("tenant_type")}/>
              <FormGroup.Controls>
                <div style={{width: "200px"}}>
                  <Select id="tenantTypeId" className="react-select" classNamePrefix="react-select" value={tenantTypeId} options={tenantTypeOpts} isClearable placeholder={""} onChange={handleTenantTypeIdChange} isDisabled={!isEditing()}/>
                </div>
                <InfoBlock>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="tenantPlanId" text={intl.msg("tenant_plan")}/>
              <FormGroup.Controls>
                <div style={{width: "200px"}}>
                  <Select id="tenantPlanId" className="react-select" classNamePrefix="react-select" value={tenantPlanId} options={tenantPlanOpts} isClearable placeholder={""} onChange={handleTenantPlanIdChange} isDisabled={!isEditing()}/>
                </div>
                <InfoBlock>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="brandId" text={intl.msg("brand")}/>
              <FormGroup.Controls>
                <div style={{width: "200px"}}>
                  <Select id="brandId" className="react-select" classNamePrefix="react-select" value={brandId} options={brandOpts} isClearable placeholder={""} onChange={handleBrandIdChange} isDisabled={!isEditing()}/></div>
                <InfoBlock>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_flags" text={intl.msg("account_system_flags")}/>
              <FormGroup.Controls>
                <AsyncPaginate id="inp_flags" className="react-select" classNamePrefix="react-select" cacheOptions isMulti isClearable value={flagIds} placeholder={""} debounceTimeout={800} defaultOptions={true} loadOptions={loadFlags} onChange={handleFlagIdsChange} isDisabled={!isEditing()}/>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_props" text={intl.msg("props")}/>
              <FormGroup.Controls>
                <textarea id="inp_props" className="form-control" style={{width: "100%"}} rows="100" value={propsJson} disabled={!isEditing()} onChange={handlePropsChange}/>
                <InfoBlock>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

          </FormBody>

        </MainContainer>
      </>

    )
}
