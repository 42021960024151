import React, {useState, useEffect, forwardRef, useRef} from 'react'
import {useIntlEx} from "./components/IntlUtils";
import {Markdown} from "./components/MarkdownUtils";
import getLogger from "./components/Logging.js"
import {shortIdEqual} from "./components/StringUtils";
import {useSolv} from "./components/SolvProvider";
import PrintableDoc from "./components/PrintComps";

const log = getLogger("CreditTransactionInvoice")

const CreditTransactionBill = forwardRef((props, ref) => {

  const {brandId} = useSolv()
  const {intl} = useIntlEx()

  const skinEl = document.getElementById("main-container") || document.documentElement;
  let logoBaseUri = window.getComputedStyle(skinEl).getPropertyValue("--logo-uri")?.trim()?.replaceAll("\"", "")?.replaceAll("{brandId}", brandId.toLowerCase())?.replaceAll("{mode}", "light").replaceAll("{color}", "color")

  return (
    <PrintableDoc title={intl.msg("credit_invoice_title")} ref={ref}>

      <PrintableDoc.Section className="pt-5">

        <PrintableDoc.Table className="w-100">

          <PrintableDoc.Row>

            <PrintableDoc.Column>

              <PrintableDoc.Table className="w-100">
                <PrintableDoc.Row>
                  <PrintableDoc.Column className="mod:label" value={`${intl.msg("issued_by")}:`}/>
                </PrintableDoc.Row>
                <PrintableDoc.Row>
                  <PrintableDoc.Column>
                    SOLV Technology PTE LTD<br/>
                    68 Circular Road, #02-01,<br/>
                    049422<br/>
                    Singapore<br/>
                  </PrintableDoc.Column>
                </PrintableDoc.Row>

                <PrintableDoc.Row>
                  <PrintableDoc.Column className="mod:label pt-3" value={`${intl.msg("customer")}:`}/>
                </PrintableDoc.Row>
                <PrintableDoc.Row>
                  <PrintableDoc.Column className="whitespace-prewrap">
                    ID: {props?.tenant?.tenantId?.toShortId()}<br/>
                    {props.tenant?.billingInfo?.name || props?.tenant?.tenantName || ""}<br/>
                    {props.tenant?.billingInfo?.address || props.tenant?.contactInfo || ""}
                  </PrintableDoc.Column>
                </PrintableDoc.Row>
              </PrintableDoc.Table>

            </PrintableDoc.Column>

            <PrintableDoc.Column className="valign-top" align="right">

              <PrintableDoc.Table className="w-auto whitespace-nowrap">

                <PrintableDoc.Row>
                  <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("credit_transaction_invoice_no")}:`}/>
                  <PrintableDoc.Column value={props.creditTransaction?.invoiceNo}/>
                </PrintableDoc.Row>

                <PrintableDoc.Row>
                  <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("issued_on")}:`}/>
                  <PrintableDoc.Column value={intl.date(props.creditTransaction?.createdOn, "ISO")}/>
                </PrintableDoc.Row>

                {
                  (props.purchaseOrderNo || props.creditTransaction?.purchaseOrderNo) &&
                    <PrintableDoc.Row>
                      <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("credit_transaction_purchase_order_no")}:`}/>
                      <PrintableDoc.Column value={props.purchaseOrderNo || props.creditTransaction?.purchaseOrderNo}/>
                    </PrintableDoc.Row>
                }

                {
                  props.creditTransaction?.settledOn &&
                    <>
                      <PrintableDoc.Row>
                        <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("settled_on")}:`}/>
                        <PrintableDoc.Column value={intl.date(props.creditTransaction?.settledOn, "ISO")}/>
                      </PrintableDoc.Row>

                      <PrintableDoc.Row>
                        <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("payment_method")}:`}/>
                        <PrintableDoc.Column value={intl.msg(`payment_method_${props.creditTransaction?.paymentMethod?.toLowerCase()}`)}/>
                      </PrintableDoc.Row>

                      {
                        props.creditTransaction?.details?.payment?.purchase_units?.[0]?.payments?.captures?.[0] &&
                          <PrintableDoc.Row>
                            <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("credits_paypal_info")}:`}/>
                            <PrintableDoc.Column value={props.creditTransaction?.details?.payment?.purchase_units?.[0]?.payments?.captures?.[0]?.id}/>
                          </PrintableDoc.Row>
                      }

                      {
                        props.creditTransaction?.reversedOn &&
                          <PrintableDoc.Row>
                            <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("canceled_on")}:`}/>
                            <PrintableDoc.Column value={intl.date(props.creditTransaction?.reversedOn, "ISO")}/>
                          </PrintableDoc.Row>
                      }
                    </>
                }

              </PrintableDoc.Table>

            </PrintableDoc.Column>

          </PrintableDoc.Row>

        </PrintableDoc.Table>

      </PrintableDoc.Section>

      <PrintableDoc.Section className="pt-5">

        <PrintableDoc.Table className="w-100">

          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:label mod:title-borders" width="80%" value={intl.msg("credit_invoice_description")}/>
            <PrintableDoc.Column className="mod:label mod:title-borders talign-right" width="20%" value={`${intl.msg("credit_invoice_amount")} (${props.tenant?.currencyCode})`}/>
          </PrintableDoc.Row>

          <PrintableDoc.Row>
            <PrintableDoc.Column width="80%" value={props.creditTransaction?.description}/>
            <PrintableDoc.Column className="talign-right" width="20%" value={intl.num(props.creditTransaction?.credits)}/>
          </PrintableDoc.Row>

          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:label talign-right" width="80%" value={`${intl.msg("subtotal")}:`}/>
            <PrintableDoc.Column className="talign-right" width="20%" value={intl.num(props.creditTransaction?.credits)}/>
          </PrintableDoc.Row>

          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:label talign-right" width="80%" value={`${intl.msg("tax")} (0%):`}/>
            <PrintableDoc.Column className="talign-right" width="20%" value={"0.00"}/>
          </PrintableDoc.Row>

          <PrintableDoc.Row>
            <PrintableDoc.Column  className="mod:label talign-right"width="80%" value={`${intl.msg("total")}:`}/>
            <PrintableDoc.Column className="mod:total-borders talign-right" width="20%" value={intl.num(props.creditTransaction?.credits)}/>
          </PrintableDoc.Row>

          {
            (["CANCELLED", "REVERSED"].includes(props.creditTransaction?.status)) ? (
              <PrintableDoc.Row>
                <PrintableDoc.Column width="80%"/>
                <PrintableDoc.Column className="talign-right" width="20%">
                  <div className="pt-3">
                    <img src="https://sassets.solv.technology/icons/solv-icon-doc-cancelled-circle-1.svg" width="auto" height="140"/>
                  </div>
                </PrintableDoc.Column>
              </PrintableDoc.Row>
            ) : (["ACTIVE", "SETTLED"].includes(props.creditTransaction?.status)) ? (
              <PrintableDoc.Row>
                <PrintableDoc.Column width="80%"/>
                <PrintableDoc.Column className="talign-right" width="20%">
                  <div className="pt-3">
                    <img src="https://sassets.solv.technology/icons/solv-icon-doc-paid-circle-1.svg" width="auto" height="140"/>
                  </div>
                </PrintableDoc.Column>
              </PrintableDoc.Row>
            ) : (
              <></>
            )

          }

        </PrintableDoc.Table>

      </PrintableDoc.Section>

      <PrintableDoc.Section className="pt-5">

        {
          (["PENDING"].includes(props.creditTransaction?.status)) &&
            <PrintableDoc.Table className="w-100">

              <PrintableDoc.Row>
                <PrintableDoc.Column className="mod:label mod:title-borders" value={intl.msg("payment_instructions_title")}/>
              </PrintableDoc.Row>

              <PrintableDoc.Row>
                <PrintableDoc.Column className="mod:text pt-1" value={intl.msg("payment_instructions_text", {domestic: props.bankAccounts?.some(ba => shortIdEqual(ba.regionId, props.tenant.regionId))})}/>
              </PrintableDoc.Row>

              <PrintableDoc.Row className="pt-3">
                <PrintableDoc.Column>
                  <PrintableDoc.Table className="pt-3">
                    {
                      props.bankAccounts?.some(ba => shortIdEqual(ba.regionId, props.tenant.regionId)) ? (
                        props.bankAccounts?.filter(ba => shortIdEqual(ba.regionId, props.tenant.regionId))?.map(ba =>
                          <>
                            <PrintableDoc.Row>
                              <PrintableDoc.Column className="mod:label talign-right" value={"Account Number:"}/>
                              <PrintableDoc.Column value={ba.bankAccountNo}/>
                            </PrintableDoc.Row>
                            <PrintableDoc.Row>
                              <PrintableDoc.Column className="mod:label talign-right" value={"Account Name:"}/>
                              <PrintableDoc.Column value={ba.bankAccountName}/>
                            </PrintableDoc.Row>
                            {
                              ba.bankAccountEtc.map(bae =>
                                <PrintableDoc.Row>
                                  <PrintableDoc.Column className="mod:label talign-right" value={`${bae[0]}:`}/>
                                  <PrintableDoc.Column value={bae[1]}/>
                                </PrintableDoc.Row>
                              )
                            }
                          </>
                        )
                      ) : (
                        props.bankAccounts?.filter(ba => !shortIdEqual(ba.regionId, props.tenant.regionId))?.map(ba =>
                          <>
                            <PrintableDoc.Row>
                              <PrintableDoc.Column className="mod:label talign-right" value={"Account Number:"}/>
                              <PrintableDoc.Column value={ba.bankAccountNo}/>
                            </PrintableDoc.Row>
                            <PrintableDoc.Row>
                              <PrintableDoc.Column className="mod:label talign-right" value={"Account Name:"}/>
                              <PrintableDoc.Column value={ba.bankAccountName}/>
                            </PrintableDoc.Row>
                            {
                              ba.bankAccountEtc.map(bae =>
                                <PrintableDoc.Row>
                                  <PrintableDoc.Column className="mod:label talign-right" value={`${bae[0]}:`}/>
                                  <PrintableDoc.Column value={bae[1]}/>
                                </PrintableDoc.Row>
                              )
                            }
                          </>
                        )
                      )
                    }
                  </PrintableDoc.Table>
                </PrintableDoc.Column>
              </PrintableDoc.Row>

            </PrintableDoc.Table>
        }

        <PrintableDoc.Table className="pt-4">
          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:text" value={intl.msg("credit_invoice_footer_text")}/>
          </PrintableDoc.Row>
        </PrintableDoc.Table>

      </PrintableDoc.Section>

    </PrintableDoc>
  )

})

export default CreditTransactionBill;