import React from "react";
import getLogger from "./Logging.js"
import styles from "./GraphComps.module.css"
import {useIntlEx} from "./IntlUtils";

const log = getLogger("GraphicComps")

export const PieChart = ({ percentage = 50, size = 200, children }) => {
  const radius = size / 2;
  const cx = radius;
  const cy = radius;

  // Convert percentage to radians, subtract Math.PI / 2 to start at 12 o'clock
  const startAngle = -Math.PI / 2;
  const endAngle = startAngle + (percentage / 100) * 2 * Math.PI;

  const startX = cx + radius * Math.cos(startAngle);
  const startY = cy + radius * Math.sin(startAngle);
  const endX = cx + radius * Math.cos(endAngle);
  const endY = cy + radius * Math.sin(endAngle);

  const largeArcFlag = percentage > 50 ? 1 : 0;

  const pathData = [
    `M ${cx} ${cy}`, // Move to center
    `L ${startX} ${startY}`, // Line to start of arc
    `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
    `Z` // Close the path
  ].join(' ');

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle cx={cx} cy={cy} r={radius} fill="var(--pie-chart-background-color)" />
      <path d={pathData} fill="var(--pie-chart-pie-color)" />
      {children}
    </svg>
  );
};

export const PieChart2 = ({ percentage = 50, size = 200, children }) => {

  // percentage = Math.min(percentage, 201)

  const radius = size / 2;
  const cx = radius;
  const cy = radius;

  let pieBackgroundColor = "var(--pie-chart-background-color)"
  let pieColor = "var(--pie-chart-pie-color)"
  let pieOverflowColor = "#803721"

  // Function to calculate path data for a slice
  const calculatePathData = (startAngle, endAngle) => {

    const startX = cx + radius * Math.cos(startAngle);
    const startY = cy + radius * Math.sin(startAngle);
    const endX = cx + radius * Math.cos(endAngle);
    const endY = cy + radius * Math.sin(endAngle);
    const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;

    return [
      `M ${cx} ${cy}`, // Move to center
      `L ${startX} ${startY}`, // Line to start of arc
      `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
      `Z` // Close the path
    ].join(' ');
  };

  // Convert percentage to radians, subtract Math.PI / 2 to start at 12 o'clock
  const startAngle = -Math.PI / 2;
  let endAngle = startAngle + (Math.min(percentage, 100) / 100) * 2 * Math.PI;
  const excessPercentage = percentage > 100 ? percentage - 100 : 0;

  const orangeSlicePath = calculatePathData(startAngle, endAngle);

  // For excess percentage, we calculate the second slice starting from 12 o'clock again
  let redSlicePath = '';
  if (excessPercentage > 0) {
    const redEndAngle = startAngle + (Math.min(excessPercentage, 100) / 100.0) * 2 * Math.PI;
    log.debug(">>>Z: pie", startAngle, redEndAngle, excessPercentage)
    redSlicePath = calculatePathData(startAngle, redEndAngle);
    pieBackgroundColor = pieColor
  }

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle cx={cx} cy={cy} r={radius} fill={pieBackgroundColor}/>
      <path d={orangeSlicePath} fill={pieColor}/>
      {excessPercentage > 0 && <path d={redSlicePath} fill={pieOverflowColor}/>}
      {children}
    </svg>
  );
};

export const PieChart3 = ({ percentage = 50, size = 200, children }) => {

  const radius = size / 2;
  const cx = radius;
  const cy = radius;

  let pieBackgroundColor = "var(--pie-chart-background-color)"
  let pieColor = "var(--pie-chart-pie-color)"
  let pieOverflowBackgroundColor = "var(--pie-chart-overflow-background-color)"
  let pieOverflowColor = "var(--pie-chart-overflow-color)"

  // Function to calculate path data for a slice
  const calculatePathData = (startAngle, endAngle) => {
    const startX = cx + radius * Math.cos(startAngle);
    const startY = cy + radius * Math.sin(startAngle);
    const endX = cx + radius * Math.cos(endAngle);
    const endY = cy + radius * Math.sin(endAngle);
    const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;

    return [
      `M ${cx} ${cy}`, // Move to center
      `L ${startX} ${startY}`, // Line to start of arc
      `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
      `Z` // Close the path
    ].join(' ');
  };

  // Convert percentage to radians, subtract Math.PI / 2 to start at 12 o'clock
  const startAngle = -Math.PI / 2;
  const cappedPercentage = Math.min(percentage, 100);
  const endAngle = startAngle + (cappedPercentage / 100) * 2 * Math.PI;

  // Handle excess percentage (over 100%)
  const excessPercentage = Math.max(percentage - 100, 0);
  const cappedExcessPercentage = Math.min(excessPercentage, 100);
  const redEndAngle = startAngle + (cappedExcessPercentage / 100) * 2 * Math.PI;

  const slicePath = calculatePathData(startAngle, endAngle);
  const overflowSlicePath = cappedExcessPercentage > 0 ? calculatePathData(startAngle, redEndAngle) : '';

  let circleRadius = radius
  let pieRadius = radius + 4

  if (cappedExcessPercentage > 0) {
    pieBackgroundColor = pieOverflowBackgroundColor
    pieColor = pieOverflowColor
  }
  else if (cappedPercentage === 100.00) {
    pieBackgroundColor = pieColor
  }

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle cx={cx} cy={cy} r={circleRadius} fill={pieBackgroundColor}/>

      {excessPercentage > 100 ? (
        <circle cx={cx} cy={cy} r={circleRadius} fill={pieOverflowColor}/>
      ) : (
        <>
          <path d={slicePath} fill={pieColor}/>
          {cappedExcessPercentage > 0 && <path d={overflowSlicePath} fill={pieOverflowColor}/>}
        </>
      )}
      {children}
    </svg>
  );
};

export function PercentageBar(props) {

  const {intl} = useIntlEx()

  const percentage = props.percentage
  const cssstyles = {}
  let size = props.size || "md"
  if (size) {
    switch (size) {
      case "sm":
        cssstyles.width = "30px"
        cssstyles.height = "10px"
        break
      case "md":
        cssstyles.width = "40px"
        cssstyles.height = "16px"
        break
      case "lg":
        cssstyles.width = "60px"
        cssstyles.height = "22px"
        break
    }
    // cssstyles.width = props.width
  }

  return (
    <div className={`${styles["percentage"]} ${styles[`percentage-${size}`]} ${styles["percentage-border"]}`}>
      <div className={styles["percentage-bar"]} style={{width: `${intl.int(percentage)}%`}}/>
      <div className={styles["percentage-text"]}>{intl.int(percentage)}%</div>
    </div>
  )
}
