import React, {forwardRef} from 'react'
import {useSolv} from "./components/SolvProvider";
import {useIntlEx} from "./components/IntlUtils";
import PrintableDoc from "./components/PrintComps";

const CommissionStatementBill = forwardRef((props, ref) => {

  const {brandId} = useSolv()
  const {intl} = useIntlEx()

  const skinEl = document.getElementById("main-container") || document.documentElement;
  let logoBaseUri = window.getComputedStyle(skinEl).getPropertyValue("--logo-uri")?.trim()?.replaceAll("\"", "")?.replaceAll("{brandId}", brandId.toLowerCase())?.replaceAll("{mode}", "light").replaceAll("{color}", "color")

  return (
    <PrintableDoc title={intl.msg("commission_statement_bill_title")} ref={ref}>

      <PrintableDoc.Section className="pt-5">

        <PrintableDoc.Table className="w-100">

          <PrintableDoc.Row>

            <PrintableDoc.Column>

              <PrintableDoc.Table className="w-100">
                <PrintableDoc.Row>
                  <PrintableDoc.Column className="mod:label" value={`${intl.msg("issued_by")}:`}/>
                </PrintableDoc.Row>
                <PrintableDoc.Row>
                  <PrintableDoc.Column>
                    SOLV Technology PTE LTD<br/>
                    68 Circular Road, #02-01,<br/>
                    049422<br/>
                    Singapore<br/>
                  </PrintableDoc.Column>
                </PrintableDoc.Row>

                <PrintableDoc.Row>
                  <PrintableDoc.Column className="mod:label pt-3" value={`${intl.msg("reseller")}:`}/>
                </PrintableDoc.Row>
                <PrintableDoc.Row>
                  <PrintableDoc.Column className="whitespace-prewrap">
                    ID: {props?.tenant?.tenantId?.toShortId()}<br/>
                    {props.tenant?.billingInfo?.name || props.tenant?.displayName || props.tenant?.tenantName}<br/>
                    {props.tenant?.billingInfo?.address || props.tenant?.contactInfo || ""}
                  </PrintableDoc.Column>
                </PrintableDoc.Row>
              </PrintableDoc.Table>

            </PrintableDoc.Column>

            <PrintableDoc.Column className="valign-top" align="right">

              <PrintableDoc.Table className="w-auto whitespace-nowrap">

                <PrintableDoc.Row>
                  <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("commission_statement_no")}:`}/>
                  <PrintableDoc.Column value={props?.commissionStatement?.statementNo}/>
                </PrintableDoc.Row>

                <PrintableDoc.Row>
                  <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("issued_on")}:`}/>
                  <PrintableDoc.Column value={intl.date(props.commissionStatement?.createdOn, "ISO")}/>
                </PrintableDoc.Row>

                {
                  "SETTLED" === props.commissionStatement?.status &&
                    <>
                      <PrintableDoc.Row>
                        <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("settled_on")}:`}/>
                        <PrintableDoc.Column value={intl.date(props.commissionStatement?.settledOn)}/>
                      </PrintableDoc.Row>
                      {
                        props.commissionStatement?.settleEtc?.invoiceInfo &&
                          <PrintableDoc.Row>
                            <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("reseller_invoice_no")}:`}/>
                            <PrintableDoc.Column value={props.commissionStatement?.settleEtc?.invoiceInfo?.invoiceNo}/>
                          </PrintableDoc.Row>
                      }
                      {
                        props.commissionStatement?.settleEtc?.paymentInfo &&
                          <>
                            <PrintableDoc.Row>
                              <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("payment_method")}:`}/>
                              <PrintableDoc.Column value={intl.msg(`payment_method_${props.commissionStatement?.settleEtc?.paymentInfo?.paymentMethod?.toLowerCase()}`)}/>
                            </PrintableDoc.Row>
                            <PrintableDoc.Row>
                              <PrintableDoc.Column className="mod:label talign-right" value={`${intl.msg("payment_ref_info")}:`}/>
                              <PrintableDoc.Column value={props.commissionStatement?.settleEtc?.paymentInfo?.refInfo}/>
                            </PrintableDoc.Row>
                          </>
                      }
                    </>
                }

              </PrintableDoc.Table>

            </PrintableDoc.Column>

          </PrintableDoc.Row>

        </PrintableDoc.Table>

      </PrintableDoc.Section>

      <PrintableDoc.Section className="pt-5">

        <PrintableDoc.Table className="w-100">

          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:label mod:title-borders" width="10%" value={intl.msg("date")}/>
            <PrintableDoc.Column className="mod:label mod:title-borders" width="20%" value={intl.msg("account")}/>
            <PrintableDoc.Column className="mod:label mod:title-borders" width="40%" value={intl.msg("item")}/>
            <PrintableDoc.Column className="mod:label mod:title-borders talign-right" width="20%" value={`${intl.msg("amount")} (USD)`}/>
          </PrintableDoc.Row>

          {
            props.commissionStatementItemRows?.pages?.map((commissionStatementItemGroup, i) => (
                commissionStatementItemGroup.data.map((commissionStatementItem, idx) => (
                  <PrintableDoc.Row>
                    <PrintableDoc.Column width="10%" value={intl.date(commissionStatementItem.createdOn, "ISO")}/>
                    <PrintableDoc.Column width="20%" value={commissionStatementItem.tenantName}/>
                    <PrintableDoc.Column width="40%" value={commissionStatementItem.description}/>
                    <PrintableDoc.Column align="right" width="20%" value={intl.num(commissionStatementItem.amount)}/>
                  </PrintableDoc.Row>
                ))
            ))
          }

          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:label talign-right" width="80%" colSpan="3" value={`${intl.msg("subtotal")}:`}/>
            <PrintableDoc.Column className="talign-right" width="20%" value={intl.num(props.commissionStatement?.total)}/>
          </PrintableDoc.Row>

          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:label talign-right" width="80%" colSpan="3" value={`${intl.msg("tax")} (0%):`}/>
            <PrintableDoc.Column className="talign-right"width="20%" value={"0.00"}/>
          </PrintableDoc.Row>

          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:label talign-right" width="80%" colSpan="3" value={`${intl.msg("total")}:`}/>
            <PrintableDoc.Column className="mod:total-borders talign-right" width="20%" value={"0.00"}/>
          </PrintableDoc.Row>

          {
            (["CANCELLED"].includes(props.commissionStatement?.status)) ? (
              <PrintableDoc.Row>
                <PrintableDoc.Column width="80%" colSpan="3"/>
                <PrintableDoc.Column className="talign-right" width="20%">
                  <div className="pt-3">
                    <img src="https://sassets.solv.technology/icons/solv-icon-doc-cancelled-circle-1.svg" width="auto" height="140"/>
                  </div>
                </PrintableDoc.Column>
              </PrintableDoc.Row>
            ) : (["ACTIVE", "SETTLED"].includes(props.commissionStatement?.status)) ? (
              <PrintableDoc.Row>
                <PrintableDoc.Column width="80%" colSpan="3"/>
                <PrintableDoc.Column className="talign-right" width="20%">
                  <div className="pt-3">
                    <img src="https://sassets.solv.technology/icons/solv-icon-doc-paid-circle-1.svg" width="auto" height="140"/>
                  </div>
                </PrintableDoc.Column>
              </PrintableDoc.Row>
            ) : (
              <></>
            )

          }

        </PrintableDoc.Table>

      </PrintableDoc.Section>

      <PrintableDoc.Section className="pt-5">
        <PrintableDoc.Table>
          <PrintableDoc.Row>
            <PrintableDoc.Column className="mod:text" value={intl.msg("commission_statement_bill_footer_text")}/>
          </PrintableDoc.Row>
        </PrintableDoc.Table>
      </PrintableDoc.Section>

    </PrintableDoc>
  )

})

export default CommissionStatementBill;