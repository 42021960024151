import React, {Fragment, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useSolv, useTenant} from "../components/SolvProvider"
import MainMenu from "../MainMenu"
import {FormHeader, FormBody, MainContainer, useAlert, ActionButton} from "../components/FormComps.js"
import { useIntlEx } from "../components/IntlUtils"
import {DataTable, useCols} from "../components/TableComps";
import getLogger from "../components/Logging.js"
import '../App.css'
import {useQueryClient} from "react-query";

const log = getLogger("Regions")

export default function Regions() {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert(null)

  const [reloadId, setReloadId] = useState(null)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (!user.isSystem()) {
      setFatal("DENIED_ACCESS")
    }
  },[])


  useEffect(() => {
    if (tenant) {
      log.debug("Region: tenant=", tenant.accessingAs("ADMIN/*/SYSTEM"))
    }
  }, [tenant])

  const {cols} = useCols({
    resourceName: "regions",
    specs: [
      // {
      //   field: "regionId",
      //   label: intl.msg("region_id"),
      //   filter: "text",
      //   sort: "",
      //   align: "left",
      //   visible: function() { return false },
      // },
      {
        field: "regionName",
        label: intl.msg("region_name"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "countryCodes",
        label: intl.msg("region_country_codes"),
        filter: {
          type: "asyncselect",
          multi: true,
          loader: (input, loadedOptions, additional) => {
            log.debug("loadCountries: invoked")
            return api.listCountries({view: "BASIC", extraParams: `${input ? `filter=countryCode:${encodeURIComponent(input)}&` : ""}sort=countryCode:ASC`, cursor: additional?.cursor, limmit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.countryCode,
                    label: c.countryCode,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadCountries: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
      {
        field: "currencyCode",
        label: intl.msg("region_currency_code"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("loadCurrencies: invoked")
            return api.listCurrencies({view: "BASIC", extraParams: "sort=currencyCode:ASC", cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.currencyCode,
                    label: c.currencyCode,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadCurrencies: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
      // {
      //   field: "status",
      //   label: intl.msg("status"),
      //   filter: {
      //     type: "select",
      //     options: () => {
      //       return ([
      //         {
      //           value: "ACTIVE",
      //           label: intl.msg("status_active")
      //         },
      //         {
      //           value: "INACTIVE",
      //           label: intl.msg("status_inactive")
      //         },
      //         {
      //           value: "PENDING",
      //           label: intl.msg("status_pending")
      //         },
      //       ])
      //     }
      //   },
      //   sort: "",
      //   align: "left",
      // }
    ],
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listRegions({extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "regionName":
        n = <span>{row.regionName}</span>
        break
      case "countryCodes":
        n = <>
          {
            row.countryCodes.map((c, i) => {
              return (<>{i > 0 ? ", " : ""}<a key={`countryCode_${i}`} className="text-link" href={`/sys/countries?filter=countryCode:${c}`}>{c}</a></>)
            })
          }
        </>
        break
      case "currencyCode":
        n = <span>{row.currencyCode}</span>
        break
      case "status":
        n = <span>{row.status ? intl.msg(`status_${row.status.toLowerCase()}`) : ""}</span>
        break
    }
    return (
      n
    )
  }

  function handleRowClick(row) {
    window.location = `/sys/regions/${row.regionId}`
  }

  return (

    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("regions")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>

                {
                  tenant.accessingAs("ADMIN/*/SYSTEM") &&
                    <ActionButton name="add_new" flavor="primary" href={`/sys/regions/_new`}/>
                }

              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>
            <DataTable
              tenant={tenant}
              cols={cols}
              resourceName="regions"
              dataKey="regionId"
              onFetchDataPage={handleFetchDataPage}
              onRenderDataCol={handleRenderDataCol}
              onClickDataRow={handleRowClick}
            />

          </FormBody>
        </MainContainer>

      </>
  )
}