import React, {useEffect, useState} from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {Alert, useAlert} from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkHref} from "./components/AccountUtils";

const log = getLogger("CreditTransactionDeleteDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CreditTransactionDeleteDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  function handleDeleteClick() {
    setBusy("Deleting...")
    try {
      api.deleteCreditTransaction(props.creditTransaction.tenant.tenantId, props.creditTransaction.creditTransactionId)
        .then((data) => {
          window.location = mkHref({suffix: `/credits`, tenant: props.tenant})
        })
        .catch((error) => {
          log.error(error)
          setAlert({error: intl.msg("error_failed")})
        })
        .finally(() => {
          setBusy(null)
        })
    }
    catch (error) {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleOpenDialog() {
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_credit_transaction_delete")
  }


  return (

    props.creditTransaction &&
      <ModalDialog
        dialogId="dlg_credit_transaction_delete"
        size="md" keyboard={"true"} backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}>

        <ModalDialog.Header
          title={intl.msg("credit_transaction_delete_title")}
          alert={alert}/>

        <ModalDialog.Body>

          <Alert warning={intl.msg("credit_transaction_delete_text", {description: props.creditTransaction.description})}/>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              {intl.msg("cancel")}
            </button>
            {
              !alert &&
                <button type="button" className="btn btn-danger" onClick={handleDeleteClick}>
                  {intl.msg("delete")}
                </button>
            }
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

    </ModalDialog>
  )
}