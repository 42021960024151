import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from "react"
import { useIntlEx } from "./IntlUtils.js"
import useDebounce from "./Debounce";
import getLogger from "./Logging.js"
import styles from "./SearchInput.module.css"
import {Icon} from "./FormComps";
import {debug} from "loglevel";

const log = getLogger("SearchInput")

export const SearchInput = forwardRef((props, ref)  => {

  const { intl } = useIntlEx()

  const [value, setValue] = useState(props.value)
  const [focused, setFocused] = useState(false);

  const debouncedValue = useDebounce(value, 800)

  useEffect(() => {
    const v = debouncedValue || ""
    if ("string" === typeof(v)) {
      if (props.onChange) {
        props.onChange(v)
      }
      else if (props.onSearch) {
        props.onSearch(v)
      }
    }
  }, [debouncedValue])

  useImperativeHandle(ref, () => ({
    setValue(value) {
      handleChange({target:{value: value}})
      fireEvents(value)
    }
  }));

  function fireEvents(value) {
    if (props.onChange) {
      props.onChange(value)
    }
    else if (props.onSearch) {
      props.onSearch(value)
    }
  }

  function handleSearchClick() {
    fireEvents(value)
  }

  function handleClearClick() {
    setValue("")
    if (props.onChange) {
      props.onChange("")
    }
    else if (props.onSearch) {
      props.onSearch("")
    }
    // props.onSearch("")
  }

  function handleChange(e) {
    if (e?.target?.value) {
      setValue(e.target.value)
    }
    else {
      setValue("")
    }
  }

  function handleKeyDown(e) {
    if (e && e.key === "Enter") {
      if (props.onChange) {
        props.onChange(value)
      }
      else if (props.onSearch) {
        props.onSearch(value)
      }
      // props.onSearch(value)
    }
  }

  function handleFocus() {
    setFocused(true)
  }

  function handleBlur() {
    setFocused(false)
  }

  return (
      <>
        <div className={`${styles.search} input-group flex-nowrap ${focused ? styles.search_is_focused : ""}`}>
          {
            props.searchIcon &&
              <span className="input-group-prepend">
                <div className={styles.search_icon}>
                  <button className={`form-control btn ${styles.search_button}`} type="button" onClick={handleSearchClick}>
                    <i className="fa fa-search"></i>
                  </button>
                </div>
              </span>
          }
          <input id="inp_searchInput" className={`form-control ${styles.search_text} shadow-none`} type="text" value={value} placeholder={props.placeholder} onChange={handleChange} onKeyDown={handleKeyDown} onFocus={handleFocus} onBlur={handleBlur}/>
          <span className="input-group-append">
          <button className={`form-control btn ${styles.search_clear}`} type="button" onClick={handleClearClick}>
            {/*<i className="fa fa-times"></i>*/}
            <Icon name="cross"/>
          </button>
        </span>
        </div>
      </>
  )

})