import React from "react";
import {useIntlEx} from "./IntlUtils";
import {TagBox, TagLabel} from "./TagBox";
import {useSolv} from "./SolvProvider";
import {Widget, WidgetMetric} from "./Widget";
import {mkHref} from "./AccountUtils";
import {CreditsExpiringText} from "./CreditTransactionUtils";
import {useQuery} from "react-query";

export function useCommissionStatementStatusOpts() {
  const {intl} = useIntlEx()
  const commissionStatementStatusOpts = [
    {
      value: "SETTLED",
      label: intl.msg("status_settled"),
    },
    {
      value: "PENDING",
      label: intl.msg("status_pending"),
    },
  ]

  function findCommissionStatementStatusOpt(status) {
    const st = status?.toUpperCase()
    if (!st) {
      return null
    }
    return commissionStatementStatusOpts.find(o => o.value === st)
  }

  return ({
    commissionStatementStatusOpts,
    findCommissionStatementStatusOpt,
  })
}

export function CommissionStatementStatus(props) {
  const {intl} = useIntlEx()
  const {findCommissionStatementStatusOpt} = useCommissionStatementStatusOpts()
  const userId = props.userId || props.row?.userId
  const statusOpt = findCommissionStatementStatusOpt(props.status || props.row?.status)
  const userLevel = props.userLevel || props.row?.userLevel
  const size = props.size || "sm"
  return (
    <>
      {
        statusOpt &&
        <TagBox>
          <TagLabel label={statusOpt.label} className={`status-color-${statusOpt.value.toLowerCase()} tag-label-${size}`} tooltip={""}/>
        </TagBox>
      }
    </>
  )
}

export function CommissionsWidget(props) {
  const {session, api} = useSolv()
  const {intl} = useIntlEx()
  const tenant = props.tenant

  const {data: totalPendingCommissionsData} = useQuery({
    queryFn: () => api.getTotalPendingCommissions({tenantId: props?.tenant?.tenantId}),
    queryKey: ["totalPendingCommissions", props?.tenant?.tenantId],
    enabled: !!props?.tenant
  })

  return (
    props.tenant &&
      <Widget title={intl.msg("commissions")}
              subtitle={<CreditsExpiringText credits={tenant.credits} alt={intl.msg("total_due")}/>}>
        <WidgetMetric>
          {intl.num(totalPendingCommissionsData?.data || 0.0)}
          <small className="pl-1">USD</small>
        </WidgetMetric>
      </Widget>
  )
}