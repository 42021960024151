import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import PrivateRoute from "../components/PrivateRoute";
import Broadcast from "../Broadcast";
import AccountEdit from "../AccountEdit";
import Members from "../Members";
import Member from "../Member";
import Welcome from "../Welcome";
import Contact from "../Contact";
import Page403 from "../Page403";
import Page426 from "../Page426";
import Page500 from "../Page500";
import Page503 from "../Page503";
import Page404 from "../Page404"
import Home from "./Home";
import Signin from "./Signin";
import Signup from "./Signup";
import Onboarding from "./Onboarding";

export default function PetSolvRoutes(props) {
  return (
      <Switch>
        <PrivateRoute path="/broadcast" component={Broadcast} exact />

        <PrivateRoute path="/account" component={AccountEdit} exact />
        <PrivateRoute path="/profile" component={Member} exact />
        <PrivateRoute path="/members" component={Members} exact />
        <PrivateRoute path="/members/_new" component={Member} exact />
        <PrivateRoute path="/members/:emailAddress" component={Member} exact />
        <PrivateRoute path="/" component={Home} exact />
        <PrivateRoute path="/welcome" component={Welcome} exact/>

        <PrivateRoute path="/contact" component={Contact} exact />

        <Route path="/signin" component={Signin} exact />
        <Route path="/signin/:magicCode" component={Signin} exact />

        <Route path="/signup" component={Signup} exact />
        <Route path="/signup/:signupCode" component={Signup} exact />
        <Route path="/signup/:signupCode/:magicCode" component={Signup} exact />

        <Route path="/onboarding" component={Onboarding} exact />

        <Route path="/forbidden" component={Page403} />
        <Route path="/upgrade" component={Page426} />
        <Route path="/error" component={Page500} />
        <Route path="/unavailable" component={Page503} />
        <Route path="/403" component={Page403} />
        <Route path="/426" component={Page426} />
        <Route path="/500" component={Page500} />
        <Route path="/503" component={Page503} />

        <Route path="/linkedin" component={LinkedInCallback} exact />

        <Route component={Page404} />
      </Switch>
  )
}