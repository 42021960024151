 import React, {Fragment, useState, useEffect, useReducer, useRef} from 'react'
import { useParams, useHistory, useLocation, Redirect }  from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import {useSolv, useTenant} from "./components/SolvProvider"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer, RegionLink, useAlert, ExternalLink, FormGroup, FormGroups, ExternalLinkButton, ActionButton, DropdownMenu, DropdownMenuItem} from "./components/FormComps.js"
import TagInput from "./components/TagInput.js"
import {ImageDropBox, uploadImage, processImageFile} from "./components/ImageUtils.js"
import {validateField, showError, clearError, clearAllErrors, validateUrl, InfoBlock, InfoText, validateDomainName, validateEmailAddress} from "./components/ValidationUtils.js"
import {useIntlEx} from "./components/IntlUtils.js"
import {AccountTabs} from "./components/AccountUtils";
import getLogger from "./components/Logging.js"
import ImageDialog from "./components/ImageDialog";
import {mkHref, useIndustry, useResellerType, useTenantPlan,} from "./components/AccountUtils"
import {mkExtraParams} from "./components/TableComps"
import {default as Select} from "react-select"
import NumericInput from "react-numeric-input"
import {rangeMap} from "./components/CommonUtils"
import useStateRef from "react-usestateref";
import "./App.css"
 import {AsyncPaginate, reduceGroupedOptions} from "react-select-async-paginate";

const log = getLogger("ResellerNew")

const DefaultCPMs = {"SOLV": 5.0, "IMAGE": 5.0, "VIDEO": 10.0}

export default function ResellerNew(props) {

  const {brandId, api, session, setFatal, setBusy} = useSolv()
  const {intl} = useIntlEx()

  const location = useLocation()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()

  const [touched, setTouched] = useState(null)

  const {tenantPlanOpts, findTenantPlanOpt} = useTenantPlan()
  const {resellerTypeOpts, findResellerTypeOpt, getResellerType, getAvailableResellerTypeOpts} = useResellerType(["MASTER", "STANDALONE"])

  const [emailAddress, setEmailAddress] = useState(null)
  const [tenantName, setTenantName] = useState(null)
  const [displayName, setDisplayName] = useState(null)
  const [region, setRegion] = useState(null)
  const [industry, setIndustry] = useState(null)
  const [tenantPlan, setTenantPlan] = useState(findTenantPlanOpt("BUSINESS_A"))
  const [resellerType, setResellerType] = useState(findResellerTypeOpt("STANDALONE"))
  const [maxLevels, setMaxLevels, maxLevelsRef] = useStateRef(2)
  const [commissions, setCommissions, commissionsRef] = useStateRef([5.0])
  const [cpm, setCpm] = useState(5.0)
  const [broadcastTemplates, setBroadcastTemplates] = useState(null)
  const [broadcastTemplateOpts, setBroadcastTemplateOpts] = useState(null)
  const [homePage, setHomePage] = useState(null)
  const [contactInfo, setContactInfo] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)
  const [customOnboardingPage, setCustomOnboardingPage] = useState(null)
  const [parentReseller, setParentReseller] = useState(null)
  const [representative, setRepresentative] = useState(false)

  const [logoImageInfo, setLogoImageInfo] = useState(null)
  const logoImagePickerDialogRef = useRef(null)

  const [clipboardOpsAllowed, setClipboardOpsAllowed] = useState(null)

  const [availableResellerTypeOpts, setAvailableResellerTypeOpts] = useState(null)

  log.debug(">>>Z: tenant=", tenant)

  useEffect(() => {
    if (tenant) {
      loadBroadcastTemplateOpts()
        .catch((error) => {
          setFatal(error)
        })
    }
  }, [tenant])

  // useEffect(() => {
  //   if (tenant) {
  //     setBusy(intl.msg("loading"))
  //     api.getRegion(tenant.regionId)
  //       .then(({data}) => {
  //         setRegion({
  //           value: data.regionId,
  //           label: data.regionName,
  //         })
  //         loadBroadcastTemplateOpts()
  //           .catch((error) => {
  //             setFatal(error)
  //           })
  //       })
  //       .catch((error) => {
  //         setFatal(error)
  //       })
  //       .finally(function () {
  //         setBusy(null)
  //       })
  //   }
  // }, [tenant])

  function loadBroadcastTemplateOpts() {
    log.debug(">>>Z: loadBroadcastTemplates", tenant)
    return api.getAllBroadcastTemplates(tenant.tenantId)
      .then(({data}) => {
        log.debug("loadBroadcastTemplates: data=", data)
        let opts = []
        opts = data.map(bt => {return {
          value: bt.broadcastTemplateId,
          label: bt.broadcastTemplateName
        }})
        log.debug("loadBroadcastTemplates: opt=", opts)
        setBroadcastTemplateOpts(opts)
        setBroadcastTemplates(opts)
        return opts
      })
      .catch(function (ex) {
        log.debug("Load Region failed: error=", ex)
      })
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_emailAddress", validateEmailAddress(emailAddress), "valid", opt)

    validateField("inp_tenantName", tenantName && (tenantName.trim().length > 0) && (tenantName !== emailAddress), "valid", opt)

    validateField("inp_contactInfo", contactInfo && contactInfo.trim().length > 0, "required", opt)

    validateField("inp_homePage", validateUrl(homePage), "valid", opt)

    validateField("inp_logoUrl", logoUrl && logoUrl.trim().length > 0, "required", opt)

    // validateField("inp_broadcastTemplates", broadcastTemplates && broadcastTemplates.length > 0, "required", opt)

    if (tenant.accessingAs("*/*/SYSTEM")) {

      validateField("inp_region", region, "required", opt)
      validateField("inp_industry", industry, "required", opt)

      if (["MASTER"].includes(resellerType?.value)) {
        validateField("inp_maxLevels", parseInt(maxLevels) > 1 && parseInt(maxLevels) <= 4, "range", opt)
      }

      if (commissions === null || commissions.length === 0) {
        validateField("inp_commission", false, "required", opt)
      }
      else {
        if (["STANDALONE", "SUB"].includes(resellerType?.value)) {
          const cs = parseFloat(commissions[0])
          validateField("inp_commission", !isNaN(cs) && cs > 0.00 && cs <= 20.00, "valid", opt)
        }
        else {
          if (maxLevels > commissions.length) {
            validateField("inp_commission_0", false, "valid", opt)
          }

          const cs = commissions.slice(0, maxLevels)

          if (cs.filter(c => isNaN(parseFloat(c))).length > 0) {
            validateField("inp_commission_0", false, "valid", opt)
          }
          else {
            const ttl = cs.map(c => parseFloat(c)).reduce((acc, curr) => acc + curr)
            if (isNaN(ttl) || ttl <= 0.00 || ttl > 20.00) {
              validateField("inp_commission_0", false, "valid", opt)
            }
          }
        }
      }
    }

    if (customOnboardingPage && customOnboardingPage.trim().length > 0) {
      validateField("inp_customOnboardingPage", validateUrl(customOnboardingPage), "valid", opt)
    }

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug("handleSaveClick: 1")

    try {

      if (validate()) {

        log.debug("handleSaveClick: 2")

        setBusy(intl.msg("working"))

        const {data} = await api.createUuid()
        const tenantId = data.uuid

        log.debug("handleSaveClick: tenantId=", tenantId)

        let finalLogoUrl = logoUrl

        if (finalLogoUrl && finalLogoUrl.startsWith("data:")) {
          finalLogoUrl = await uploadImage({
            api: api,
            brandId: brandId,
            imageData: finalLogoUrl,
            imagePath: `t/${tenantId}/logo`,
            setImageUrl: setLogoUrl,
          })
        }

        let payload = {
          tenantId: tenantId,
          emailAddress: emailAddress,
          tenantName: tenantName,
          tenantPlanId: null,
          broadcastTemplateIds: broadcastTemplates.map(t => t.value),
          homePage: homePage,
          displayName: displayName,
          contactInfo: contactInfo,
          logoUrl: finalLogoUrl,
          customOnboardingPage: customOnboardingPage,
          createdBy: session?.user.userId,
        }

        if (tenant.accessingAs("*/*/SYSTEM")) {
          payload.parentId = null
          payload.regionId = region.value
          payload.industryId = industry.value
          payload.maxDepth = resellerType.value === "MASTER" ? maxLevels - 1 : 0
          payload.commissions = commissions
        }
        else if (tenant.accessingAs("*/MANAGER/RESELLER")) {
          payload.parentId = parentReseller.value
        }
        else {
          setAlert({error: intl.msg("error_failed")})
        }

        log.debug("handleInviteClick: payload=", payload)

        const {res} = await api.inviteReseller(payload)

        // window.location.reload()

      }
      else {
        setAlert({error: intl.msg("error_invalid_form")})
      }
    }
    catch (error) {
      log.error("Validation error: ", error)
      handleError(error)
    }
    finally {
      setBusy(null)
    }
  }

  function handleCancelClick() {
    window.location = mkHref({suffix: `/resellers`, tenant: tenant, user: session.user})
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError", error.code)
      const code = error.code
      const details = error.details
      switch (code) {
        case "BANNED_INVITER":
          setAlert({error: intl.msg("error_banned_user", details)})
          break
        case "UNKNOWN_INVITER":
        case "INVALID_INVITER":
          setAlert({error: intl.msg("invite_error_invalid_inviter", details), field: "inp_emailAddresses", constraint: "valid"})
          break
        case "DUPLICATE_INVITEE_USER":
          setAlert({error: intl.msg("invite_error_duplicate_user", details), field: "inp_emailAddresses", constraint: "unique"})
          break
        case "DUPLICATE_INVITEE_TENANT":
          setAlert({error: intl.msg("invite_error_duplicate_tenant", details), field: "inp_tenantName", constraint: "unique"})
          break
        case "BANNED_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_banned_email_address", details), field: "inp_emailAddresses", constraint: "valid"})
          break
        default:
          setAlert({error: intl.msg("error_failed", details)})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function loadRegions(input, options, additional) {
    return api.listRegions({view: "BASIC", extraParams: `${input ? `filter=regionName:${encodeURIComponent(input)}&` : ""}sort=regionName:ASC`, cursor: additional?.cursor, limit: 20})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.regionId,
            label: c.regionName,
            pop: c.pop
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      });
  }

  function loadIndustries(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {
    return api.listIndustries({extraParams: mkExtraParams({filterParams:{industryName: search}}, {sortParams: {industryName: "ASC"}}), cursor: additional?.cursor, limit: 50})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.industryId,
            label: c.industryName,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
      .catch((error) => {
        log.debug("Error loading regions: ", error)
      })
  }

  function handleEmailAddressChange(e) {
    if (e) {
      let v = e.target.value
      if (v) {
        v = v.toLowerCase()
      }
      setEmailAddress(v)
      setTouched(true)
    }
  }

  function handleRegionChange(v) {
    setRegion(v)
    setTouched(true)
  }

  function handleResellerTypeChange(v) {
    setResellerType(v)
    setTouched(true)
  }

  function handleResellerChange(v) {
    setParentReseller(v)
    setTouched(true)
  }

  function handleMaxLevelsChange(v) {
    setMaxLevels(v)
    setTouched(true)
  }

  function handleCommissionsChange(i, v) {
    setCommissions(prev => {
      if (prev === null) {
        prev = []
      }
      if (i >= prev.length - 1) {
        const padLength = Math.max(0, i - prev.length + 1);
        prev = prev.concat(new Array(padLength).fill(0.00))
      }
      prev.splice(i, 1, v);
      log.debug(">>>Z: handleCommissionsChange: prev=", [...prev]);
      return [...prev]
    })
  }

  log.debug(">>>Z: commissions=", Array.isArray(commissionsRef.current), commissionsRef.current.slice(0, maxLevelsRef && maxLevelsRef.current ? maxLevelsRef.current : 0))

  const ttlCommissions = Array.isArray(commissionsRef?.current) && commissionsRef.current.length > 0 && maxLevelsRef?.current > 0 ? commissionsRef.current.slice(0, maxLevelsRef && maxLevelsRef.current ? maxLevelsRef.current : 0).reduce((acc, cur) => cur ? acc + cur : acc) : 0.0

  function handleCpmChange(v) {
    setCpm(v)
    setTouched(true)
  }

  function handleTenantNameChange(e) {
    setTenantName(e.target.value)
    setTouched(true)
  }

  function handleDisplayNameChange(e) {
    setDisplayName(e.target.value)
    setTouched(true)
  }

  function handleIndustryChange(v) {
    log.debug("handleIndustryChange: v=", v)
    setIndustry(v)
    setTouched(true)
  }

  function handleBroadcastTemplateChange(value) {
    log.debug("handleBroadcastTemplateChange: value=", value)
    setBroadcastTemplates(value)
    setTouched(true)
  }

  function handleRepresentative(v) {
    setRepresentative(v)
    setTouched(true)
  }

  function handleHomePageChange(e) {
    const value = e.target.value
    setHomePage(value)
    setTouched(true)
  }

  function handleHomePageBlur() {
    if (homePage && homePage.trim().length > 0) {
      try {
        if (homePage.startsWith("http://")) {
          setHomePage((prev) => `https://${prev.substring(7)}`)
        }
        else if (!homePage.startsWith("https://")) {
          setHomePage((prev) => `https://${prev}`)
        }
      }
      catch (e) {
      }
    }
  }

  function handleContactInfoChange(e) {
    setContactInfo(e.target.value)
    setTouched(true)
  }

  async function handleLogoChange(e) {

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 100_000,
          width: 100,
          height: 100,
        })
      }
      catch (ex) {
        setAlert({error: "error_image_process_failed"})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        // if (result.warning || result.error) {
        setLogoImageInfo(result)
        const el = document.getElementById("inp_dlg_logoImagePicker")
        el.click()
      }
      else {
        setLogoUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_logoUrl")
    }
  }

  function handleLogoClear() {
    setLogoUrl(null)
    setTouched(true)
  }

  function handleCustomOnboardingPageChange(e) {
    setCustomOnboardingPage(e.target.value)
    setTouched(true)
  }

  function Header(props) {
    return (
      <>
        <h1>{intl.msg("reseller_new_title")}</h1>
      </>
    )
  }

  function isEditable() {
    return true
  }

  return (
    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                <div>
                  {intl.msg("reseller")}
                </div>
                <div className="text-muted">
                  -
                </div>
                <div>
                  ({intl.msg("new").toLowerCase()})
                </div>
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                <ActionButton action="create" flavor="primary" disabled={!touched} onClick={handleSaveClick}/>
                <ActionButton action="cancel" onClick={handleCancelClick}/>
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

              <FormGroups>

                <FormGroup>
                  <FormGroup.Label text={intl.msg("email_address")} description={intl.msg("account_invite_email_address_description")} htmlFor="inp_emailAddress"/>
                  <FormGroup.Control>
                    <input id="inp_emailAddress" className="form-control" type="text" maxLength="200" value={emailAddress} autoFocus={true} onChange={handleEmailAddressChange}/>
                    <InfoBlock>
                      <InfoText validate="valid">{intl.msg("helptext_email_address_valid")}</InfoText>
                      <InfoText validate="unique">{intl.msg("helptext_email_address_unique")}</InfoText>
                      <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Control>
                </FormGroup>

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_tenantName" text={intl.msg("account_name")} description={intl.msg("account_invite_account_name_description")}/>
                  <FormGroup.Controls>
                    <input id="inp_tenantName" type="text" className="form-control" value={tenantName} maxLength="200" onChange={handleTenantNameChange} autoComplete="no"/>
                    <InfoBlock>
                      <InfoText >{intl.msg("helptext_name_casing")}</InfoText>
                      <InfoText validate="unique">{intl.msg("account_name_helptext_unique")}</InfoText>
                      <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>

              </FormGroups>

              {
                tenant.accessingAs("*/*/SYSTEM") &&
                  <>

                    <FormGroups>

                      <FormGroup>
                        <FormGroup.Label htmlFor="inp_region" text={intl.msg("region")} description={intl.msg("account_invite_region_description")}/>
                        <FormGroup.Controls>
                          <AsyncPaginate
                            id="inp_region"
                            className="react-select"
                            classNamePrefix="react-select"
                            cacheOptions
                            value={region}
                            defaultOptions={true}
                            loadOptions={loadRegions}
                            reduceOptions={reduceGroupedOptions}
                            placeholder=""
                            onChange={handleRegionChange}
                          />
                          <InfoBlock>
                            <InfoText validate="required" >{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>

                      <FormGroup>
                        <FormGroup.Label htmlFor="inp_industry" text={intl.msg("industry")} description={intl.msg("account_invite_industry_description")}/>
                        <FormGroup.Controls>
                          <AsyncPaginate
                            id="inp_industry"
                            className="react-select"
                            classNamePrefix="react-select"
                            cacheOptions
                            value={industry}
                            defaultOptions={true}
                            loadOptions={loadIndustries}
                            reduceOptions={reduceGroupedOptions}
                            placeholder=""
                            onChange={handleIndustryChange}
                          />
                          <InfoBlock>
                            <InfoText validate="required" >{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>

                    </FormGroups>

                    <FormGroups>

                      <FormGroup>
                        <FormGroup.Label htmlFor="inp_resellerType" text={intl.msg("reseller_type")} description={intl.msg("reseller_type_description")}/>
                        <FormGroup.Controls>
                          <div style={{width: "200px"}}>
                            <Select
                              id="inp_resellerType"
                              className="react-select"
                              classNamePrefix="react-select"
                              value={resellerType}
                              options={resellerTypeOpts}
                              onChange={handleResellerTypeChange}
                            />
                          </div>
                          <InfoBlock>
                            <InfoText validate="required" >{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>

                      {
                        resellerType?.value === "MASTER" ? (
                          <>
                            <FormGroup>
                              <FormGroup.Label htmlFor="inp_maxLevels" text={intl.msg("reseller_max_levels")} description={intl.msg("reseller_max_levels_description")}/>
                              <FormGroup.Controls>
                                <div style={{width: "50px"}}>
                                  <NumericInput id="inp_maxLevels" className="form-control"  min={2} max={5} precision={0} value={maxLevels} onChange={handleMaxLevelsChange}/>
                                </div>
                                <InfoBlock>
                                  <InfoText validate="valid">{intl.msg("reseller_max_levels_helptext_valid", {minValue: tenant.commissions && tenant.commissions.length > 0 ? tenant.commissions.length : 2, maxValue: 5})}</InfoText>
                                  {
                                    tenant?.commissions && maxLevels < tenant?.commissions.length &&
                                      <InfoText warning={true}>{intl.msg("reseller_max_levels_helptext_reduce_warning", {minValue: tenant.commissions && tenant.commissions.length > 0 ? tenant.commissions.length : 2, maxValue: 5})}</InfoText>
                                  }
                                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                                </InfoBlock>
                              </FormGroup.Controls>
                            </FormGroup>
                            {
                              maxLevels > 0 &&
                                <FormGroup>
                                  <FormGroup.Label htmlFor="inp_commissions" text={intl.msg("reseller_commissions")} description={intl.msg("reseller_commissions_description")}/>
                                  <FormGroup.Controls>
                                    <table border="0">
                                      {
                                        rangeMap(0, maxLevels - 1, (i) => {
                                          return (
                                            <tr>
                                              <td>
                                                <label className="col-form-label pr-2" htmlFor={`inp_commission_${i}`}>
                                                  <span>
                                                    {
                                                      i === 0 ? (
                                                        intl.msg("reseller_type_master_l")
                                                      ) : maxLevels > 2 ? (
                                                        intl.msg("reseller_type_sub_l", {subLevel: i})
                                                      ) : (
                                                        intl.msg("reseller_type_sub")
                                                      )
                                                    }:
                                                  </span>
                                                </label>
                                              </td>
                                              <td>
                                                <div className="d-flex align-items-center gap-1">
                                                  <div style={{width: "80px"}}>
                                                    <NumericInput id={`inp_commission_${i}`} className="form-control" min={1.00} max={99.00} precision={2} strict={true} value={commissions && commissions.length > 0 ? commissions[i] : 0.00} onChange={(v) => handleCommissionsChange(i, v)}/>
                                                  </div>
                                                  <span className="col-form-label">%</span>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        })
                                      }

                                      <tr>
                                        <td>
                                          <label className="col-form-label pr-2" htmlFor="inp_commission_standalone">
                                            {intl.msg("total")}:
                                          </label>
                                        </td>
                                        <td>
                                          <div className="d-flex gap-1 align-items-center" style={{borderTop: "1px solid var(--form-divider-color)", borderBottom: "4px double var(--form-divider-color)"}}>
                                            <div style={{width: "80px", textAlign: "right", paddingRight: "25px", color: (ttlCommissions <= 20 ? "inherit" : "var(--color-danger)")}}>
                                              {ttlCommissions.toFixed(2)}
                                            </div>
                                            <div className="col-form-label" style={{paddingBottom: "0"}}>%</div>
                                          </div>
                                        </td>
                                      </tr>

                                      {
                                        tenant?.commissions && maxLevels < tenant?.commissions.length &&
                                        <>
                                          <tr>
                                            <td colspan="2" style={{height: "8px"}}>

                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <label className="col-form-label pr-2" htmlFor="inp_commission_standalone">
                                                {intl.msg("reseller_type_standalone")}:
                                              </label>
                                            </td>
                                            <td>
                                              <div className="d-flex gap-1 align-items-center">
                                                <div style={{width: "80px"}}>
                                                  <NumericInput id={`inp_commission_standalone`} className="form-control" min={1.00} max={99.00} precision={2} strict={true} value={commissions?.length > maxLevels - 1? commissions[maxLevels] : 0.00} onChange={(v) => handleCommissionsChange(maxLevels, v)}/>
                                                </div>
                                                <span className="col-form-label">%</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </>
                                      }

                                    </table>
                                    <InfoBlock>
                                      <InfoText validate="valid">{intl.msg("reseller_commissions_helptext_valid")}</InfoText>
                                      <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                                    </InfoBlock>
                                  </FormGroup.Controls>

                                </FormGroup>
                            }
                          </>
                        ) : resellerType?.value === "STANDALONE" ? (
                          <FormGroup>
                            <FormGroup.Label htmlFor="inp_commission" text={intl.msg("reseller_commission")} description={intl.msg("reseller_commission_description")}/>
                            <FormGroup.Controls>
                              <div className="d-flex gap-1 align-items-center" style={{width: "100px"}}>
                                <NumericInput id="inp_commission" className="form-control" min={1.00} max={99.00} precision={2} value={commissions && commissions.length > 0 ? commissions[0] : 0.00} onChange={(v) => handleCommissionsChange(0, v)}/>
                                <span className="col-form-label">%</span>
                              </div>
                              <InfoBlock>
                                <InfoText validate="valid">{intl.msg("reseller_commission_helptext_valid")}</InfoText>
                                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                              </InfoBlock>
                            </FormGroup.Controls>
                          </FormGroup>
                        ) : (
                          <></>
                        )
                      }

                    </FormGroups>

                  </>
              }

              <FormGroups>

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_displayName" text={intl.msg("account_contact_name")} description={intl.msg("account_contact_name_description")}/>
                  <FormGroup.Controls>
                    <input id="inp_displayName" type="text" className="form-control" maxLength={200} value={displayName} onChange={handleDisplayNameChange} autoComplete="no"/>
                    <InfoBlock>
                      <InfoText validate="required">{intl.msg("helptext_optional")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_contactInfo" text={intl.msg("account_contact_info")} description={intl.msg("account_contact_info_description")}/>
                  <FormGroup.Controls>
                    <textarea id="inp_contactInfo" className="form-control" rows="3" autoComplete="no" value={contactInfo} onChange={handleContactInfoChange} style={{whiteSpace: "pre-line"}}/>
                    <InfoBlock>
                      <InfoText validate="optional">{intl.msg("helptext_optional")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_homePage" text={intl.msg("account_home_page")} description={intl.msg("account_home_page_description")}/>
                  <FormGroup.Controls>
                    <div className="input-group" style={{gap: "0.5rem"}}>
                      <input id="inp_homePage" type="text" className="form-control" value={homePage} onChange={handleHomePageChange} onBlur={handleHomePageBlur}/>
                      <div className="input-group-append">
                        <ExternalLinkButton href={homePage} validate="relaxed"/>
                      </div>
                    </div>
                    <InfoBlock>
                      <InfoText validate="valid">{intl.msg("helptext_web_address_valid")}</InfoText>
                      <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_logoUrl" text={intl.msg("account_logo")} description={intl.msg("account_logo_description")}/>
                  <FormGroup.Controls>
                    <ImageDropBox
                      id="inp_logoUrl"
                      image={logoUrl}
                      imageClassName={"account-logo"}
                      width={100}
                      height={100}
                      onChange={handleLogoChange}
                      onClear={handleLogoClear}/>
                    <InfoBlock>
                      <InfoText validate="valid_size">{intl.msg("account_logo_helptext_valid_size")}</InfoText>
                      <InfoText validate="required">{intl.msg("helptext_optional")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>

              </FormGroups>

          </FormBody>

        </MainContainer>

        <ImageDialog ref={logoImagePickerDialogRef} id="dlg_logoImagePicker" title="Logo Image" imageInfo={logoImageInfo} setTargetImageData={setLogoUrl} setTouched={setTouched}/>

      </>
  )
}