import React  from 'react'
import { useIntlEx } from "./IntlUtils.js"
import { TagLabel } from './TagBox'
import getLogger from "./Logging.js"
import {useSolv} from "./SolvProvider";
import {Markdown} from "./MarkdownUtils";
import {Alert} from "./FormComps";
import {PercentageBar} from "./GraphicComp";

const log = getLogger("BroadcastUtils")

export function BroadcastStatusTagLabel(props) {
  const { intl } = useIntlEx()
  const user = props.user
  const broadcast = props.broadcast
  const size = (props.size ? props.size : "md")
  // const size = (props.size ? ` tag-label-${props.size}` : "")
  let realStatus = (broadcast ? broadcast.status : props.status)?.toLowerCase()
  if (realStatus) {
    const displayStatus = getBroadcastDisplayStatus(realStatus, user)
    let title = intl.msg(`status_broadcast_${displayStatus}`)
    let label = intl.msg(`status_broadcast_${displayStatus}`)
    let pct = getPctDelivered(broadcast, intl)
    return (
      displayStatus &&
      <TagLabel
        label={label}
        title={title}
        size={size}
        className={"broadcast-status-color-" + displayStatus + (props.className ? ` ${props.className}` : "")}>
        {
          pct ? (
            <PercentageBar percentage={Math.min(pct, 99.00)} width="40px"/>
          ) : (
            <></>
          )
        }
      </TagLabel>
    )
  }
  else {
    return <></>
  }
}

export function BroadcastDeliveryPercentage(props) {
  const {intl} = useIntlEx()
  const user = props.user
  const broadcast = props.broadcast
  const size = (props.size ? props.size : "md")
  let realStatus = (broadcast ? broadcast.status : props.status)?.toLowerCase()
  if (realStatus) {
    let pct = getPctDelivered(broadcast, intl)
    return (
      pct ? (
        <PercentageBar percentage={Math.min(pct, 99.00)} size={size}/>
      ) : (
        <></>
      )
    )
  }
  else {
    return <></>
  }
}

export function getPctDelivered(broadcast) {
  const adViews = broadcast?.adViews || broadcast?.stats?.adViews
  if (broadcast && broadcast.status && adViews && broadcast.targetAdViews && ("STARTED" === broadcast.status)) {
    return adViews < broadcast.targetAdViews ? Math.min((adViews + 0.0) / (broadcast.targetAdViews + 0.0) * 100, 99.0) : 99.0
  }
  else {
    return null
  }
}

export function getStyle(style, el, at, defaultValue) {
  if (style && style[el] && style[el][at]) {
    return style[el][at]
  }
  return defaultValue
}

export function mkFieldRowClassName(opts) {
  let className = "field-row"
  if (opts) {
    if (opts.layout) {
      className = ` ${className} field-style-${opts.layout.toLowerCase()}`
    }
    if (opts.labelStyle) {
      className = ` ${className} field-style-label-${opts.labelStyle.toLowerCase()}`
    }
    if (opts.valueStyle) {
      className = `${className} field-style-value-${opts.valueStyle.toLowerCase()}`
    }
  }
  return className
}

export function getMessageFormatOpts(tenant, intl) {
  let opts = []
  opts.push({
    label: <><div>{intl.msg("broadcast_message_type_solv")}</div><small>{intl.msg("broadcast_message_type_solv_tooltip")}</small></>,
    value: "SOLV",
  })
  opts.push({
    label: <><div>{intl.msg("broadcast_message_type_image")}</div><small>{intl.msg("broadcast_message_type_image_tooltip")}</small></>,
    value: "IMAGE",
  })
  return opts
}

export function getMessageFormatOpt(messageFormat, tenant, intl) {
  return getMessageFormatOpts(tenant, intl).find(i => i.value === messageFormat)
}

export function getClickActionOpts(tenant, intl, opts = ["SOLV", "EXTERNAL", "FBMESSENGER", "LINE", "HOME", "VIBER", "WHATSAPP"]) {
  const mode = getComputedStyle(document.documentElement).getPropertyValue("--mode") || "light"
  let o = []
  // if (opts.includes("SOLV")) {
  //   o.push({
  //     label: <>
  //       <div>{intl.msg("broadcast_page_type_solv_page")}</div>
  //       <small>{intl.msg("broadcast_page_type_solv_page_tooltip")}</small></>,
  //     value: "SOLV",
  //     displayLabel: intl.msg("broadcast_page_type_solv_page"),
  //   })
  // }

  //<img src={`https://sassets.solv.technology/icons/solv-icon-social-www-circle-border-mono-${mode}.svg`} width="16" height="auto"/>

  if (opts.includes("EXTERNAL")) {
    o.push({
      label: <>
        <div className="d-flex align-items-center gap-1">
          <img src={`https://sassets.solv.technology/icons/solv-icon-social-www-circle-color.svg`} width="16" height="auto"/>
          <div>{intl.msg("broadcast_page_type_custom_page")}</div>
        </div>
        <small>{intl.msg("broadcast_page_type_custom_page_tooltip")}</small>
      </>,
      value: "EXTERNAL",
      displayLabel: intl.msg("broadcast_page_type_custom_page"),
    })
  }
  if (opts.includes("HOME")) {
    if (tenant.homePage && tenant.homePage.trim().length > 0) {
      o.push({
        label: <>
          <div className="d-flex align-items-center gap-1">
            <img src={`https://sassets.solv.technology/icons/solv-icon-social-www-circle-color.svg`} width="16" height="auto"/>
            <div>{intl.msg("broadcast_page_type_home_page")}</div>
          </div>
          <small>{intl.msg("broadcast_page_type_home_page_tooltip")}</small></>,
        value: "HOME",
        displayLabel: intl.msg("broadcast_page_type_home_page"),
      })
    }
  }
  if (opts.includes("WHATSAPP")) {
    o.push({
      label: <>
        <div className="d-flex align-items-center gap-1">
          <img src={`https://sassets.solv.technology/icons/solv-icon-messenger-whatsapp-color.svg`} width="16" height="auto"/>
          <div>{intl.msg("broadcast_page_type_whatsapp_chat")}</div>
        </div>
        <small>{intl.msg("broadcast_page_type_whatsapp_chat_tooltip")}</small></>,
      value: "WHATSAPP",
      displayLabel: intl.msg("broadcast_page_type_whatsapp_chat"),
    })
  }
  if (opts.includes("FBMESSENGER")) {
    o.push({
      label: <>
        <div className="d-flex align-items-center gap-1">
          <img src={`https://sassets.solv.technology/icons/solv-icon-messenger-facebook-color.svg`} width="16" height="auto"/>
          <div>{intl.msg("broadcast_page_type_fbmessenger_chat")}</div>
        </div>
        <small>{intl.msg("broadcast_page_type_fbmessenger_chat_tooltip")}</small></>,
      value: "FBMESSENGER",
      displayLabel: intl.msg("broadcast_page_type_fbmessenger_chat"),
    })
  }
  if (opts.includes("LINE")) {
    o.push({
      label: <>
        <div className="d-flex align-items-center gap-1">
          <img src={`https://sassets.solv.technology/icons/solv-icon-messenger-line-color.svg`} width="16" height="auto"/>
          <div>{intl.msg("broadcast_page_type_line_chat")}</div>
        </div>
        <small>{intl.msg("broadcast_page_type_line_chat_tooltip")}</small></>,
      value: "LINE",
      displayLabel: intl.msg("broadcast_page_type_line_chat"),
    })
  }
  if (opts.includes("VIBER")) {
    o.push({
      label: <>
        <div className="d-flex align-items-center gap-1">
          <img src={`https://sassets.solv.technology/icons/solv-icon-messenger-viber-color.svg`} width="16" height="auto"/>
          <div>{intl.msg("broadcast_page_type_viber_chat")}</div>
        </div>
        <small>{intl.msg("broadcast_page_type_viber_chat_tooltip")}</small></>,
      value: "VIBER",
      displayLabel: intl.msg("broadcast_page_type_viber_chat"),
    })
  }
  return o
}

export function getClickActionOpt(clickAction, tenant, intl, opts = ["SOLV", "EXTERNAL", "HOME", "WHATSAPP", "FBMESSENGER", "LINE", "VIBER"]) {
  return getClickActionOpts(tenant, intl, opts).find(i => i.value === clickAction)
}

export function mkPageUrlFromChatDestination(type, destination) {
  switch (type) {
    case "FBMESSENGER":
      return `http://m.me/${encodeURIComponent(destination)}`
    case "LINE":
      return `https://line.me/R/oaMessage/${encodeURIComponent(destination)}`
    case "VIBER":
      return `viber://contact?number=${encodeURIComponent(destination?.replace(/[^0-9]/g, ""))}`
    case "WHATSAPP":
      return `https://wa.me/${encodeURIComponent(destination?.replace(/[^0-9]/g, ""))}`
    default:
      return destination
  }
}

export function getChatDestinationFromPageUrl(type, url) {
  switch (type) {
    case "FBMESSENGER":
      try {
        const u = new URL(url)
        return u.pathname.replace(/^\/*/, '').trim()
      }
      catch (err) {
        return null
      }
      break
    case "LINE":
      try {
        const u = new URL(url)
        return u.pathname.replace(/^\/*/, '').trim()
      }
      catch (err) {
        return null
      }
      break
    case "VIBER":
      try {
        const u = new URL(url)
        return u.searchParams.get("number")
      }
      catch (err) {
        return null
      }
      break
    case "WHATSAPP":
      try {
        const u = new URL(url)
        return u.pathname.replace(/^\/*/, '').trim()
      }
      catch (err) {
        return null
      }
      break
  }
}

// export function getWhatsAppPhoneNoFromPageUrl(url) {
//   try {
//     const u = new URL(url)
//     return u.pathname.replace(/^\/*/, '').trim()
//   }
//   catch (err) {
//     return null
//   }
// }

//
//
// export function getMessageFormatOpts(tenant, intl) {
//   let opts = []
//   opts.push({
//     label: <><div>{intl.msg("broadcast_message_type_solv")}</div><small>{intl.msg("broadcast_message_type_solv_tooltip")}</small></>,
//     value: "SOLV",
//   })
//   opts.push({
//     label: <><div>{intl.msg("broadcast_message_type_image")}</div><small>{intl.msg("broadcast_message_type_image_tooltip")}</small></>,
//     value: "IMAGE",
//   })
//   return opts
// }
//
// export function getMessageFormatOpt(messageFormat, tenant, intl) {
//   return getMessageFormatOpts(tenant, intl).find(i => i.value === messageFormat)
// }
//
// export function getClickActionOpts(tenant, intl, opts = ["SOLV", "EXTERNAL", "HOME", "WHATSAPP"]) {
//   let o = []
//   // if (opts.includes("SOLV")) {
//   //   o.push({
//   //     label: <>
//   //       <div>{intl.msg("broadcast_page_type_solv_page")}</div>
//   //       <small>{intl.msg("broadcast_page_type_solv_page_tooltip")}</small></>,
//   //     value: "SOLV",
//   //     displayLabel: intl.msg("broadcast_page_type_solv_page"),
//   //   })
//   // }
//   if (opts.includes("EXTERNAL")) {
//     o.push({
//       label: <>
//         <div>{intl.msg("broadcast_page_type_custom_page")}</div>
//         <small>{intl.msg("broadcast_page_type_custom_page_tooltip")}</small>
//       </>,
//       value: "EXTERNAL",
//       displayLabel: intl.msg("broadcast_page_type_custom_page"),
//     })
//   }
//   if (opts.includes("HOME")) {
//     if (tenant.homePage && tenant.homePage.trim().length > 0) {
//       o.push({
//         label: <>
//           <div>{intl.msg("broadcast_page_type_home_page")}</div>
//           <small>{intl.msg("broadcast_page_type_home_page_tooltip")}</small></>,
//         value: "HOME",
//         displayLabel: intl.msg("broadcast_page_type_home_page"),
//       })
//     }
//   }
//   if (opts.includes("WHATSAPP")) {
//     o.push({
//       label: <>
//         <div>{intl.msg("broadcast_page_type_whatsapp_chat")}</div>
//         <small>{intl.msg("broadcast_page_type_whatsapp_chat_tooltip")}</small></>,
//       value: "WHATSAPP",
//       displayLabel: intl.msg("broadcast_page_type_whatsapp_chat"),
//     })
//   }
//   return o
// }

function getBroadcastDisplayStatus(status, user) {
  let s = status?.trim()?.toLowerCase()
  if (!user?.isSystemUser()) {
    switch (s) {
      case "published":
      case "queued":
      case "generated":
      case "imported":
      case "failed_import":
        return "awaiting_stats"
      default:
        return s
    }
  }
  else {
    return s
  }
}


export function useBroadcastStatusOpts(props) {
  const {session:{user}} = useSolv()
  const {intl} = useIntlEx()
  let broadcastStatusOpts = [
    {
      value: "DRAFT",
      label: intl.msg("status_broadcast_draft"),
    },
  ]
  if (user?.isSystemUser()) {
    broadcastStatusOpts.push(
      {
        value: "PUBLISHED",
        label: intl.msg("status_broadcast_published"),
      }
    )
    broadcastStatusOpts.push(
      {
        value: "IMPORTED",
        label: intl.msg("status_broadcast_imported"),
      }
    )
    broadcastStatusOpts.push(
      {
        value: "FAILED_IMPORT",
        label: intl.msg("status_broadcast_failed_import"),
      }
    )
  }
  else {
    broadcastStatusOpts.push(
      {
        value: "PUBLISHED\tIMPORTED\tFAILED_IMPORT",
        label: intl.msg("status_broadcast_awaiting_stats"),
      }
    )
  }

  broadcastStatusOpts.push({
    value: "STARTED",
    label: intl.msg("status_broadcast_started"),
  })

  broadcastStatusOpts.push({
    value: "ENDED",
    label: intl.msg("status_broadcast_ended"),
  })

  function findBroadcastStatusOpt(status) {
    if (!status) {
      return null
    }
    const st = status.toUpperCase()
    return broadcastStatusOpts.find(i => i.value?.toUpperCase() === st)
  }

  return {
    broadcastStatusOpts,
    findBroadcastStatusOpt
  }
}

// export function getClickActionOpt(clickAction, tenant, intl) {
//   return getClickActionOpts(tenant, intl).find(i => i.value === clickAction)
// }

// export function getWhatsAppPhoneNoFromPageUrl(url) {
//   try {
//     const u = new URL(url)
//     return u.pathname.replace(/^\/*/, '').trim()
//   }
//   catch (err) {
//     return null
//   }
// }

// export function getPageUrlFromWhatsAppPhoneNo(whatsAppPhoneNo) {
//   return `https://wa.me/${encodeURIComponent(whatsAppPhoneNo)}`
// }
//
// export function getTenantPredefinedClickUrl(tenant, id) {
//   return tenant.clickUrls.find(i => i.id === id)?.url
// }

// export function BroadcastIssue(props) {
//
//   const {brandId} = useSolv()
//   const {intl} = useIntlEx()
//   const markdown = new Markdown(null, null, null, null, true)
//
//   return (
//       (props.issue) ? (
//           <>
//             <Alert type="warning" title={intl.msg(`broadcast_issue_${props.issue?.type?.toLowerCase()}_title`, {brandName: intl.msg(`label_${brandId.toLowerCase()}`)})}>
//               {
//                 props.issue?.description &&
//                   <>
//                     <hr/>
//                     <p>
//                       <div dangerouslySetInnerHTML={{__html: markdown.toHtml(props.issue.description)}}/>
//                     </p>
//                   </>
//               }
//               {
//                 props.canRefund &&
//                   <p>{intl.msg(`broadcast_issue_${props.issue?.type?.toLowerCase()}_actions`, {canRefund: props.canRefund})}</p>
//               }
//             </Alert>
//           </>
//       ) : (
//           <></>
//       )
//   )
// }

export function getAdSystemName(adSystemId) {
  if ("AMOBEE" === adSystemId) {
    return ("Nexxen")
  }
  else {
    return adSystemId
  }
}
