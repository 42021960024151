 import React, {Fragment, useState, useEffect, useReducer, useRef} from 'react'
import { useParams, useHistory, useLocation, Redirect }  from 'react-router-dom'
import {useMutation, useQuery} from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {v4 as uuidv4} from 'uuid'
import {useSolv, useTenant} from "./components/SolvProvider"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer, RegionLink, useAlert, ExternalLink, FormGroup, FormGroups, ExternalLinkButton, ActionButton, DropdownMenu, DropdownMenuItem, Section, Label, Text} from "./components/FormComps.js"
import TagInput from "./components/TagInput.js"
import {ImageDropBox, uploadImage, processImageFile} from "./components/ImageUtils.js"
import {validateField, showError, clearError, clearAllErrors, validateUrl, InfoBlock, InfoText, validateDomainName} from "./components/ValidationUtils.js"
import {useIntlEx} from "./components/IntlUtils.js"
import {AccountTabs} from "./components/AccountUtils";
import {pathReducer, simpleReducer} from "./components/ReactUtils";
import {openDialog, openDialogCurried} from "./components/DialogUtils.js"
import getLogger from "./components/Logging.js"
import ImageDialog from "./components/ImageDialog";
import {AccountSummaryWidgets} from "./components/Widget";
import {AccountHeader, mkHref, useIndustry, useResellerType} from "./components/AccountUtils"
 import AccountDeleteDialog from "./AccountDeleteDialog"
import ResellerInviteDialog from "./ResellerInviteDialog"
import ClientInviteDialog from "./ClientInviteDialog";
import {BroadcastDeliveryPercentage, BroadcastStatusTagLabel} from "./components/BroadcastComps"
import CreditTransactionTopupDialog from "./CreditTransactionTopupDialog"
import ToggleButton from "./components/ToggleButton"
import {UserLink} from "./components/UserUtils"
import CreditsExtendExpiryDialog from "./CreditsExtendExpiryDialog"
import {AsyncPaginate} from "react-select-async-paginate"
import {mkExtraParams} from "./components/TableComps"
import {default as Select} from "react-select"
import NumericInput from "react-numeric-input"
import {rangeMap} from "./components/CommonUtils"
import ResellerMoveDialog from "./ResellerMoveDialog";
import {shortIdEqual} from "./components/StringUtils";
import {canExtendCredits, CreditsExpiring} from "./components/CreditTransactionUtils";
import CreditsAdjustDialog from "./CreditsAdjustDialog";
import "./App.css"
import styles from "./Account.module.css"
 import ClientMoveDialog from "./ClientMoveDialog";

 const log = getLogger("Account")

export default function Account(props) {

  const {brandId, api, session, setFatal, setBusy} = useSolv()
  const {intl} = useIntlEx()

  const location = useLocation()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()

  const {industryOpts, findIndustryOpt} = useIndustry()
  const {getResellerType, getAvailableResellerTypeOpts, findResellerTypeOpt} = useResellerType()

  const [industry, setIndustry] = useState(findIndustryOpt("PUBLIC_SAFETY"))

  function getResellerTypeOpt() {
    return findResellerTypeOpt(getResellerType(tenant))
  }

  useEffect(() => {
    if (tenant) {
      // log.debug("useEffect: tenant=", tenant)
      // if ("ONBOARDING" === tenant.status) {
      //   setAlert({attention: intl.msg("account_onboarding_notice")})
      //   setEditMode("EDIT")
      // }
      // updateModifyTenant({path: ".", value: tenant})
      // setClickUrls(tenant.clickUrls)
      // const _allowedDomains = tenant.allowedDomains?.length > 0 ? tenant.allowedDomains.map(d => {
      //   return {label: d.trim(), value: d.trim()}
      // }) : null
      // setAllowedDomains(_allowedDomains)
      // const _disallowedDomains = tenant.disallowedDomains?.length > 0 ? tenant.disallowedDomains.map(d => {
      //   return {label: d.trim(), value: d.trim()}
      // }) : null
      // setDisallowedDomains(_disallowedDomains)
      // setRestrictDomains(_allowedDomains?.length > 0 || _disallowedDomains?.length > 0)
      // setAvailableResellerTypeOpts(getAvailableResellerTypeOpts(tenant))
      // setResellerType(findResellerTypeOpt(getResellerType(tenant)))
      // setCommissions(tenant.commissions)
      // setMaxLevels(tenant.maxDepth + 1)
      // setIndustry(tenant.industryId)
      // setFlags(tenant.flags?.map(d => {
      //   return {label: d.trim(), value: d.trim()}
      // }))
      // setUseContactInfo(["null", "undefined"].includes(typeof(tenant.billingInfo?.name)))
      // setOverrideAdSystemProps(!!tenant.props)
      // if (tenant.props && tenant.props.adSystem && tenant.props.adSystem.AMOBEE) {
      //   if (tenant.props.adSystem.AMOBEE.cpms) {
      //     setCpms(tenant.props.adSystem.AMOBEE.cpms)
      //   }
      //   if (tenant.props.adSystem.AMOBEE.behavioralSegmentSetId) {
      //     api.getAmobeeBehaviorSegmentSet(tenant.props.adSystem.AMOBEE.behavioralSegmentSetId)
      //       .then(({data}) => {
      //         log.debug("getAmobeeBehaviorSegmentSet: ", data)
      //         setAmobeeBehaviorSegmentSet({
      //           value: data.behaviorSegmentSetId,
      //           label: data.behaviorSegmentSetName,
      //           popDampFactor: data.popDampFactor,
      //         })
      //       })
      //       .catch((error) => {
      //         log.debug("Error", error)
      //       })
      //   }
      // }
      // // api.getTenantRecentActivities(tenant.tenantId)
      // //   .then(({data}) => {
      // //     if (data.broadcasts || data.responses) {
      // //       setRecentActivities(data)
      // //     }
      // //     else {
      // //       setRecentActivities(null)
      // //     }
      // //     log.debug("tenantRecentActivities: data=", data)
      // //   })
      // //   .catch((error) => {
      // //     log.debug("Error", error)
      // //   })

      // if (tenant.industryId) {
      //   api.getIndustry(tenant.industryId)
      //     .then(({data}) => {
      //       log.debug("getIndustry: data=", data)
      //       setIndustry({
      //         value: data.industryId,
      //         label: data.industryName._,
      //         description: data.industryDescription._,
      //       })
      //     })
      //     .catch((error) => {
      //       log.error("getIndustry: error=", error)
      //     })
      // }

      if (CreditsExpiring(tenant.credits)) {
        setAlert({warning: intl.msg("credits_alert_expiring", {accessType: tenant.accessInfo?.accessType, canExtend: canExtendCredits(tenant, session.user).toString(), expireDays: intl.fmDaysFrom(tenant.credits.expiresOn), a: chunk => <a className="text-link" href="#" onClick={openDialogCurried("dlg_credits_extend_expiry")}>{chunk}</a>})})
      }

    }
  }, [tenant])

  const fetchActiveBroadcasts = async ({ pageParam = null }) => {
    return api.listActiveBroadcasts({tenantId: tenant.tenantId, limit: 51})
  }

  const {data: activeBroadcastsData, status: activeBroadcastsStatus} = useQuery([tenant], fetchActiveBroadcasts)

  // useEffect(() => {
  //   setTtlCommissions(commissions ? commissions.slice(0, maxLevels).reduce((acc, cur) => cur ? acc + cur : acc) : 0.0)
  // }, [commissions, maxLevels])

  function getErrorMsg(error) {
    let res = null
    if (error && error.code) {
      log.debug("getErrorMsg: error=", error)
      const code = error.code
      res = {
        code: code
      }
      switch (code) {
        case "DUPLICATE_KEY":
        case "DUPLICATE_TENANT":
          res.msg = intl.msg("error_invalid_form", {tenantName: error.tenantName})
          res.field = "inp_tenantName"
          res.constraint = "valid"
          break
        default:
          res.msg = intl.msg("error_failed", {emailAddress: error.emailAddress})
          break
      }
    }
    else {
      return intl.msg("error_failed")
    }
    return res
  }

  function handleError(error) {
    const msg = getErrorMsg(error)
    if (msg) {
      setAlert({error: msg.msg})
      if (msg.field) {
        showError(msg.field, msg.constraint)
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  async function handleEditClick() {
    window.location = mkHref({suffix: "/_edit", user: session.user, tenant: tenant})
  }

  function ClientQuickAccess(props) {
    return (
      <ul className={styles["plain-list"]}>
        <li>
          <a className="text-link" href={mkHref({suffix: "/broadcasts/_new", tenant: tenant})}>{intl.msg("home_quick_access_create_a_broadcast")}</a>
        </li>
        {
          tenant.accessingAs(["ADMIN/MEMBER/*"]) ? (
            <li>
              <a className="text-link" href="#"
                 onClick={openDialogCurried("dlg_credits_add")}>{intl.msg("home_quick_access_add_credits")}</a>
            </li>
          ) : tenant.accessingAs(["*/MANAGER/SYSTEM"]) ? (
            <li>
              <a className="text-link" href="#"
                 onClick={openDialogCurried("dlg_credits_adjust")}>{intl.msg("home_quick_access_adjust_credits")}</a>
            </li>

          ) : (
            <></>
          )
        }
      </ul>
    )
  }

  function ResellerQuickAccess(props) {
    return (
      <ul className={styles["plain-list"]}>
        {
          tenant.accessingAs(["BASIC/MEMBER/RESELLER", "ADMIN/MANAGER/*"]) &&
          <li>
            <a className="text-link" href="#" data-toggle="modal" onClick={openDialogCurried("dlg_client_invite")}>{intl.msg("home_quick_access_invite_a_client")}</a>
            </li>
        }
        {
          tenant.accessingAs(["ADMIN/MANAGER/RESELLER", "ADMIN/*/SYSTEM"]) &&
          // (tenant?.maxDepth ? tenant?.maxDepth > (tenant?.parentPath ? (tenant?.parentPath.split(".").length - 1) : 0) : false) &&
          // (tenant.accessingAs("ADMIN/MANAGER/RESELLER")) &&
            <li>
              <a className="text-link" href="#" onClick={openDialogCurried("dlg_reseller_invite")}>{intl.msg("home_quick_access_invite_a_reseller")}</a>
            </li>
        }
        {/*<li>*/}
        {/*  <a className="text-link" href={mkHref({suffix: "/broadcasts/_new", tenant: tenant})}>{intl.msg("home_quick_access_create_a_broadcast")}</a>*/}
        {/*</li>*/}
        {
          <li>
            {/*{tenant && <ContactSupportLink user={session.user} tenant={tenant}/>}*/}
          </li>
        }
      </ul>
    )
  }

  function SystemQuickAccess(props) {
    return (
      <ul className={styles["plain-list"]}>
        <li>
          <a className="text-link" href="#" data-toggle="modal" onClick={openDialogCurried("dlg_client_invite")}>{intl.msg("home_quick_access_invite_a_client")}</a>
        </li>
        <li>
          <a className="text-link" href={mkHref({suffix: `/resellers/_new`, tenant: tenant, user: session.user})}>{intl.msg("home_quick_access_invite_a_reseller")}</a>
          {/*<a className="text-link" href="#" onClick={openDialogCurried("dlg_reseller_invite")}>{intl.msg("home_quick_access_invite_a_reseller")}</a>*/}
        </li>
      </ul>
    )
  }

  function QuickAccess(props) {
    log.debug("QuickAccess: tenant=", tenant)
    return (
      tenant.isClient() ? (
        ClientQuickAccess(props)
      ) : tenant.isReseller() ? (
        ResellerQuickAccess(props)
      ) : tenant.isSystem() ? (
        SystemQuickAccess(props)
      ) : (
        <></>
      )
    )
  }

  function canReinvite() {
    if (tenant) {
      if (["INVITING", "ONBOARDING"].includes(tenant.status)) {
        if (tenant.createdBy === session.user?.userId) {
          return true
        }
        else if (tenant.accessingAs("ADMIN/*/*")) {
          return true
        }
      }
    }
    return false
  }

  function canEdit() {
    if (tenant) {
      if (["INVITING"].includes(tenant.status) && shortIdEqual(tenant?.createdById, session.user?.userId)) {
        return true
      }
      return tenant.accessingAs("ADMIN/*/*") && !["DELETED", "BANNED"].includes(tenant.status)
    }
    return false
  }

  function canMove() {
    if (!tenant) {
      return false
    }
    if (["DELETED", "BANNED"].includes(tenant.status)) {
      return false
    }
    if (!tenant.isReseller) {
      return false
    }
    if (!tenant.accessingAs(["SUPERADMIN/MANAGER/*"])) {
      return false
    }
    if (!(tenant.hasSubTenants() || session.user.isSystem())) {
      return false
    }
    return true
  }

  function canDelete() {
    if (tenant) {
      if (["INVITING", "ONBOARDING"].includes(tenant.status)) {
        if (tenant.accessingAs("ADMIN/*/*")) {
          return true
        }
      }
      else if (tenant.tenantId !== session.user.tenantId && tenant.accessingAs("ADMIN/MANAGER/*")) {
        return true
      }
    }
    return false
  }

  function MoreMenu(props) {
    const comps = []
    if (tenant) {
      if (canMove()) {
        if (comps.length > 0) {
          comps.push(<div className="dropdown-divider"></div>)
        }
        comps.push(
          <DropdownMenuItem name="move" onClick={() => openDialog(`dlg_${tenant.tenantTypeId.toLowerCase()}_move`)}/>
        )
      }
      if (canDelete()) {
        if (comps.length > 0) {
          comps.push(<div className="dropdown-divider"></div>)
        }
        comps.push(
          <DropdownMenuItem name="delete"  onClick={() => openDialog("dlg_account_delete")}/>
        )
      }
    }
    return (
      <>
        {
          comps.length > 0 &&
            <DropdownMenu>
              {comps}
            </DropdownMenu>
        }
      </>
    )
  }

  function ActiveBroadcastsSection(props) {
    return (
      activeBroadcastsStatus === "loading" ? (
        <></>
      ) : activeBroadcastsStatus === "success" && activeBroadcastsData?.data?.length > 0 ? (
        <Section title={intl.msg("active_broadcasts")} style={{maxHeight: "400px", overflowY: "scroll"}}>
          <>
              <table border="0" cellPadding="2" cellSpacing="8" width="100%">
                <tr>
                  <td>
                    <Label msg="broadcast_name"/>
                  </td>
                  {
                    session.user?.isSystem() &&
                      <td>
                        <Label msg="account"/>
                      </td>
                  }
                  <td>
                    <Label msg="status"/>
                  </td>
                  <td>
                    <Label msg="views"/>
                  </td>
                  <td>
                    <Label msg="clicks"/>
                  </td>
                </tr>
                {/*<tr>*/}
                {/*  <td colspan="4">*/}
                {/*    <div className="p-1"></div>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                {
                  activeBroadcastsData.data.map(data => {
                    return (
                      <tr>
                        {
                          session?.user.isManager() ? (
                            <td>
                              <a className="text-link" href={`${data.tenantId}/broadcasts/${data.broadcastId}`}>
                                {data.broadcastName}
                              </a>
                            </td>
                          ) : (
                            <td>
                              <a className="text-link" href={`/broadcasts/${data.broadcastId}`}>
                                {data.broadcastName}
                              </a>
                            </td>
                          )
                        }
                        {
                          session.user?.isSystem() &&
                            <td>
                              <a className="text-link" href={`/${data.tenantId}`}>
                                {data.tenantName}
                              </a>
                            </td>
                        }
                        <td>
                          <BroadcastStatusTagLabel broadcast={data} user={session?.user} size="lg"/>
                        </td>
                        <td>
                          {intl.int(data.adViews, "COMPACT")}
                        </td>
                        <td>
                          {intl.int(data.adClicks, "COMPACT")}
                        </td>
                      </tr>
                    )
                  })
                }
              </table>
              {
                activeBroadcastsData.nextCursor &&
                  <p className="mt-3">
                    <a className="text-link" href={`/broadcasts`}>Click here</a> to show more.
                  </p>
              }
          </>
        </Section>
      ) : (
        <></>
      )
    )
  }

  function AccountInfoSection(props) {
    return (
      <Section title={intl.msg("account_info")}>

        <div className="d-table">

          <div className="d-table-row">
            <div className="d-table-cell talign-right whitespace-nowrap p-1">
              <Label msg="account_name"/>
            </div>
            <div className="d-table-cell p-1">
              <Text text={tenant.tenantName}/>
            </div>
          </div>

          {
            !tenant.accessingAs("*/MEMBER/*") &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right whitespace-nowrap p-1">
                  <Label msg="account_type"/>
                </div>
                <div className="d-table-cell p-1">
                  <Text msg={`account_type_${tenant.tenantTypeId.toLowerCase()}`}/>
                </div>
              </div>
          }

          {
            tenant.tenantTypeId === "CLIENT" &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right whitespace-nowrap p-1">
                  <Label msg="account_plan"/>
                </div>
                <div className="d-table-cell p-1">
                  <Text msg={`account_plan_solv_${tenant.tenantPlanId}`}/>
                </div>
              </div>
          }

          {
            tenant.tenantTypeId === "RESELLER" &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right whitespace-nowrap p-1">
                  <Label msg="reseller_type"/>
                </div>
                <div className="d-table-cell p-1">
                  <Text msg={`reseller_type_${getResellerType(tenant).toLowerCase()}`}/>
                </div>
              </div>
          }

          {
            tenant.reseller &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right whitespace-nowrap p-1">
                  <Label msg={tenant.isReseller() ? "account_reseller_reseller" : "account_client_reseller"}/>
                </div>
                <div className="d-table-cell p-1">
                  <a className="text-link" href={`/${tenant.reseller.tenantId}`}>{tenant.reseller.tenantName}</a>
                </div>
              </div>
          }

          {
            tenant && tenant.isReseller() && tenant.accessingAs("BASIC/MEMBER,MANAGER/*") &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right whitespace-nowrap p-1]">
                  <Label text={(getResellerTypeOpt()?.value === "MASTER" ? intl.msg("reseller_commissions") : intl.msg("reseller_commission"))}/>
                </div>
                <div className="d-table-cell p-1">
                  <Text>
                    {
                      !["MASTER"].includes(getResellerTypeOpt()?.value) ? (
                        <span>{tenant.commissions[0].toFixed(2)}%</span>
                      ) : (
                        tenant.commissions?.map((c, i) => {
                          return (
                            i === 0 ? (
                              <>
                                <span className="text-muted pr-1">{intl.msg("reseller_type_master_l")}:</span>
                                <span className="">{c.toFixed(2)}%</span>
                              </>
                            ) : i >= tenant.commissions.length - 1 ? (
                              <>
                                <span className="text-muted pr-1">, {intl.msg("reseller_type_sub_l", {subLevel: i})}:</span>
                                <span className="">{c}%</span>
                              </>
                            ) : (
                              <>
                                <span className="text-muted pr-1">, {intl.msg("reseller_type_super_l", {subLevel: i})}:</span>
                                <span className="">{c.toFixed(2)}%</span>
                              </>
                            )
                          )
                        })
                      )
                    }
                  </Text>
                </div>
              </div>
          }

          {
            tenant?.brandId === "SOLV" && tenant?.tenantTypeId != "SYSTEM" &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right text-muted whitespace-nowrap p-1">
                  <Label msg="industry"/>
                </div>
                <div className="d-table-cell p-1">
                  <Text text={industry?.label}/>
                </div>
              </div>
          }

          {
            tenant.owner &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right text-muted align-top whitespace-nowrap p-1">
                  <Label msg="owner"/>
                </div>
                <div className="d-table-cell p-1">
                  <UserLink user={tenant.owner} tenant={tenant} session={session}/>
                </div>
              </div>
          }

          {
            !tenant.isSystem() &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right text-muted whitespace-nowrap p-1">
                  <Label msg="region"/>
                </div>
                <div className="d-table-cell p-1">
                  <RegionLink session={session} region={tenant.region}/>
                </div>
              </div>
          }

          {
            tenant.isClient() &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right text-muted whitespace-nowrap p-1">
                  <Label msg="currency"/>
                </div>
                <div className="d-table-cell p-1">
                  {tenant.currencyCode}
                </div>
              </div>
          }

          {
            tenant.logoUrl &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right text-muted align-top  whitespace-nowrap p-1">
                  <Label msg="account_logo"/>
                </div>
                <div className="d-table-cell p-1">
                  <div  style={{width: "100px", height: "100px", overflow: "hidden", position: "relative"}}>
                    <img src={tenant.logoUrl} style={{width: "100%", height: "100%", objectFit: "contain", position: "absolute", top: "0", left: "0"}}/>
                  </div>
                </div>
              </div>
          }

          {
            tenant.contactInfo &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right text-muted align-top whitespace-nowrap p-1">
                  <Label msg="account_contact_info"/>
                </div>
                <div className="d-table-cell p-1">
                  <div style={{whiteSpace: "pre-wrap"}}>{tenant.contactInfo}</div>
                </div>
              </div>
          }

          {
            tenant.homePage &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right text-muted align-top whitespace-nowrap p-1">
                  <Label msg="account_home_page"/>
                </div>
                <div className="d-table-cell p-1">
                  <ExternalLink href={tenant.homePage} target="_blank">{tenant.homePage}</ExternalLink>
                </div>
              </div>
          }

          {
            tenant && tenant.accessingAs("*/*/SYSTEM") &&
              <div className="d-table-row">
                <div className="d-table-cell talign-right text-muted whitespace-nowrap p-1">
                  <Label msg="flags"/>
                </div>
                <div className="d-table-cell p-1">
                  {tenant.flags?.join(", ")}
                </div>
              </div>
          }

          {
            session.user?.isSystem() &&
              <div className="d-table-row ">
                <div className="d-table-cell talign-right text-muted whitespace-nowrap p-1">
                  <Label msg="props"/>
                </div>
                <div className="d-table-cell p-1 w-100" style={{verticalAlign: "top"}}>
                  <div className="form-group" style={{backgroundColor: "transparent", border: "none", padding: "0"}}>
                    {
                      tenant.props &&
                        <textarea rows="10" style={{backgroundColor: "transparent", width: "100%", overflow: "scroll", whiteSpace: "pre"}} resize="true" disabled="true" value={JSON.stringify(tenant.props, null, 2)}/>
                    }
                  </div>
                </div>
              </div>
          }

        </div>
      </Section>
    )
  }

  function QuickAccessSection(props) {
    if (!["INVITING", "ONBOARDING"].includes(tenant.status)) {
      return (
        <Section title={intl.msg("quick_access")}>
          <div>
            <QuickAccess/>
          </div>
        </Section>
      )
    }
    else {
      return <></>
    }
  }

  function LearnSection(props) {
    if (!["INVITING", "ONBOARDING"].includes(tenant.status)) {
      return (
        <Section title={intl.msg("learn")}>
          <ul className={styles["plain-list"]}>
            <li>
              <a className="text-link" href="/whatsnew">What's New</a>
            </li>
            <li>
              <a className="text-link" href="https://help.solv.technology/category/using-solv" target="_blank">{intl.msg("hc_using_solv")}</a>
            </li>
            <li>
              <a className="text-link" href="https://help.solv.technology/category/faq" target="_blank">{intl.msg("hc_faq")}</a>
            </li>
            <li>
              <a className="text-link" href="https://help.solv.technology" target="_blank">Help Center</a>
            </li>
          </ul>
        </Section>
      )
    }
    else {
      return <></>
    }
  }

  function Greeting() {
    const curHr = (new Date()).getHours()
    const userLink = (chunk) => <a className="text-link" href={mkHref({suffix: `/members/${session.user.userId}`, tenant: tenant})}>{chunk}</a>
    return (
      tenant.accessingAs().accessType === "MEMBER" ? (
        <div className={styles["greeting-container"]}>
          <div className={styles["greeting"]}>
            {
              curHr < 12 ? (
                intl.msg("greeting_morning", {member: session.user.fullName, userLink: userLink})
              ) : curHr < 18 ? (
                intl.msg("greeting_afternoon", {member: session.user.fullName, userLink: userLink})
              ) : (
                intl.msg("greeting_evening", {member: session.user.fullName, userLink: userLink})
              )
            }
          </div>
          <div className={styles["greeting"]}>
            {intl.msg("welcome", {brand: brandId})}
          </div>
        </div>
      ) : (
        <></>
      )
    )
  }

  return (
    tenant &&
      <>

        <MainContainer tenant={tenant} menu={MainMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {
                  tenant.accessingAs("*/MEMBER/*") ? (
                    <AccountHeader tenant={tenant}/>
                  ) : (
                    <div>
                      {intl.msg("account")}
                    </div>
                  )
                }
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  canEdit() &&
                    <ActionButton action="edit" onClick={handleEditClick}/>
                }
                <MoreMenu/>
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            {
              tenant &&
                <>

                  <Greeting/>

                  <div className={`${!["INVITING", "ONBOARDING"].includes(tenant.status) ? styles.container : ""}`}>

                    {
                      tenant.accessingAs("BASIC/MEMBER,MANAGER/*") && !["INVITING","ONBOARDING"].includes(tenant.status) &&
                        <div className={styles.widgets_container}>
                        <AccountSummaryWidgets tenant={tenant}/>
                        </div>
                    }

                    {
                      (session.user?.isSystem() || tenant.accessingAs("BASIC/MEMBER,MANAGER/CLIENT")) &&
                        <div className={styles.active_broadcasts_container}>
                          <ActiveBroadcastsSection/>
                        </div>
                    }

                    {
                      tenant.accessingAs("BASIC/MEMBER,MANAGER/*") &&
                        <div className={styles.quick_access_container}>
                          <QuickAccessSection/>
                        </div>
                    }

                    <div className={styles.account_info_container}>
                      <AccountInfoSection/>
                    </div>

                    {
                      ("SOLV" === brandId) && tenant.accessingAs("BASIC/MEMBER,MANAGER/*") &&
                        <div className={styles.learn_container}>
                          <LearnSection/>
                        </div>
                    }
                  </div>
                </>
            }

          </FormBody>

        </MainContainer>

        <CreditTransactionTopupDialog key={"topup"} tenant={tenant}/>

        <CreditsAdjustDialog key={"extend"} tenant={tenant} currencyCode={tenant.currencyCode}/>

        {
          CreditsExpiring(tenant.credits) &&
            <CreditsExtendExpiryDialog key={"extend"} tenant={tenant}/>
        }

        <ResellerInviteDialog tenant={tenant} reinvite={tenant && "INVITING" === tenant.status}/>
        <ResellerMoveDialog tenant={tenant}/>

        <ClientMoveDialog tenant={tenant}/>
        <ClientInviteDialog tenant={tenant}/>

        <AccountDeleteDialog tenant={tenant}/>

      </>
  )
}