import React, { useState, useEffect } from "react"
import {useHistory, useLocation, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {openDialog} from "../components/DialogUtils";
import {Icon, MainContainer, SelectList} from "../components/FormComps"
import {PageDialogContainer, PageDialog, PageDialogMainPanel} from "../components/PageDialog"
import {GoogleButton} from "../components/GoogleButton"
import {MicrosoftButton} from "../components/MicrosoftButton";
import {LinkedInButton} from "../components/LinkedInButton"
import {isBlankString} from "../components/StringUtils";
import {validateEmailAddress} from "../components/ValidationUtils";
import {PageDialogHeader, PageDialogFooter, PageDialogSider} from "./PageDialogUtils";
import {usePrefs} from "../components/PrefUtils";
import getLogger from "../components/Logging.js"
import "../App.css"
import "./Elct.css"

const log = getLogger("Signin")

function hashToStep(hash) {
  if (hash) {
    if (hash.startsWith("#")) {
      return hash.slice(1).toUpperCase()
    }
    else {
      return hash.toUpperCase()
    }
  }
  else {
    return null
  }
}

export default function Signin(props) {

  const {api, auth: {signin, generateMagicLink}, env, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()
  const {savePrefs} = usePrefs()

  const params = useParams()
  const location = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)

  const [emailAddress, setEmailAddress] = useState(queryParams.get("emailAddress"))
  const [recaptchaResponse, setReCaptchaResponse] = useState(null)
  const [magicCode, setMagicCode] = useState(params.magicCode)
  const [googlePayload, setGooglePayload] = useState(null)
  const [googleAccounts, setGoogleAccounts] = useState(null)
  const [googleAccount, setGoogleAccount] = useState(null)

  const [step, setStep] = useState(null)

  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (magicCode) {
      signinWithMagicCode()
    }
  },[magicCode])

  useEffect(() => {
    setStep(hashToStep(location.hash))
  }, [location])

  async function handleGoogleLoginSuccess(response){
    try {
      log.debug("googleHandleSuccessfulLogin: response=", response)
      setBusy(intl.msg("working"))
      const payload = {
        idProvider: "GOOGLE",
        profileObj: response.profileObj,
        authToken: response.tokenId
      }
      setGooglePayload(payload)
      log.debug("googleHandleSuccessfulLogin: payload=", payload)
      await doSignin(payload)
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  function handleGoogleLoginError(error) {
    log.debug("googleHandleFailedLogin: ", error)
    setAlert({error: intl.msg("error_failed_google_auth")})
  }

  async function handleMicrosoftLoginSuccess(data){
    try {
      setBusy(intl.msg("working"))
      const payload = {
        idProvider: "MICROSOFT",
        account: data.account,
        authToken: data
      }
      await doSignin(payload)
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  function handleMicrosoftLoginError(err) {
    log.error("handleMicrosoftLoginError: ERROR=", err)
    setAlert({error: intl.msg("error_failed_microsoft_auth")})
  }

  async function handleLinkedInLoginSuccess(response){
    try {
      setBusy(intl.msg("working"))
      const payload = {
        idProvider: "LINKEDIN",
        code: response
      }
      await doSignin(payload)
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  function handleLinkedInLoginError(err){
    log.error("handleLinkedInLoginError: ERROR=", err)
    setAlert({error: intl.msg("error_failed_linkedin_auth")})
  }

  function handleEmailAddressChange(e) {
    e.preventDefault()
    setEmailAddress(e.target.value)
  }

  async function handleEmailContinueClick() {
    try {
      setBusy(intl.msg("working"))
      if (isBlankString(emailAddress) || !validateEmailAddress(emailAddress)) {
        setAlert({error: intl.msg("error_invalid_input_email_address")})
      }
      else {
        const payload = {
          op: "SIGN_IN",
          emailAddress: emailAddress,
          recaptcha: recaptchaResponse,
          dest: queryParams.get("dest"),
        }
        await generateMagicLink(payload)
        setAlert(null)
        queryParams.set("emailAddress", emailAddress)
        window.location.hash = "#check_email"
        window.location.search = queryParams.toString()
      }
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  async function handleGoogleAccountChange(v) {
    setGoogleAccount(v)
    let payload = googlePayload
    payload.emailAddress = v.value
    log.debug("handleGoogleAccountChange: payload=", v, payload)
    await doSignin(payload)
  }

  async function signinWithMagicCode() {
    try {
      setBusy(intl.msg("working"))
      const payload = {
        op: "SIGN_IN",
        idProvider: "MAGICLINK",
        code: magicCode
      }
      setAlert(null)
      await doSignin(payload)
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  async function doSignin(payload){
    try {
      log.debug(">>>Z: doSignin: payload=", payload)
      const data = await signin(payload)
      log.debug(">>>Z: doSignin: data=", data)
      if (data.user) {
        let prefs = {
          lang: data.user.lang || "system",
          theme: data.user.theme || "system"
        }
        savePrefs(prefs)
      }
      gotoDest(data)
    }
    catch (err) {
      log.error(err)
      handleError(err)
    }
  }

  function gotoDest(signInData) {
    let dest = "/";
    if (signInData.dest) {
      dest = signInData.dest
    }
    else if (props.location && props.location.state && props.location.state.referrer) {
      dest = props.location.state.referrer
      if (dest.pathname) {
        dest = dest.pathname
      }
      else {
        dest = "/"
      }
    }
    window.location = dest
    // }
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError", error.code)
      const code = error.code
      switch (code) {
        case "MULTIPLE_GOOGLE_ACCOUNTS":
          if (error.details?.accounts) {
            const opts = error.details.accounts.map(a => {
              return ({
                label: `${a.tenantName} (${a.emailAddress })`,
                value: a.emailAddress,
              })
            })
            opts.sort((a, b) => a.label.localeCompare(b.label));
            setGoogleAccounts(opts)
            setGoogleAccount(null)
            setAlert(null)
            setStep("SELECT_GOOGLE_ACCOUNT")
          }
          else {
            setAlert({error: intl.msg("error_failed")})
          }
          break
        case "UNAUTHORIZED":
        case "NOT_FOUND":
          setAlert({error: intl.msg("error_unknown_user")})
          break
        case "BANNED_USER":
        case "BANNED_TENANT":
          setAlert({error: intl.msg("error_banned_user")})
          break
        case "BANNED_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_banned_email_address")})
          break
        case "INVALID_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_invalid_email_address")})
          break
        case "INVALID_RECAPTCHA":
          setAlert({error: intl.msg("error_invalid_recaptcha")})
          break
        case "INVALID_MAGIC_LINK":
          setAlert({error: intl.msg("error_invalid_magic_link")})
          break
        case "DUPLICATE_MAGIC_LINK":
          setAlert({error: intl.msg("error_duplicate_magic_link")})
          break
        case "INVITING":
          setAlert({error: intl.msg("signin_error_inviting", {a: chunks => <a className="text-link" href={`/signup/${error.details.userId}`}>{chunks}</a>})})
          break
        case "UNAVAILABLE":
          window.location = "/unavailable"
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  return (
    <>
      <MainContainer>
        <PageDialogContainer>
          {
            ("EMAIL" === step) ? (
              <PageDialog header={PageDialogHeader} sider={PageDialogSider} footer={PageDialogFooter} size="md" align="center">
                <PageDialogMainPanel title={intl.msg("signin_with_email_title")} alert={alert}>
                  <p className="mt-3">
                    {intl.msg("signin_with_email_text", {a_recaptcha: chunks => <a className="def-link" href="https://en.wikipedia.org/wiki/ReCAPTCHA" target="_blank">{chunks}</a>})}
                  </p>
                  <input className="form-control" type="text" key="inp_emailAddress" id="inp_emailAddress" style={{width: "300px", padding: "20px"}} placeHolder={intl.msg("your_email_address_placeholder")} value={emailAddress} onChange={handleEmailAddressChange}/>
                  <div id="fg_captcha" style={{marginTop: "10px"}}>
                    <ReCAPTCHA
                        sitekey={`${env.GOOGLE_RECAPTCHA_SECRET}`}
                        onChange={(e) => setReCaptchaResponse(e)}
                        theme={window.getComputedStyle(document.documentElement).getPropertyValue("--theme")}/>
                  </div>
                  <button className="btn btn-primary mt-3" style={{minWidth: "100px", padding: "8px", marginTop: "10px"}} onClick={handleEmailContinueClick}>
                    {intl.msg("continue")}
                  </button>
                  <div className="mt-3">
                    <a className="text-link" href="/signin">{intl.msg("signin_with_email_use_another_means")}</a>
                  </div>
                </PageDialogMainPanel>
              </PageDialog>
            ) : ("CHECK_EMAIL" === step) ? (
              <PageDialog header={PageDialogHeader} sider={PageDialogSider} footer={PageDialogFooter} size="md" align="center">
                <PageDialogMainPanel title={intl.msg("signin_with_email_check_your_email_title")} alert={alert}>
                  <p className="mt-3">
                    {intl.msg("signin_with_email_check_your_email_text", {brand: "ELCT", emailAddress: emailAddress, b: chunks => <strong>{chunks}</strong>, em: chunks => <em>{chunks}</em>, p: chunks  => <p className="mt-3">{chunks}</p>})}
                  </p>
                  <button type="button" className="btn btn-primary mt-3" onClick={handleEmailContinueClick}>{intl.msg("resend")}</button>
                </PageDialogMainPanel>
              </PageDialog>
            ) : ("SELECT_GOOGLE_ACCOUNT" === step) ? (
              <PageDialog header={PageDialogHeader} sider={PageDialogSider} footer={PageDialogFooter} size="md" align="center">
                <PageDialogMainPanel title={intl.msg("signin_select_account_title")} alert={alert}>
                  <p className="mt-3">
                    {intl.msg("signin_select_account_text", {brand: "ELCT", emailAddress: emailAddress, b: chunks => <strong>{chunks}</strong>, em: chunks => <em>{chunks}</em>, p: chunks  => <p className="mt-3">{chunks}</p>})}
                  </p>
                  <SelectList
                    id="inp_account"
                    value={googleAccount}
                    options={googleAccounts}
                    menuIsOpen={true}
                    onChange={handleGoogleAccountChange}
                    isDisabled={false}/>
                </PageDialogMainPanel>
              </PageDialog>
            ) : (
              <PageDialog header={PageDialogHeader} sider={PageDialogSider} footer={PageDialogFooter} size="md" align="center">
                <PageDialogMainPanel title={intl.msg("signin_title", {brandName: "ELCT"})} alert={alert}>
                  <p className="mt-3">
                    {intl.msg("signin_text")}
                  </p>
                  <div className="mt-1">
                    <GoogleButton
                      onSuccess={handleGoogleLoginSuccess}z
                      onError={handleGoogleLoginError}
                      disabled={false}
                      buttonText={intl.msg("signin_with_google")}/>
                  </div>
                  <div className="mt-2">
                    <LinkedInButton
                      onSuccess={handleLinkedInLoginSuccess}
                      onError={handleLinkedInLoginError}
                      buttonText={intl.msg("signin_with_linkedin")}/>
                  </div>
                  <div className="d-flex flex-row align-items-center mt-2 gap-1">
                    <span className="text-muted">-</span><span>or</span><span className="text-muted">-</span>
                  </div>
                  <div className="mt-2">
                    <input className="form-control" type="text" value={emailAddress} placeholder={"Enter email address"} onChange={handleEmailAddressChange}/>
                    <button className="btn-signin btn-signin-email mt-1" type="button" disabled={!emailAddress || emailAddress.trim().length === 0} onClick={handleEmailContinueClick}>
                      <div className="btn-signin-icon">
                        <Icon name="envelope2"/>
                      </div>
                      <span className="btn-signin-text">{intl.msg("signin_with_email")}</span>
                    </button>
                  </div>
                  <div className="mt-3">
                    <p style={{fontSize: "8pt"}}>{intl.msg("signin_by_clicking", {a1: chunks => <a href="https://sassets.solv.technology/legal/terms.html" target="_blank">{chunks}</a>, a2: chunks => <a href="https://sassets.solv.technology/legal/privacy.html" target="_blank">{chunks}</a>})}</p>
                  </div>
                  <div className="mt-4" style={{borderTop: "1px solid #555", paddingTop: "20px"}}>
                    {intl.msg("signin_dont_have_an_account")}
                  </div>
                </PageDialogMainPanel>
              </PageDialog>
            )
          }
        </PageDialogContainer>
      </MainContainer>
    </>
  )
}