import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import {useSolv, useTenant} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {DropdownMenu, DropdownMenuItem, FormBody, FormHeader, MainContainer} from "./components/FormComps.js"
import ExportToFileDialog from "./components/ExportToFileDialog";
import {openDialog, openDialogCurried} from "./components/DialogUtils";
import {AccountTabs} from "./components/AccountUtils";
import {mkHref, TenantLink} from "./components/AccountUtils";
import {DataTable, useCols} from "./components/TableComps";
import {HumanTimestamp} from "./components/DateUtils";
import getLogger from "./components/Logging.js"
import './App.css'
import {
  CommissionStatementStatus,
  CommissionsWidget,
  useCommissionStatementStatusOpts
} from "./components/CommissionUtils";
import {useQuery} from "react-query";

const log = getLogger("CommissionStatements")

export default function CommissionStatements(props) {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useState(null)

  const [selectedRow, setSelectedRow] = useState(null)

  const {commissionStatementStatusOpts} = useCommissionStatementStatusOpts()

  const [dateRange, setDateRange] = useState("")

  const {cols} = useCols({
    specs: [
      {
        field: "createdOn",
        label: intl.msg("commission_statement_date"),
        filter: {
          type: "select:reportDateRange",
          required: true,
          default: "Last30Days"
        },
        sort: "DESC",
        align: "left",
      },
      {
        field: "tenantName",
        label: intl.msg("account"),
        filter: "text",
        sort: "",
        align: "left",
        visible: function() {
          return tenant && tenant.accessingAs("*/MEMBER/SYSTEM")
        },
      },
      {
        field: "statementNo",
        label: intl.msg("commission_statement_no"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "total",
        label: <span>{intl.msg("commission_statement_total")} (USD)</span>,
        filter: null,
        sort: "",
        align: "right",
        showTotal: true,
      },
      {
        field: "status",
        label: intl.msg("status"),
        filter: {
          type: "select",
          options: () => commissionStatementStatusOpts,
          multi: true,
        },
        sort: "",
        align: "left",
      },
    ],
    storageItemName: "incomeStatements",
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listIncomeStatements({tenantId: tenant.tenantId, extraParams: cols.stringify(), cursor: cursor, limit: -1})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "createdOn":
        n = <HumanTimestamp timestamp={row.createdOn} else={intl.msg("never")}/>
        break
      case "tenantName":
        n = <TenantLink tenant={row} suffix={`/commissions`}/>
        break
      case "statementNo":
        n = <span>{row.statementNo}</span>
        break
      case "total":
        n = <span>{intl.num(row.total)}</span>
        break
      case "status":
        n = <CommissionStatementStatus row={row} size="lg"/>
    }
    return (
      n
    )
  }

  function handleRowClick(row) {
    setSelectedRow(row)
    window.location = mkHref({suffix: `/commissions/${row.commissionStatementId}`, tenant: tenant})
  }

  function handleRenderFooterCol(col, data) {
    switch (col.field) {
      case "credits":
        let total = 0
        data.pages.map((group, i) => {
          group.data.map((row, j) => {
            total = total + (row[col.field] || 0)
          })
        })
        return <span>{intl.num(total)}</span>
      default:
        return <></>
    }
  }

  async function handleExport() {
    return api.exportCommissionStatements({tenantId: tenant.tenantId, extraParams: cols.stringify()})
  }

  function handleFilterChange(col, value) {
    if ("createdOn" === col.field) {
      setDateRange(`${intl.date(new Date(value.startTime), "ISO-COMPACT")}-${intl.date(new Date(value.endTime), "ISO-COMPACT")}`)
    }
  }

  function canExport() {
    return tenant.accessingAs(["BASIC/*/*"])
  }

  function canPrint() {
    return tenant.accessingAs(["BASIC/*/*"])
  }

  function FormControls(props) {
    const ctls = []

    ctls.push(<MoreMenu/>)

    return ctls
  }

  function MoreMenu(props) {

    const ctls = []

    // if (canPrint()) {
    //   ctls.push(
    //     <DropdownMenuItem msg="print" icon="invoice" onClick={openDialogCurried("dlg_credits_print")}/>
    //   )
    // }
    //

    // if (canAdjust()) {
    //   ctls.push(
    //     <DropdownMenuItem name="adjust" onClick={openDialogCurried("dlg_credits_adjust")}/>
    //   )
    // }

    if (canExport()) {
      ctls.push(
        <DropdownMenuItem name="export" onClick={openDialogCurried("dlg_credits_export")} prefixWithDivider={ctls.length > 0}/>
      )
    }

    return (
      ctls.length > 0 ? (
        <DropdownMenu>
          {ctls}
        </DropdownMenu>
      ) : (
        <></>
      )
    )
  }

  useEffect(() => {
    log.debug(">>>Z: CommissionStatements: tenant changed", tenant)
  }, [tenant])

  return (
    tenant &&
    <>
      <MainContainer tenant={tenant} menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("commissions")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              <FormControls/>
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <div className="row no-gutters">
            <div className="col d-flex justify-content-center">
              <CommissionsWidget tenant={tenant}/>
            </div>
          </div>

          <div className="row no-gutters pt-3">
            <div className="col">

              <div className={`row ${tenant.isClient() ? "mt-4" : ""}`}>
                <div className="col">
                  <DataTable
                    tenant={tenant}
                    cols={cols}
                    resourceName="commissionStatements"
                    dataKey="incomeStatementId"
                    onFetchDataPage={handleFetchDataPage}
                    onRenderDataCol={handleRenderDataCol}
                    onRenderFooterCol={handleRenderFooterCol}
                    onClickDataRow={handleRowClick}
                    onFilterChange={handleFilterChange}
                  />
                </div>
              </div>
            </div>
          </div>

        </FormBody>

      </MainContainer>
      <ExportToFileDialog
        key="download"
        type="commissionStatements"
        fileName={`solv-commission-statements-${dateRange}`}
        fileExt={"csv"}
        onExport={handleExport}/>
    </>

  )
}

