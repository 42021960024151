import React, {useState, useEffect, useReducer, useRef} from 'react'
import { useParams}  from 'react-router-dom'
import {useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert, FormGroup, FormGroups, DropdownMenuItem, DropdownMenu, ActionButton, ExternalLinkButton} from "./components/FormComps.js"
import MainMenu from "./MainMenu"
import {useTenant} from "./components/SolvProvider";
import {AccountTabs} from "./components/AccountUtils";
import {UserAndTimeLink} from "./components/UserUtils";
import {openDialogCurried} from "./components/DialogUtils";
import {useReactToPrint} from "react-to-print";
import {simpleReducer} from "./components/ReactUtils";
import {mkHref} from "./components/AccountUtils";
import {DataTable, useCols} from "./components/TableComps";
import {Date} from "./components/DateUtils";
import {CommissionStatementStatus} from "./components/CommissionUtils";
import CommissionStatementSettleDialog from "./CommissionStatementSettleDialog";
import CommissionStatementBill from "./CommissionStatementBill";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("CommissionStatement")

export default function CommissionStatement(props) {

  const {brandId, api, env, session, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()
  const [touched, setTouched] = useState(false)

  const [commissionStatement, setCommissionStatement] = useState(null)
  const [commissionStatementItemRows, setCommissionStatementItemRows] = useState(null)
  const [billingInfo, updateBillingInfo] = useReducer(simpleReducer, {})
  const [dateRange, setDateRange] = useState("")

  const statementRef = useRef(null)
  const handlePrint = useReactToPrint({
    contentRef: statementRef,
    documentTitle: commissionStatement?.statementNo,
    pageStyle: `@page { @top-left { content: '  '; } @bottom-left { content: '${window.location.href}'} @bottom-right { content: counter(page) '/' counter(pages); }`
  });

  const {cols: commissionStatementItemCols} = useCols({
    specs: [
      {
        field: "createdOn",
        label: intl.msg("date"),
        sort: "DESC",
        align: "left",
      },
      {
        field: "tenantName",
        label: intl.msg("account"),
        // filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "description",
        label: intl.msg("item"),
        // filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "amount",
        label: <span>{intl.msg("amount")} (USD)</span>,
        // filter: null,
        sort: "",
        align: "right",
        showTotal: true,
      },
    ],
    storageItemName: "commissionStatementItems",
  })

  useEffect(() => {
    if (tenant && params.commissionStatementId) {
      setBusy(intl.msg("working"))
      api.getCommissionStatement(params.commissionStatementId)
        .then(({data}) => {
          setCommissionStatement(data)
        })
        .catch((err) => {
          setFatal(err)
        })
        .finally(() => {
          setBusy(null)
        })
    }
  }, [tenant])


  function handleItemFetchDataPage({pageParam: cursor}) {
    return api.listCommissionStatementItems({commissionStatementId: params.commissionStatementId, extraParams: commissionStatementItemCols.stringify(), cursor: cursor, limit: -1})
  }

  function handleItemFetchStatusChange(status, data) {
    if ("success" === status) {
      setCommissionStatementItemRows(data)
    }
  }

  function handleItemRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "createdOn":
        n = <Date timestamp={row.createdOn} else={intl.msg("never")} format="ISO"/>
        break
      case "tenantName":
        n = <a className="text-link" href={`/${row.tenantId}`}>{row.tenantName}</a>
        break
      case "description":
        n = <a className="text-link" href={`/${row.tenantId}/broadcasts/${row.refId}`}>{row.description}</a>
        break
      case "amount":
        n = <span>{intl.num(row.amount)}</span>
        break
    }
    return (
      n
    )
  }

  function handleItemRenderFooterCol(col, data) {
    switch (col.field) {
      case "credits":
        let total = 0
        data.pages.map((group, i) => {
          group.data.map((row, j) => {
            total = total + (row[col.field] || 0)
          })
        })
        return <span>{intl.num(total)}</span>
      default:
        return <></>
    }
  }

  function handleItemFilterChange(col, value) {
    if ("createdOn" === col.field) {
      setDateRange(`${intl.date(new Date(value.startTime), "ISO-COMPACT")}-${intl.date(new Date(value.endTime), "ISO-COMPACT")}`)
    }
  }

  async function handleEditClick() {
    window.location = mkHref({suffix: `/commissions/${params.commissionStatemmentId}/_edit`, tenant: tenant, user: session.user})
  }

  function isEditing() {
    return touched
  }

  function FormControls() {

    const ctls = []

    if (commissionStatement) {

      if (canSettle()) {
        ctls.push(
          <ActionButton name="settle" flavor="primary" onClick={openDialogCurried("dlg_commission_statement_settle")}/>
        )
      }
      ctls.push(
        <MoreMenu/>
      )
    }

    return (ctls)
  }

  // function canEdit() {
  //   return (!isEditing() && commissionStatement && ("PENDING" === commissionStatement.status) && tenant.accessingAs("ADMIN/MANAGER/SYSTEM"))
  // }

  // function canDelete() {
  //   return (!isEditing() && commissionStatement && ("PENDING" === commissionStatement.status) && (isMine() || tenant.accessingAs("ADMIN/MEMBER,MANAGER/*")))
  // }

  function canPrint() {
    return (!isEditing() && commissionStatement && commissionStatementItemRows)
  }

  // function canReverse() {
  //   return (!isEditing() && tenant.accessingAs("ADMIN/*/SYSTEM") && commissionStatement && ("ACTIVE" === commissionStatement.status) && (!commissionStatement.reversedOn) && ["PURCHASE", "BONUS", "EXPIRE"].includes(commissionStatement.commissionStatementType))
  // }
  //

  function canSettle() {
    return ("PENDING" === commissionStatement?.status && tenant && tenant.accessingAs("ADMIN/MANAGER/SYSTEM"))
  }


  function MoreMenu(props) {

    const cmps = []

    if (canPrint()) {
      cmps.push(
        <DropdownMenuItem msg="print" icon="invoice" onClick={handlePrint} prefixWithDivider={cmps.length > 0}/>
      )
    }

    // if (canDelete()) {
    //   cmps.push(
    //     <DropdownMenuItem name="delete" onClick={openDialogCurried("dlg_credit_transaction_delete")} prefixWithDivider={cmps.length > 0}/>
    //   )
    // }

    return (
      cmps.length > 0 &&
        <DropdownMenu>
          {cmps}
        </DropdownMenu>
    )
  }

  return (
    tenant &&
    <>
      <MainContainer tenant={tenant} menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("commission_statement")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              <FormControls/>
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>
        <FormBody>

          <FormGroups>

            <FormGroup disabled={true}>
              <FormGroup.Label text={intl.msg("commission_statement_no")}/>
              <FormGroup.Control>
                <label className="form-control">{commissionStatement?.statementNo}</label>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup disabled={true}>
              <FormGroup.Label text={intl.msg("commission_statement_total")}/>
              <FormGroup.Control>
                <label className="form-control">{intl.num(commissionStatement?.total)} USD</label>
              </FormGroup.Control>
            </FormGroup>

            {
              "SETTLED" === commissionStatement?.status ? (
                <>
                  <FormGroup disabled={true}>
                    <FormGroup.Label text={intl.msg("status")}/>
                    <FormGroup.Control>
                      <CommissionStatementStatus row={commissionStatement} size="lg"/>
                    </FormGroup.Control>
                  </FormGroup>

                  <FormGroup disabled={true}>
                    <FormGroup.Label text={intl.msg("settled")}/>
                    <FormGroup.Control>
                      <label className="form-control">
                        <UserAndTimeLink user={commissionStatement?.settledBy} timestamp={commissionStatement?.settledOn} session={session} tenant={tenant}/>
                      </label>
                    </FormGroup.Control>
                  </FormGroup>

                  {
                    commissionStatement?.settleEtc?.invoiceInfo?.invoiceNo &&
                      <FormGroup disabled={true}>
                        <FormGroup.Label text={intl.msg("invoice_no")}/>
                        <FormGroup.Control>
                          <label className="form-control">{commissionStatement.settleEtc.invoiceInfo.invoiceNo}</label>
                        </FormGroup.Control>
                      </FormGroup>
                  }

                  {
                    commissionStatement?.settleEtc?.paymentInfo?.paymentMethod &&
                      <FormGroup disabled={true}>
                        <FormGroup.Label text={intl.msg("payment_method")}/>
                        <FormGroup.Control>
                          <label className="form-control">{intl.msg(`payment_method_${commissionStatement?.settleEtc?.paymentInfo?.paymentMethod?.toLowerCase()}`)}</label>
                        </FormGroup.Control>
                      </FormGroup>
                  }

                  {
                    commissionStatement?.settleEtc?.paymentInfo?.refInfo &&
                      <FormGroup disabled={true}>
                        <FormGroup.Label text={intl.msg("payment_ref_info")}/>
                        <FormGroup.Control>
                          <label className="form-control">{commissionStatement.settleEtc.paymentInfo.refInfo}</label>
                        </FormGroup.Control>
                      </FormGroup>
                  }

                </>
              ) : (
                <FormGroup disabled={true}>
                  <FormGroup.Label text={intl.msg("status")}/>
                  <FormGroup.Control>
                    <CommissionStatementStatus row={commissionStatement} size="lg"/>
                  </FormGroup.Control>
                </FormGroup>
              )
            }

            <FormGroup>

              <DataTable
                tenant={tenant}
                cols={commissionStatementItemCols}
                resourceName="commissionStatementItems"
                dataKey="commissionStatementItemId"
                onFetchDataPage={handleItemFetchDataPage}
                onFetchStatusChange={handleItemFetchStatusChange}
                onRenderDataCol={handleItemRenderDataCol}
                onRenderFooterCol={handleItemRenderFooterCol}
                onFilterChange={handleItemFilterChange}
              />


            </FormGroup>

          </FormGroups>

        </FormBody>
      </MainContainer>

      <CommissionStatementSettleDialog
        commissionStatementId={params.commissionStatementId}
        tenant={tenant}
      />

      <CommissionStatementBill
        ref={statementRef}
        tenant={tenant}
        commissionStatement={commissionStatement}
        commissionStatementItemRows={commissionStatementItemRows}
      />

    </>

  )
}
