import React, {useState, useEffect, useReducer, useRef} from 'react'
import { useParams}  from 'react-router-dom'
import {useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert, FormGroup, FormGroups, DropdownMenuItem, DropdownMenu, ActionButton, ExternalLinkButton} from "./components/FormComps.js"
import MainMenu from "./MainMenu"
import {useTenant} from "./components/SolvProvider";
import {AccountTabs} from "./components/AccountUtils";
import {UserAndTimeLink} from "./components/UserUtils";
import {openDialogCurried} from "./components/DialogUtils";
import CreditTransactionReverseDialog from "./CreditTransactionReverseDialog";
import CreditTransactionBill from "./CreditTransactionBill";
import {useReactToPrint} from "react-to-print";
import {shortIdEqual} from "./components/StringUtils";
import CreditTransactionDeleteDialog from "./CreditTransactionDeleteDialog";
import {simpleReducer} from "./components/ReactUtils";
import CreditTransactionSettleDialog from "./CreditTransactionSettleDialog";
import {CreditTransactionStatus} from "./components/CreditTransactionUtils";
import getLogger from "./components/Logging.js"
import './App.css'
import {mkHref} from "./components/AccountUtils";

const log = getLogger("CreditTransaction")

export default function CreditTransaction(props) {

  const {api, env, session, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()
  const [touched, setTouched] = useState(false)

  const [creditTransaction, setCreditTransaction] = useState(null)
  const [billingInfo, updateBillingInfo] = useReducer(simpleReducer, {})
  const [bankAccounts, setBankAccounts] = useState(null)

  const invoiceRef = useRef(null)
  const handlePrint = useReactToPrint({
    contentRef: invoiceRef,
    documentTitle: creditTransaction?.invoiceNo,
    pageStyle: `@page { @top-left { content: '  '; } @bottom-left { content: '${window.location.href}'} @bottom-right { content: counter(page) '/' counter(pages); }`
  });

  useEffect(() => {
    if (tenant && params.creditTransactionId) {
      setBusy(intl.msg("working"))
      api.getCreditTransaction(tenant.tenantId, params.creditTransactionId)
        .then(({data}) => {
          setCreditTransaction(data)
          updateBillingInfo(data.tenant?.billingInfo)
          api.getBankAccountsByRegionId(data.tenant?.regionId)
            .then(({data}) => {
              setBankAccounts(data)
            })
            .catch((err) => {
              log.debug("ERROR>>>", err)
              setFatal(err)
            })
        })
        .catch((err) => {
          setFatal(err)
        })
        .finally(() => {
          setBusy(null)
        })
    }
  }, [tenant])

  async function handleEditClick() {
    window.location = mkHref({suffix: `/credits/${params.creditTransactionId}/_edit`, tenant: tenant, user: session.user})
  }

  async function handleSaveClick() {
    setTouched(false)
  }

  async function handleCancelClick() {
    setTouched(false)
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("region_error_duplicate"), field: "inp_regionId", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditing() {
    return touched
  }

  function isMine() {
    return shortIdEqual(creditTransaction.createdById, session?.user?.userId)
  }

  function FormControls() {

    const ctls = []

    if (creditTransaction) {

      if (isEditing()) {
        ctls.push(
          <ActionButton name="save" flavor="primary" onClick={handleSaveClick}/>
        )
        ctls.push(
          <ActionButton name="cancel" flavor="secondary" onClick={handleCancelClick}/>
        )
      }
      else {
        if (canSettle()) {
          ctls.push(
            <ActionButton name="settle" flavor="primary" onClick={openDialogCurried("dlg_credit_transaction_settle")}/>
          )
        }
        if (canEdit()) {
          ctls.push(
            <ActionButton name="edit" flavor="secondary" onClick={handleEditClick}/>
          )
        }
        ctls.push(
          <MoreMenu/>
        )
      }
    }

    return (ctls)
  }

  function canEdit() {
    return (!isEditing() && creditTransaction && ("PENDING" === creditTransaction.status) && (isMine() || tenant.accessingAs("ADMIN/MEMBER,MANAGER/*")))
  }

  function canDelete() {
    return (!isEditing() && creditTransaction && ("PENDING" === creditTransaction.status) && (isMine() || tenant.accessingAs("ADMIN/MEMBER,MANAGER/*")))
  }

  function canPrint() {
    return (!isEditing() && creditTransaction && (["PURCHASE", "PURCHASE_PENDING"].includes(creditTransaction.creditTransactionType)))
  }

  function canReverse() {
    return (!isEditing() && tenant.accessingAs("ADMIN/*/SYSTEM") && creditTransaction && ("ACTIVE" === creditTransaction.status) && (!creditTransaction.reversedOn) && ["PURCHASE", "BONUS", "EXPIRE"].includes(creditTransaction.creditTransactionType))
  }

  function canSettle() {
    return (!isEditing() && tenant.accessingAs("ADMIN/*/SYSTEM") && creditTransaction && ("PENDING" === creditTransaction.status))
  }

  function MoreMenu(props) {

    const cmps = []

    if (canPrint()) {
      cmps.push(
        <DropdownMenuItem msg="print" icon="invoice" onClick={handlePrint} prefixWithDivider={cmps.length > 0}/>
      )
    }

    if (canReverse()) {
      cmps.push(
        <DropdownMenuItem msg="reverse" icon="undo" onClick={openDialogCurried("dlg_credit_transaction_reverse")} prefixWithDivider={cmps.length > 0}/>
      )
    }

    if (canDelete()) {
      cmps.push(
        <DropdownMenuItem name="delete" onClick={openDialogCurried("dlg_credit_transaction_delete")} prefixWithDivider={cmps.length > 0}/>
      )
    }

    return (
      cmps.length > 0 &&
        <DropdownMenu>
          {cmps}
        </DropdownMenu>
    )
  }

  function RelationInfo(props) {
    const ctls = []

    if ("PAYMENT_TRANSACTION" === creditTransaction?.refType && "PURCHASE" === creditTransaction?.creditTransactionType) {

      ctls.push(
        <FormGroups>
        {/*// <FormGroups title={intl.msg("credits_related_payment_info")}>*/}
          <FormGroup disabled={true}>
            <FormGroup.Label text={intl.msg("payment_method")}/>
            <FormGroup.Control>
              <label className="form-control">{intl.msg(`payment_method_${creditTransaction?.paymentMethod?.toLowerCase()}`)}</label>
            </FormGroup.Control>
          </FormGroup>
          {
            (creditTransaction.details?.payment?.refInfo || creditTransaction?.details?.refInfo) ? (
              <FormGroup disabled={true}>
                <FormGroup.Label text={intl.msg("ref_info")}/>
                <FormGroup.Control>
                  <label className="form-control">
                    {creditTransaction.details?.payment?.refInfo || creditTransaction?.details?.refInfo}
                  </label>
                </FormGroup.Control>
              </FormGroup>
            ) : (creditTransaction.details?.payment?.purchase_units?.[0]?.payments?.captures?.[0]?.id) ? (
              <FormGroup disabled={true}>
                <FormGroup.Label text={intl.msg("credits_paypal_info")}/>
                <FormGroup.Control>
                  <label className="form-control">
                    {creditTransaction.details?.payment?.purchase_units?.[0]?.payments?.captures?.[0]?.id}
                  </label>
                </FormGroup.Control>
              </FormGroup>
            ) : (
              <></>
            )
          }
          {
            creditTransaction?.settledOn &&
              <FormGroup disabled={true}>
                <FormGroup.Label text={intl.msg("settled")}/>
                <FormGroup.Control>
                  <label className="form-control">
                    <UserAndTimeLink user={creditTransaction?.settledBy} timestamp={creditTransaction?.settledOn} session={session} tenant={tenant}/></label>
                </FormGroup.Control>
              </FormGroup>
          }
        </FormGroups>
      )
    }

    if ("CREDIT_TRANSACTION" === creditTransaction?.refType && creditTransaction?.ref?.creditTransactionId) {
      ctls.push(
        <FormGroups>
        {/*// <FormGroups title={intl.msg("credits_related_transaction_info")}>*/}
          <FormGroup disabled={true}>
            <FormGroup.Label text={intl.msg("credits_original_transaction")}/>
            <FormGroup.Controls>
              <div className="d-flex gap-2 align-items-center">
                <label className="form-control">{creditTransaction?.ref?.creditTransactionId} - {creditTransaction?.ref?.description}</label>
                <ExternalLinkButton href={mkHref({prefix: env.BASE_URL, suffix: `/credits/${creditTransaction?.ref?.creditTransactionId}`, tenant: tenant, user: session?.user})} validate="off"/>
              </div>
            </FormGroup.Controls>
          </FormGroup>
        </FormGroups>
      )
    }

    if ("BROADCAST" === creditTransaction?.refType && creditTransaction?.ref?.broadcastId) {
      ctls.push(
        <FormGroups>
        {/*<FormGroups title={intl.msg("credits_related_broadcast_info")}>*/}
          <FormGroup disabled={true}>
            <FormGroup.Label text={intl.msg(`credits_${"REFUND" == creditTransaction?.creditTransactionType ? "refunded" : "published"}_broadcast`)}/>
            <FormGroup.Controls>
              <div className="d-flex gap-2 align-items-center">
                <label className="form-control">{creditTransaction?.ref?.broadcastName}</label>
                <ExternalLinkButton href={mkHref({prefix: env.BASE_URL, suffix: `/broadcasts/${creditTransaction?.ref?.broadcastId}`, tenant: tenant, user: session?.user})} validate="off"/>
              </div>
            </FormGroup.Controls>
          </FormGroup>
        </FormGroups>
      )
    }

    if ("REVERSED" === creditTransaction?.status && creditTransaction?.reversedRef) {
      ctls.push(
        <FormGroups>
        {/*<FormGroups title={intl.msg("credits_related_transaction_info")}>*/}
          <FormGroup disabled={true}>
            <FormGroup.Label text={intl.msg("credits_reversal_transaction")}/>
            <FormGroup.Controls>
              <div className="d-flex gap-2 align-items-center">
                <label className="form-control">{creditTransaction?.reversedRef?.creditTransactionId} - {creditTransaction?.reversedRef?.description}</label>
                <ExternalLinkButton href={mkHref({prefix: env.BASE_URL, suffix: `/credits/${creditTransaction?.reversedRef?.creditTransactionId}`, tenant: tenant, user: session?.user})} validate="off"/>
              </div>
            </FormGroup.Controls>
          </FormGroup>
        </FormGroups>
      )
    }

    if (ctls.length > 0) {
      return (
        // <FormGroups title={intl.msg("credits_related_info")}>
          ctls
        // </FormGroups>
      )
    }
    else {
      return (<></>)
    }

  }

  return (
    tenant &&
    <>
      <MainContainer tenant={tenant} menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("credit_transaction")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              <FormControls/>
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>
        <FormBody>

          <FormGroups>

            <FormGroup disabled={true}>
              <FormGroup.Label text={intl.msg("credits_transaction_id")}/>
              <FormGroup.Control>
                <label className="form-control">{creditTransaction?.creditTransactionId}</label>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup disabled={true}>
              <FormGroup.Label text={intl.msg("credits_transaction_type")}/>
              <FormGroup.Control>
                <label className="form-control">{intl.msg(`credit_transaction_type_${creditTransaction?.creditTransactionType.toLowerCase()}`)}</label>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup disabled={true}>
              <FormGroup.Label text={intl.msg("description")}/>
              <FormGroup.Control>
                <label className="form-control">{creditTransaction?.description}</label>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup disabled={true}>
              <FormGroup.Label text={intl.msg("credits_amount")}/>
              <FormGroup.Control>
                <label className="form-control">{intl.num(creditTransaction?.credits, "SIGNED")} {creditTransaction?.tenant?.currencyCode}</label>
              </FormGroup.Control>
            </FormGroup>

            {
              ("PENDING" === creditTransaction?.status) &&
                <>
                  {
                    creditTransaction?.purchaseOrderNo &&
                      <FormGroup disabled={true}>
                        <FormGroup.Label text={intl.msg("credit_transaction_purchase_order_no")}/>
                        <FormGroup.Control>
                          <label className="form-control">{creditTransaction?.purchaseOrderNo}</label>
                        </FormGroup.Control>
                      </FormGroup>
                  }

                  <FormGroup disabled={true}>
                    <FormGroup.Label text={intl.msg("credit_transaction_billing_name")}/>
                    <FormGroup.Control>
                      <label className="form-control">{creditTransaction?.billingInfo?.name}</label>
                    </FormGroup.Control>
                  </FormGroup>

                  <FormGroup disabled={true}>
                    <FormGroup.Label text={intl.msg("credit_transaction_billing_address")}/>
                    <FormGroup.Control>
                      <label className="form-control" style={{whiteSpace: "pre-line"}}>{creditTransaction?.billingInfo?.address}</label>
                    </FormGroup.Control>
                  </FormGroup>
                </>
            }

            <FormGroup disabled={true}>
              <FormGroup.Label text={intl.msg("status")}/>
              <FormGroup.Control>
                <label className="form-control">
                  <CreditTransactionStatus row={creditTransaction} size="lg"/>
                </label>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup disabled={true}>
              <FormGroup.Label text={intl.msg("created")}/>
              <FormGroup.Control>
                <label className="form-control">
                  <UserAndTimeLink user={creditTransaction?.createdBy} timestamp={creditTransaction?.createdOn} session={session} tenant={tenant}/>
                </label>
              </FormGroup.Control>
            </FormGroup>

          </FormGroups>

          <RelationInfo/>

        </FormBody>
      </MainContainer>

      <CreditTransactionReverseDialog
        tenant={tenant}
        creditTransaction={creditTransaction}
      />

      <CreditTransactionBill
        ref={invoiceRef}
        tenant={tenant}
        creditTransaction={creditTransaction}
        bankAccounts={bankAccounts}
      />

      <CreditTransactionSettleDialog
        tenant={tenant}
        creditTransaction={creditTransaction}
      />

      <CreditTransactionDeleteDialog
        tenant={tenant}
        creditTransaction={creditTransaction}
      />

    </>

  )
}
