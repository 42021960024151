import React, {useState, useEffect, useReducer, useRef} from 'react'
import { useParams}  from 'react-router-dom'
import {SolvError, useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert, FormGroup, FormGroups, DropdownMenuItem, DropdownMenu, ActionButton, ExternalLinkButton} from "./components/FormComps.js"
import MainMenu from "./MainMenu"
import {useTenant} from "./components/SolvProvider";
import {AccountTabs} from "./components/AccountUtils";
import {UserAndTimeLink} from "./components/UserUtils";
import {openDialogCurried} from "./components/DialogUtils";
import CreditTransactionReverseDialog from "./CreditTransactionReverseDialog";
import CreditTransactionBill from "./CreditTransactionBill";
import {useReactToPrint} from "react-to-print";
import {shortIdEqual} from "./components/StringUtils";
import CreditTransactionDeleteDialog from "./CreditTransactionDeleteDialog";
import {pathReducer, simpleReducer} from "./components/ReactUtils";
import CreditTransactionSettleDialog from "./CreditTransactionSettleDialog";
import {CreditTransactionStatus, usePaymentMethodOpts} from "./components/CreditTransactionUtils";
import getLogger from "./components/Logging.js"
import './App.css'
import {mkHref} from "./components/AccountUtils";
import NumericInput from "react-numeric-input";
import {clearAllErrors, InfoBlock, InfoText, validateField} from "./components/ValidationUtils";
import {default as Select} from "react-select";

const log = getLogger("CreditTransactionEdit")

export default function CreditTransactionEdit(props) {

  const {api, env, session, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()
  const [touched, setTouched] = useState(false)

  const {paymentMethodOpts, findPaymentMethodOpt} = usePaymentMethodOpts(["BANK", "CASH", "CRYPTO", "OTHER", "WISE"])

  const [credits, setCredits] = useState(null)
  const [usdMultiplier, setUsdMultiplier] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(null)
  const [minimumCredits, setMinimumCredits] = useState(null)
  const [creditTransaction, updateCreditTransaction] = useReducer(simpleReducer, {})
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [purchaseOrderNo, setPurchaseOrderNo] = useState(null)
  const [billingInfo, updateBillingInfo] = useReducer(pathReducer, {})
  const [bankAccounts, setBankAccounts] = useState(null)

  const invoiceRef = useRef(null)
  const handlePrint = useReactToPrint({
    contentRef: invoiceRef,
    documentTitle: creditTransaction?.invoiceNo
  });

  useEffect(() => {
    if (tenant && params.creditTransactionId) {
      setBusy(intl.msg("working"))
      api.getCreditTransaction(tenant.tenantId, params.creditTransactionId)
        .then(({data}) => {
          updateCreditTransaction(data)
          const minCredits = Math.ceil(10.00 / data.usdMultiplier)
          setMinimumCredits(minCredits)
          setPaymentMethod(findPaymentMethodOpt(data.paymentMethod))
          setPurchaseOrderNo(data.purchaseOrderNo)
          updateBillingInfo({path: ".", value: data.billingInfo})
          api.getBankAccountsByRegionId(data.tenant?.regionId)
            .then(({data}) => {
              setBankAccounts(data)
            })
            .catch((err) => {
              log.debug("ERROR>>>", err)
              setFatal(err)
            })
        })
        .catch((err) => {
          log.debug("ERROR>>>", err)
          setFatal(err)
        })
        .finally(() => {
          setBusy(null)
        })
    }
  }, [tenant])

  async function handleSaveClick() {
    if (creditTransaction && validate()) {
      setBusy(intl.msg("saving"))
      if (validate()) {
        try {

          let payload = {
            credits: creditTransaction.credits,
            purchaseOrderNo: purchaseOrderNo,
            billingInfo: billingInfo,
            paymentMethodId: paymentMethod.value,
          }

          log.debug("handleSaveClick: payload=", payload)

          const res = await api.updateCreditTransaction(creditTransaction.tenantId, creditTransaction.creditTransactionId, payload)

          log.debug("handleSaveClick: result=", res)

          window.location = mkHref({prefix: `/credits`, tenant: tenant, user: session.user})
        }
        catch (error) {
          handleError(error)
        }
        finally {
          setBusy(null)
        }
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }
  }

  async function handleCancelClick() {
    setTouched(false)
    window.location = mkHref({suffix: `/credits/${params.creditTransactionId}`, tenant: tenant, user: session?.user})
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_credits", creditTransaction && creditTransaction.credits && creditTransaction.credits >= minimumCredits, "min-value", opt)
    validateField("inp_billingName", billingInfo?.name && billingInfo.name.trim().length > 0, "required", opt)
    validateField("inp_billingAddress", billingInfo?.address && billingInfo.address.trim().length > 0, "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("region_error_duplicate"), field: "inp_regionId", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleCreditsChange(v) {
    updateCreditTransaction({...creditTransaction, credits: v})
    setTouched(true)
  }

  function handlePaymentMethodChange(v) {
    updateCreditTransaction({...creditTransaction, paymentMethod: v.value})
    setPaymentMethod(v)
    setTouched(true)
  }

  function handlePurchaseOrderNoChange(e) {
    setPurchaseOrderNo(e.target.value)
    setTouched(true)
  }

  function handleBillingNameChange(e) {
    updateBillingInfo({path: "name", value: e.target.value})
    setTouched(true)
  }

  function handleBillingAddressChange(e) {
    updateBillingInfo({path: "address", value: e.target.value})
    setTouched(true)
  }

  function isMine() {
    return shortIdEqual(creditTransaction.createdById, session?.user?.userId)
  }

  function FormControls() {

    const ctls = []

    ctls.push(
      <ActionButton name="save" flavor="primary" disabled={!touched} onClick={handleSaveClick}/>
    )
    ctls.push(
      <ActionButton name="cancel" flavor="secondary" onClick={handleCancelClick}/>
    )

    return (ctls)
  }

  return (
    tenant &&
    <>
      <MainContainer tenant={tenant} menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              <div>{intl.msg("credit_transaction")}</div>
              <div className="text-muted">-</div>
              <div>{creditTransaction?.creditTransactionId}</div>
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              <FormControls/>
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>
        <FormBody>

          <FormGroups>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_credits" text={intl.msg("credits_amount")} description={intl.msg("credits_amount_description")}/>
              <FormGroup.Controls>
                <div style={{display: 'flex', alignItems: "center", width: "120px"}}>
                  <NumericInput id="inp_credits" className="form-control" style={false} strict={true} min={minimumCredits} precision={2} value={creditTransaction.credits} onChange={handleCreditsChange}/>
                  <div className="ml-2">{tenant?.currencyCode ? tenant?.currencyCode : "USD"}</div>
                </div>
                <InfoBlock>
                  <InfoText validate="min-value">{intl.msg("credits_add_credits_helptext_min_value", {minCredits: intl.num(minimumCredits), currencyCode: currencyCode})}</InfoText>
                  <InfoText>{intl.msg("credits_add_credits_helptext_note_1")}</InfoText>
                  <InfoText>{intl.msg("credits_add_credits_helptext_note_2")}</InfoText>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_purchaseOrderNo" text={intl.msg("credit_transaction_purchase_order_no")} description={intl.msg("credit_transaction_purchase_order_no_description")}/>
              <FormGroup.Controls>
                <input id="inp_purchaseOrderNo" type="text" className="form-control" value={purchaseOrderNo} onChange={handlePurchaseOrderNoChange}/>
                <InfoBlock>
                  <InfoText validate="optional">{intl.msg("helptext_optional")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_billingName" text={intl.msg("credit_transaction_billing_name")} description={intl.msg("credit_transaction_billing_name_description")}/>
              <FormGroup.Controls>
                <input id="inp_billingName" type="text" className="form-control" value={billingInfo?.name} onChange={handleBillingNameChange}/>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_billingAddress" text={intl.msg("credit_transaction_billing_address")} description={intl.msg("credit_transaction_billing_address_description")}/>
              <FormGroup.Controls>
                <textarea id="inp_billingAddress" className="form-control" value={billingInfo?.address} onChange={handleBillingAddressChange}/>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_paymentMethod" text={intl.msg("payment_method")} description={intl.msg("credit_transaction_payment_method_description")}/>
              <FormGroup.Controls>
                <div style={{width: "200px"}}>
                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    value={paymentMethod}
                    options={paymentMethodOpts}
                    // menuPortalTarget={document.body}
                    onChange={handlePaymentMethodChange}
                  />
                </div>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

          </FormGroups>

        </FormBody>
      </MainContainer>

    </>

  )
}
