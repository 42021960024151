import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import {useSolv, useTenant} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {ActionButton, DropdownMenu, DropdownMenuItem, FormBody, FormGroup, FormHeader, MainContainer} from "./components/FormComps.js"
import ExportToFileDialog from "./components/ExportToFileDialog";
import {openDialog, openDialogCurried} from "./components/DialogUtils";
import {AccountTabs} from "./components/AccountUtils";
import CreditsExtendExpiryDialog from "./CreditsExtendExpiryDialog";
import CreditTransactionTopupDialog from "./CreditTransactionTopupDialog";
import {mkHref, TenantLink} from "./components/AccountUtils";
import {DataTable, useCols} from "./components/TableComps";
import {HumanTimestamp} from "./components/DateUtils";
import {canExtendCredits, CreditsExpiring, CreditsExtendButton, CreditsWidget, CreditTransactionStatus, useCreditTransactionStatusOpts, useCreditTransactionTypeOpts} from "./components/CreditTransactionUtils";import getLogger from "./components/Logging.js"
import './App.css'
import CreditsAdjustDialog from "./CreditsAdjustDialog";

const log = getLogger("CreditTransactions")

export default function CreditTransactions(props) {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useState(null)

  const [selectedRow, setSelectedRow] = useState(null)

  const {creditTransactionStatusOpts} = useCreditTransactionStatusOpts()
  const {creditTransactionTypeOpts} = useCreditTransactionTypeOpts()

  const [dateRange, setDateRange] = useState("")

  const {cols} = useCols({
    specs: [
      {
        field: "createdOn",
        label: intl.msg("created"),
        filter: {
          type: "select:reportDateRange",
          required: true,
          default: "Last30Days"
        },
        sort: "DESC",
        align: "left",
      },
      {
        field: "tenantName",
        label: intl.msg("account"),
        filter: "text",
        sort: "",
        align: "left",
        visible: function() {
          return tenant && tenant.accessingAs("*/MEMBER/SYSTEM,RESELLER")
        },
      },
      {
        field: "creditTransactionId",
        label: intl.msg("credits_transaction_id"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "creditTransactionType",
        label: intl.msg("credit_transaction_type"),
        filter: {
          type: "select",
          options: () => creditTransactionTypeOpts,
          multi: true,
        },
        sort: "",
        align: "left",
      },
      {
        field: "description",
        label: intl.msg("description"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "credits",
        label: intl.msg("credits"),
        filter: null,
        sort: "",
        align: "right",
        showTotal: true,
      },
      {
        field: "status",
        label: intl.msg("status"),
        filter: {
          type: "select",
          options: () => creditTransactionStatusOpts,
          multi: true,
        },
        sort: "",
        align: "left",
      },
    ],
    storageItemName: "creditTransaction",
  })

  useEffect(() => {
    if (tenant) {
      if (CreditsExpiring(tenant.credits)) {
        setAlert({warning: intl.msg("credits_alert_expiring", {accessType: tenant.accessInfo?.accessType, canExtend: canExtendCredits(tenant, user).toString(), expireDays: intl.fmDaysFrom(tenant.credits.expiresOn), a: chunk => <a className="text-link" href="#" onClick={openDialogCurried("dlg_credits_extend_expiry")}>{chunk}</a>})})
      }
    }
  }, [tenant])

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listCreditTransactions({tenantId: tenant.tenantId, extraParams: cols.stringify(), cursor: cursor, limit: -1})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "createdOn":
        n = <HumanTimestamp timestamp={row.createdOn} else={intl.msg("never")}/>
        break
      case "tenantName":
        n = <TenantLink tenant={row} suffix={`/credits`}/>
        break
      case "creditTransactionType":
        n = <span>{intl.msg(`credit_transaction_type_${row.creditTransactionType.toLowerCase()}`)}</span>
        break
      case "creditTransactionId":
        n = <span>{row.creditTransactionId}</span>
        break
      case "description":
        n = <TransactionDescription row={row}/>
        break
      case "credits":
        n = <span>{intl.num(row.credits, "SIGNED")}</span>
        break
      case "status":
        n = <CreditTransactionStatus row={row} size="md"/>
    }
    return (
        n
    )
  }

  function handleRowClick(row) {
    setSelectedRow(row)
    window.location = mkHref({suffix: `/credits/${row.creditTransactionId}`, tenant: tenant})
  }

  function handleRenderFooterCol(col, data) {
    switch (col.field) {
      case "credits":
        let total = 0
        data.pages.map((group, i) => {
          group.data.map((row, j) => {
            total = total + (row[col.field] || 0)
          })
        })
        return <span>{intl.num(total, "SIGNED")}</span>
      default:
        return <></>
    }
  }

  function TransactionDescription({row}) {
    return row.description
  }

  async function handleExport() {
    return api.exportCreditTransactions({tenantId: tenant.tenantId, extraParams: cols.stringify()})
  }

  function handleFilterChange(col, value) {
    if ("createdOn" === col.field) {
      setDateRange(`${intl.date(new Date(value.startTime), "ISO-COMPACT")}-${intl.date(new Date(value.endTime), "ISO-COMPACT")}`)
    }
  }

  function canAdd() {
    return tenant.accessingAs(["SUPERADMIN/MEMBER/CLIENT"])
  }

  function canExport() {
    return tenant.accessingAs(["BASIC/*/*"])
  }

  function canPrint() {
    return tenant.accessingAs(["BASIC/*/*"])
  }

  function canAdjust() {
    return tenant?.accessingAs(["ADMIN/MANAGER/SYSTEM"])
  }

  function FormControls(props) {
    const ctls = []

    if (canAdd()) {
      ctls.push(
        <ActionButton name="add_new" flavor="primary" onClick={() => openDialog("dlg_credits_add")}/>
      )
    }

    if (canAdjust()) {
      ctls.push(
        <ActionButton name="adjust" flavor="primary" onClick={() => openDialog("dlg_credits_adjust")}/>
      )
    }

    if (canExtendCredits()) {
      ctls.push(
        <CreditsExtendButton className="ml-2" tenant={tenant} mode="BUTTON"/>
      )
    }

    ctls.push(<MoreMenu/>)

    return ctls
  }

  function MoreMenu(props) {

    const ctls = []

    // if (canPrint()) {
    //   ctls.push(
    //     <DropdownMenuItem msg="print" icon="invoice" onClick={openDialogCurried("dlg_credits_print")}/>
    //   )
    // }
    //

    // if (canAdjust()) {
    //   ctls.push(
    //     <DropdownMenuItem name="adjust" onClick={openDialogCurried("dlg_credits_adjust")}/>
    //   )
    // }

    if (canExport()) {
      ctls.push(
        <DropdownMenuItem name="export" onClick={openDialogCurried("dlg_credits_export")} prefixWithDivider={ctls.length > 0}/>
      )
    }

    return (
      ctls.length > 0 ? (
        <DropdownMenu>
          {ctls}
        </DropdownMenu>
      ) : (
        <></>
      )
    )
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("credits")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                <FormControls/>
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <div className="row no-gutters">
              <div className="col">

                {
                  tenant.isClient() &&
                    <div className="row no-gutters justify-content-center">
                      <CreditsWidget tenant={tenant}/>
                    </div>
                }

                <div className={`row ${tenant.isClient() ? "mt-4" : ""}`}>
                  <div className="col">
                    <DataTable
                      tenant={tenant}
                      cols={cols}
                      resourceName="creditTransactions"
                      dataKey="creditTransactionId"
                      onFetchDataPage={handleFetchDataPage}
                      onRenderDataCol={handleRenderDataCol}
                      onRenderFooterCol={handleRenderFooterCol}
                      onClickDataRow={handleRowClick}
                      onFilterChange={handleFilterChange}
                    />
                  </div>
                </div>
              </div>
            </div>

          </FormBody>

        </MainContainer>
        <CreditTransactionTopupDialog
            key={"topup"}
            tenant={tenant}/>
        <CreditsExtendExpiryDialog
            key={"extend"}
            tenant={tenant}/>
        <CreditsAdjustDialog
          key={"extend"}
          tenant={tenant}
          currencyCode={tenant.currencyCode}
          />
        <ExportToFileDialog
            key="download"
            type="credits"
            fileName={`solv-credit-transactions-${dateRange}`}
            fileExt={"csv"}
            onExport={handleExport}/>
      </>

  )
}

