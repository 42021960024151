import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useMutation } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useSolv } from "./components/SolvProvider";
import { useIntlEx } from "./components/IntlUtils.js"
import { AsyncPaginate } from 'react-select-async-paginate'
import { InfoBlock, InfoText } from "./components/ValidationUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkExtraParams} from "./components/TableComps";
import {ActionButton, Alert, FormGroup, useAlert} from "./components/FormComps";
import {M} from "react-select/dist/index-4bd03571.esm";
import {mkHref} from "./components/AccountUtils";

const log = getLogger("ClientMoveDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function ClientMoveDialog(props)  {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [resellerCandidate, setResellerCandidate] = useState(null)

  const [touched, setTouched] = useState(true)
  const [alert, setAlert] = useAlert(null)

  useEffect(() => {
    if (props.tenant) {
      if (props.tenant.tenantTypeId === "CLIENT") {

      }
    }
  }, [props]);

  function loadResellerCandidates(search, loadedOptions, additional) {
    log.debug(">>>Z: loadResellerCandidates:", search, loadedOptions, additional)
    return api.listResellerCandidates({tenantId: props.tenant.tenantId, extraParams: mkExtraParams({filterParams: {tenantName: search?.trim()}, sortParams: {tenantName: "ASC"}}), cursor: additional?.cursor})
      .then(({data, nextCursor}) => {
        log.debug(">>>Z: listResellerCandidates: data=", data)
        let opt = data.map(c => {
            return {
              value: c.tenantId,
              label: <span>{
                c.pathSpec?.map((p, i) =>
                  i === c.pathSpec.length - 1 ? p : <><span>{p}</span><span className="text-muted"><b> &#8601; </b></span></>
                )
              }</span>,
              pop: c.pop
            };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug(">>>Z: listResellerCandidates: res=", res)
        return res
      });
  }

  function validate() {
    return true
  }

  async function handleMoveClick() {

    setAlert(null)

    if (validate()) {

      setBusy(intl.msg("working"))
      try {

        const payload = {
          resellerTenantId: resellerCandidate.value,
        }

        const {data} = await api.moveClient(props.tenant.tenantId, payload)

        window.location = mkHref({suffix: `/`, tenant: props.tenant, user: user})
      }
      catch (err) {
        handleError(err)
      }
      finally {
        setBusy(null)
      }

    }
    else {
      log.debug("Validation error")
      setAlert({error: intl.msg("error_invalid_form")})
      setTouched(true)
    }

  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      switch (error.code) {
        case "DUPLICATE_KEY":
        case "DUPLICATE_RESELLER":
          setAlert({error: intl.msg("reseller_move_error_sub_reseller_already_exits")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
          setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleResellerChange(value) {
    log.debug("handleResellerChange: value=", value)
    setResellerCandidate(value)
    setTouched(true)
  }

  function canEdit() {
    return true
  }

  function handleOpenDialog() {
    setAlert(null)
    setBusy(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_client_move")
  }

  return (

    // props.tenant ? (
      <ModalDialog
        dialogId="dlg_client_move"
        size="lg"
        keyboard={"true"}
        backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}>

        <ModalDialog.Header title={intl.msg("client_move_title")} description={intl.msg("client_move_subtitle")} alert={alert}/>

        <ModalDialog.Body>

          <Alert className="pb-3" alert={{warning: intl.msg("client_move_alert", {tenantName: props?.tenant?.tenantName})}}/>

          <FormGroup>
            <FormGroup.Label text={intl.msg("client_move_parent_reseller")} description={intl.msg("client_move_parent_reseller_description")} htmlFor="inp_parentReseller"/>
            <FormGroup.Controls>
              <AsyncPaginate
                id="inp_parentReseller"
                className="react-select"
                classNamePrefix="react-select"
                cacheOptions
                isClearable
                value={resellerCandidate}
                debounceTimeout={800}
                defaultOptions={true}
                loadOptions={loadResellerCandidates}
                onChange={handleResellerChange}
                isDisabled={!canEdit()}
              />
              <InfoBlock>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <ActionButton name="cancel" onClick={handleCloseDialog}/>
            <ActionButton name="move" flavor="primary" disabled={!resellerCandidate} onClick={handleMoveClick}/>
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>
      </ModalDialog>
  )
}