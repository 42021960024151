import React, { Fragment, Component, useState, useRef, useEffect }  from 'react'
import './App.css'

export default function Page403() {

  return (
    <Fragment>
        <main>

          <div class="jumbotron m-3">
            <center>
              <h1 style={{fontSize: "4.5rem"}}><i class="fas fa-ban"></i></h1>
              <h1 style={{fontSize: "2.5rem", color: "#999"}}>403</h1>
              <h1 style={{fontSize: "3rem", color: "#fff"}}>Access Denied</h1>
              <p className="lead" style={{color: "#ccc"}}>
                You do not have permission to access the page you're looking for or your session has expired.<br/>
                <div className="d-flex flex-row justify-content-center gap-2 mt-4">
                  <a className="btn btn-secondary mr-2" href="/">Home</a>
                  <a className="btn btn-secondary" href="/signin">Sign in</a>
                </div>
              </p>
            </center>
          </div>            

        </main>
      </Fragment>
    )
}
