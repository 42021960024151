import React, {Fragment, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useQueryClient} from 'react-query'
import {useSolv, useTenant} from "../components/SolvProvider"
import MainMenu from "../MainMenu"
import {FormHeader, FormBody, MainContainer, useAlert, ActionButton} from "../components/FormComps.js"
import { useIntlEx } from "../components/IntlUtils"
import {DataTable, useCols} from "../components/TableComps";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Countries")

export default function Countries() {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert(null)

  const [reloadId, setReloadId] = useState(null)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (!user.isSystem()) {
      setFatal("DENIED_ACCESS")
    }
  },[])


  useEffect(() => {
    if (tenant) {
      log.debug("Region: tenant=", tenant.accessingAs("ADMIN/*/SYSTEM"))
    }
  }, [tenant])

  const {cols} = useCols({
    resourceName: "countries",
    specs: [
      {
        field: "countryCode",
        label: intl.msg("country_code"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "countryName",
        label: intl.msg("country_name"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "callingCode",
        label: intl.msg("country_calling_code"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "locationCount",
        label: intl.msg("country_location_count"),
        filter: "text",
        sort: "",
        align: "left",
      },
    ],
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listCountries({extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "countryCode":
        n = <span>{row.countryCode}</span>
        break
      case "countryName":
        n = <span>{row.countryName}</span>
        break
      case "callingCode":
        n = <span>{row.callingCode}</span>
        break
      case "locationCount":
        n = <a className="text-link" href={`/sys/locations?filter=countryCode:${row.countryCode}`}>{intl.int(row.locationCount)}</a>
        break
    }
    return (
      n
    )
  }

  function handleRowClick(row) {
    window.location = `/sys/countries/${row.countryCode}`
  }

  return (

    tenant &&
    <>

      <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

        <FormHeader>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("countries")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>

              {
                tenant.accessingAs("ADMIN/*/SYSTEM") &&
                  <ActionButton name="add_new" flavor="primary" href={`/sys/regions/_new`}/>
              }

            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>
          <DataTable
            tenant={tenant}
            cols={cols}
            resourceName="countries"
            dataKey="countryCode"
            onFetchDataPage={handleFetchDataPage}
            onRenderDataCol={handleRenderDataCol}
            onClickDataRow={handleRowClick}
          />

        </FormBody>
      </MainContainer>

    </>
  )
}