import React, {forwardRef, useState} from "react"

import styles from "./PrintComp.module.css"
import {useSolv} from "./SolvProvider";
import {useIntlEx} from "./IntlUtils";
import {addPropsToChildren, findChildren} from "./ReactUtils";

function PrintableDocField(props) {
  const {intl} = useIntlEx()
  if ("horizontal" === props.orientation?.toLowerCase()) {
    return (
      <tr>
        <td>
          <div className={`${styles["label"]} talign-right ${props.className ? props.className : ""}`}>
            {props.label}:
          </div>
        </td>
        <td>
          <div>
            {
              props.value ? (
                props.value
              ) : (
                props.children
              )
            }
          </div>
        </td>
      </tr>
    )
  }
  else {
    return (
      <tr>
        <td>
          <div className={`${styles["label"]} talign-left ${props.className ? props.className : ""}`}>
            {props.label}:
          </div>
          <div>
            {
              props.value ? (
                props.value
              ) : (
                props.children
              )
            }
          </div>
        </td>
      </tr>
    )
  }
}

function PrintableDocFields(props) {
  const {intl} = useIntlEx()
  const fieldComps = addPropsToChildren(findChildren(props.children, PrintableDocField), {orientation: props.orientation || "horizontal"})
  return (
    <td style={{verticalAlign: "top"}} align={(props.align ? props.align : "left")}>
      {
        props.title &&
          <div className={styles["label"]}>
            {props.title}
          </div>
      }
      <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate"}}>
        {fieldComps}
      </table>
    </td>
  )
}

function PrintableDocFieldContainer(props) {
  const {intl} = useIntlEx()
  // const fieldsComps = findChildren(children, PrintableDocFields);
  const children = addPropsToChildren(props.children, {orientation: props.orientation || "vertical"})
  return (
    <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "0", width: "100%",  marginTop: "30px"}}>
      {children}
    </table>
  )
}

function PrintableDocFieldRow(props) {
  const {intl} = useIntlEx()
  // const fieldsComps = findChildren(children, PrintableDocFields);
  return (
    <tr>
      {props.children}
    </tr>
  )
}

function PrintableDocHeader(props) {
  const {intl} = useIntlEx()
  // const fieldsComps = findChildren(children, PrintableDocFields);
  return (
    <div className={styles["header"]}>
      <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "0", width: "100%",  marginTop: "30px"}}>
        <tr>
          {props.children}
        </tr>
      </table>
    </div>
  )
}

function PrintableDocBodyTableRow(props) {
  return (
    <tr className={props?.type ? styles[props?.type?.toLowerCase()] : ""}>
      {props.children}
    </tr>
  )
}

function PrintableDocBodyTableCol(props) {
  return (
    <td className={props?.type? styles[props?.type?.toLowerCase()] : ""} style={{width: props.width || "100%", textAlign: props.align || "left"}} colSpan={props.colSpan ? props.colSpan : "1"} rowSpan={props.rowSpan ? props.rowSpan : "1"}>
      {props.children}
    </td>
  )
}

function PrintableDocBodyTable(props) {
  const {intl} = useIntlEx()
  return (
    <table className={styles["body"]} style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "0", marginTop: "30px"}}>
      {props.children}
    </table>
  )
}

function PrintableDocBody({ children }) {
  const {intl} = useIntlEx()
  return <div className={styles["body"]}>{children}</div>;
}

function PrintableDocFooter({ children }) {
  const {intl} = useIntlEx()
  return (
    <div className={styles["footer"]}>
      <table style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "0", width: "100%",  marginTop: "30px"}}>
        <tr>
          {children}
        </tr>
      </table>
    </div>
  )
}

function PrintableDocSection(props) {
  return (
    <div className={props.className ? props.className : ""}>
      {
        props.title &&
          <div className="title">
            {props.title}
          </div>
      }
      <div>
        {props.children}
      </div>
    </div>
  )
}

function mkClassName(input) {
  if (!input) return ""
  const words = input.split(/\s+/);
  const nonPrefixed = [];
  const prefixed = [];

  words.forEach(word => {
    if (word.startsWith("mod:")) {
      prefixed.push(styles[word.slice(4)]); // Remove "mod:"
    } else {
      nonPrefixed.push(word);
    }
  });

  return `${nonPrefixed.join(" ")} ${prefixed.join(" ")}`
}

function PrintableDocRow(props) {
  return (
    <tr className={mkClassName(props?.className)}>
      {props.children}
    </tr>
  )
}

function PrintableDocColumn(props) {
  return (
    <td className={mkClassName(props?.className)} style={{width: props.width || "auto"}} align={props.align || "left"} colSpan={props.colSpan ? props.colSpan : "1"} rowSpan={props.rowSpan ? props.rowSpan : "1"}>
      {
        props.value ? (
          props.value
        ) : (
          props.children
        )
      }
    </td>
  )
}

function PrintableDocTable(props) {
  const {intl} = useIntlEx()
  return (
    <table className={mkClassName(props?.className)} style={{tableLayout: "fixed", fontSize: "12pt", borderCollapse: "separate", borderSpacing: "0"}}>
      {props.children}
    </table>
  )
}

const PrintableDoc = forwardRef((props, ref) => {

  const {brandId} = useSolv()
  const {intl} = useIntlEx()

  const skinEl = document.getElementById("main-container") || document.documentElement;
  let logoBaseUri = window.getComputedStyle(skinEl).getPropertyValue("--logo-uri")?.trim()?.replaceAll("\"", "")?.replaceAll("{brandId}", brandId.toLowerCase())?.replaceAll("{mode}", "light").replaceAll("{color}", "color")

  const headerComps = findChildren(props.children, PrintableDocHeader);
  const bodyComps = findChildren(props.children, PrintableDocBody);
  const footerComps = findChildren(props.children, PrintableDocFooter);
  const sectionComps = findChildren(props.children, PrintableDocSection);

  return (
    <div className={styles["container"]} ref={ref}>
      <div style={{fontFamily: "Helvetica Neue, Ubuntu, sans-serif", color: "#000", backgroundColor: "#fff", padding: "0 40px 40px 40px"}}>

        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
          <div style={{color: "#aaa", fontSize: "30pt", fontWeight: "700", textTransform: "uppercase", letterSpacing: "0.10em", whiteSpace: "pre-line", lineHeight: "1"}}>
            {props.title}
          </div>
          <div>
            <img src={logoBaseUri} width="auto" height="44px"/>
          </div>
        </div>

        {
          headerComps.length > 0 &&
            <>{headerComps}</>
        }

        {
          bodyComps.length > 0 &&
            <>{bodyComps}</>
        }

        {
          footerComps.length > 0 &&
            <>{footerComps}</>
        }

        {
          sectionComps?.length > 0 &&
            <>{sectionComps}</>
        }

      </div>

    </div>
  )
})

PrintableDoc.Section = PrintableDocSection
PrintableDoc.Table = PrintableDocTable
PrintableDoc.Row = PrintableDocRow
PrintableDoc.Column = PrintableDocColumn
PrintableDoc.Footer = PrintableDocFooter

export default PrintableDoc;