import React, {useState, useReducer, useRef, useEffect}  from 'react'
import {useParams, useHistory, useLocation}  from 'react-router-dom'
import {useMutation} from 'react-query'
import useStateRef from "react-usestateref"
import {v4 as uuidv4} from 'uuid'
import * as dayjs from 'dayjs'
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils.js"
import {MainContainer} from "../components/FormComps"
import {PageDialogContainer, PageDialog, PageDialogMainPanel} from "../components/PageDialog"
import {processImageFile, ImageDropBox, uploadImag, uploadImage} from "../components/ImageUtils.js"
import ImageDialog from "../components/ImageDialog"
import {openDialog} from "../components/DialogUtils";
import {showError, clearError, InfoText, InfoBlock} from "../components/ValidationUtils.js"
import PurchaseDialog from "./PurchaseDialog";
import {PageDialogHeader, PageDialogFooter} from "./PageDialogUtils"
import {isBlankString} from "../components/StringUtils";
import {simpleReducer} from "../components/ReactUtils";
import getLogger from "../components/Logging.js"
import '../App.css'
import './PetSolv.css'

const log = getLogger("Onboarding")

export default function Onboarding(props) {

  const {brandId, api, auth: {session: {user}, refreshSignin}, env, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const location = useLocation()
  const history = useHistory()

  const params = useParams()
  const [redirect, setRedirect] = useState(null)

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  const [onboardingInfo, setOnboardingInfo] = useState(null)
  const [step, setStep, stepRef] = useStateRef("START")
  const [creativeHeader, setCreativeHeader] = useState("MISSING")
  const [creativeTitle, setCreativeTitle] = useState(null)
  const [creativeFooter, setCreativeFooter] = useState("Click to Learn More / Report")
  const [creativeImageUrl, setCreativeImageUrl] = useState(null)
  const [creativeStyle, setCreativeStyle] = useState({
    body: {
      font: "Verdana",
      textColor: "#ff0000",
      backgroundColor: "#ffffff"
    },
    header: {
      font: "Verdana",
      textColor: "#ffffff",
      backgroundColor: "#ff0000"
    },
    title: {
      font: "Verdana",
      textColor: "#ff0000",
      backgroundColor: "#ffffff"
    },
    content: {
      font: "Verdana",
      textColor: "#000000",
      backgroundColor: "#ffffff"
    },
    footer: {
      font: "Verdana",
      textColor: "#ffffff",
      backgroundColor: "#ff0000"
    },
    border: {
      color: "#ff0000"
    }
  })
  const [creativeImageInfo, setCreativeImageInfo] = useState(null)
  const creativeImagePickerDialogRef = useRef(null)
  const [petBreed, setPetBreed] = useState(null)
  const [petBreedField, updatePetBreedField] = useReducer(simpleReducer, {
    id: uuidv4().replace(/[\-]+/g,''),
    label: "Breed",
    type: "TEXT_LINE",
    input: "OPTIONAL",
    value: "",
    desc: "e.g. Poodle, Siamese, etc.",
    opts: {layout: "LCELL", labelStyle: "BOLD", valueStyle: "NORMAL"},
  })
  const [petColor, setPetColor] = useState(null)
  const [petColorField, updatePetColorField] = useReducer(simpleReducer, {
    id: uuidv4().replace(/[\-]+/g,''),
    label: "Color",
    type: "TEXT_LINE",
    input: "OPTIONAL",
    value: "",
    desc: "e.g. Black, Brown, etc.",
    opts: {layout: "LCELL", labelStyle: "BOLD", valueStyle: "NORMAL"},
  })
  const [petLastSeen, setPetLastSeen] = useState(null)
  const [petLastSeenField, updatePetLastSeenField] = useReducer(simpleReducer, {
    id: uuidv4().replace(/[\-]+/g,''),
    label: "Last Seen",
    type: "TEXT_BLOCK",
    input: "OPTIONAL",
    value: "",
    desc: "Time and place your pet was last seen/lost.",
    opts: {layout: "NEWLINE", labelStyle: "BOLD", valueStyle: "NORMAL"},
  })
  const [pageContent, setPageContent] = useState("")
  const [pageStyle, setPageStyle] = useState({
    body: {
      font: "Verdana",
      textColor: "#000000",
      backgroundColor: "#ffffff"
    },
    header: {
      font: "Verdana",
      textColor: "#ffffff",
      backgroundColor: "#ff0000"
    },
    title: {
      font: "Verdana",
      textColor: "#ff0000",
      backgroundColor: "#ffffff"
    },
    content: {
      font: "Verdana",
      textColor: "#000000",
      backgroundColor: "#ffffff"
    },
    footer: {
      font: "Verdana",
      textColor: "#ffffff",
      backgroundColor: "#ff0000"
    },
    border: {
      color: "#ff0000"
    }
  })
  const [feedbackForm, setFeedbackForm] = useState({
    "fields": [{
      "id": "c19f9af5e5b24a958447f85a30d5f788",
      "desc": "",
      "opts": {
        "layout": "INLINE",
        "labelStyle": "HIDDEN",
        "selectList": null,
        "valueStyle": "NORMAL"
      },
      "type": "TEXT_BLOCK",
      "input": "READONLY",
      "label": "Header",
      "value": "# Can you assist in reuniting this lost pet with their family?\nIf you have any information about this lost pet, please share it with us. Your message will be immediately forwarded to the pet owner. We kindly ask you to provide your contact information when submitting a message. This is necessary for the pet owner to be able to respond to you. Anonymous messages will not facilitate communication between you and the pet owner. Thank you for taking the time to help reunite this lost pet with their family."
    }, {
      "id": "8084d8d75a054099a282b85919825d94",
      "desc": "Please enter as much information as possible",
      "opts": {
        "layout": "NEWLINE",
        "labelStyle": "HIDDEN",
        "selectList": null,
        "valueStyle": "NORMAL"
      },
      "type": "TEXT_BLOCK",
      "input": "REQUIRED",
      "label": "Info",
      "value": ""
    }, {
      "id": "5e2aa3298d0646c895ca4ea7a99e1803",
      "desc": "",
      "opts": {
        "layout": "NEWLINE",
        "labelStyle": "HIDDEN",
        "selectList": null,
        "valueStyle": "NORMAL"
      },
      "type": "TEXT_BLOCK",
      "input": "READONLY",
      "label": "AboutYou",
      "value": "### About you\nSkip this section if you want to remain anonymous."
    }, {
      "id": "55f88f9da4f04ee281626cdf93f9dcfa",
      "desc": "Please enter your full name (Optional)",
      "opts": {
        "layout": "NEWLINE",
        "labelStyle": "BOLD",
        "selectList": null,
        "valueStyle": "NORMAL"
      },
      "type": "TEXT_LINE",
      "input": "OPTIONAL",
      "label": "Name",
      "value": ""
    }, {
      "id": "8c91d8566a5149b18c660cd7d4768583",
      "desc": "Please enter your contact phone number (Optional)",
      "opts": {
        "layout": "NEWLINE",
        "labelStyle": "BOLD",
        "selectList": null,
        "valueStyle": "NORMAL"
      },
      "type": "TEXT_LINE",
      "input": "OPTIONAL",
      "label": "Phone Number",
      "value": ""
    }, {
      "id": "a0a67e84da6d473f8e67b6459448e7e8",
      "desc": "Please enter your email address (Optional)",
      "opts": {
        "layout": "NEWLINE",
        "labelStyle": "BOLD",
        "selectList": null,
        "valueStyle": "NORMAL"
      },
      "type": "TEXT_LINE",
      "input": "OPTIONAL",
      "label": "Email Address",
      "value": ""
    }]
  })
  const [broadcastId, setBroadcastId] = useState(null)
  const [isCreatingBroadcast, setCreatingBroadcast] = useState(null)
  const [customOnboardingPage, setCustomOnboardingPage] = useState(null)
  const [selectedTenantPlan, setSelectedTenantPlan, selectedTenantPlanRef] = useStateRef(null)
  const [discount, setDiscount, discountRef] = useStateRef(null)
  const [selectedPrice, setSelectedPrice, selectedPriceRef] = useStateRef(null)

  useEffect(() => {
    log.debug("useEffect: invoked")
    loadOnboardingInfo()
  }, [])

  useEffect(() => {
    if (selectedTenantPlan && selectedTenantPlan) {
      log.debug("selectedTenantPlan=", selectedTenantPlan, discount)
      let price = intl.num(selectedTenantPlan.usdPrice / onboardingInfo.region.usdMultiplier) * (discount ? (1.0 - discount): 1.0)
      log.debug("selectedPrice=", price, discount)
      setSelectedPrice(price)
    }
  }, [selectedTenantPlan])

  async function loadOnboardingInfo() {
    setBusy(intl.msg("loading"))

    log.debug("loadOnboardingInfo: invoked")
    api.getOnboardingInfo()
      .then(({data}) => {
        log.debug("loadOnboardingInfo: data=", data)

        setOnboardingInfo(data)

        switch (data.step) {
          case "TENANT_PLAN":
            setStep("TENANT_PLAN")
            if (data.referral?.referralActions?.discount) {
              setDiscount(data.referral.referralActions.discount)
            }
            break
          case "BROADCAST":
            log.debug("loadOnboardingInfo: broadcast=", data.broadcast)
            if (data.broadcast) {
              setBroadcastId(data.broadcastId)
              setCreativeHeader(data.creativeHeader)
              setCreativeTitle(data.creativeTitle)
              if (data.creativeForm && data.creativeForm.fields) {
                let f = data.creativeForm.fields.find(f => f.label === "Breed")
                if (f) {
                  updatePetBreedField(f)
                }
                f = data.creativeForm.fields.find(f => f.label === "Color")
                if (f) {
                  updatePetColorField(f)
                }
                f = data.creativeForm.fields.find(f => f.label === "Last Seen")
                if (f) {
                  updatePetLastSeenField(f)
                }
              }
              setCreativeImageUrl(data.creativeImageUrl)
              setCreativeFooter(data.creativeFooter)
              setCreatingBroadcast(false)
              setPageContent(data.pageContent)
              setStep("BROADCAST")
            }
            else {
              api.createUuid()
                .then(({data}) => {
                  log.debug("loadOnboardingInfo: createUuid: data=", data)
                  setBroadcastId(data.uuid)
                  setCreatingBroadcast(true)
                  setStep("BROADCAST")
                })
                .catch((error) => {
                  log.debug("loadOnboardingInfo: error=", error)
                  setFatal({type: "OOPS", error: error})
                })
            }
            break
          default:
            completeOnboarding()
            break
        }
      })
      .catch((error) => {
        log.debug("loadOnboardingInfo: !!error=", error)
        handleError(error)
      })
      .finally(() => {
        setTouched(false)
        setBusy(null)
      })
  }

  function getTenantPlan(tenantPlanId) {
    log.debug("getTenantPlan: tenantPlanId=, plans=", tenantPlanId, onboardingInfo?.tenantPlans)
    return onboardingInfo?.tenantPlans?.find(tp => tp.tenantPlanId === tenantPlanId)
  }

  async function nextStep() {
    await loadOnboardingInfo()
  }

  function validate() {

    if (isBlankString(creativeTitle)) {
      setAlert({error: intl.msg("error_broadcast_creative_title_required")})
      return false
    }

    return true
  }
  
  const saveMutation = useMutation(
    payload => api.updateOnboarding(payload),
    {
      onSuccess: ({data}, error) => {
        log.debug("saveOnboarded: data=", data)
        setAlert(null)
      },
      onError: (error) => {
        if ("DUPLICATE_KEY" === error.code) {
          showError("inp_tenantName", "unique")
          setAlert({error: intl.msg("error_invalid_form")})
        }
        else {
          setAlert({error: intl.msg("error_failed")})
        }
      }
    }
  )

  async function handleBroadcastHeaderChange(e) {
    setCreativeHeader(e.target.value)
    setTouched(true)
  }

  async function handleBroadcastTitleChange(e) {
    log.debug("handleBroadcastTitleChange: e=", e)
    setCreativeTitle(e.target.value)
    setTouched(true)
  }

  async function handlePetBreedChange(e) {
    log.debug("handlePetBreedChange: e=", e)
    const v = e.target.value
    setPetBreed(v)
    updatePetBreedField({value: v})
    setTouched(true)
  }

  async function handlePetColorChange(e) {
    const v = e.target.value
    setPetColor(v)
    updatePetColorField({value: v})
    setTouched(true)
  }

  async function handlePetPhotoChange(e) {

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 100_000,
          width: 100,
          height: 100,
        })
      }
      catch (ex) {
        setAlert({error: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        setCreativeImageInfo(result)
        const el = document.getElementById("inp_dlg_petPhotoPicker")
        el.click()
      }
      else {
        setCreativeImageUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_logoUrl")
    }
  }

  function handlePetPhotoClear() {
    setCreativeImageUrl(null)
    setTouched(true)
  }

  async function handleBroadcastFooterChange(e) {
    setCreativeFooter(e.target.value)
    setTouched(true)
  }

  async function handleBroadcastContinueClick(e) {

    log.debug("handleBroadcastContinueClick: e", e, selectedTenantPlanRef.current, onboardingInfo.region)

    setBusy(intl.msg("saving"))

    try {

      if (validate(e)) {

        log.debug("handleBroadcastContinueClick: saving...", )

        let finalCreativeImageUrl = creativeImageUrl
    //
        if (creativeImageUrl && creativeImageUrl.startsWith("data:")) {
          finalCreativeImageUrl = await uploadImage({
            api: api,
            brandId: brandId,
            imageData: creativeImageUrl,
            imagePath: `t/${user.tenantId}/b/${broadcastId}/m/0`,
            setImageUrl: setCreativeImageUrl,
          })
        }

        const tenantPlan = onboardingInfo.tenantPlans?.find(i => i.tenantPlanId === onboardingInfo.tenant.tenantPlanId)
        log.debug("handleBroadcastContinueClick: tenantPlan=", tenantPlan)

        let payload = {
          broadcastId: broadcastId,
          tenantId: user.tenantId,
          broadcastName: user.tenantId,
          creativeType: "SOLV",
          creativeHeader: creativeHeader ? creativeHeader : "",
          creativeTitle: creativeTitle,
          creativeContent: "",
          creativeFooter: creativeFooter ? creativeFooter : "",
          creativeForm: {
            fields: [petBreedField, petColorField, petLastSeenField]
          },
          creativeStyle: creativeStyle,
          creativeImageUrl: finalCreativeImageUrl,
          pageType: "SOLV",
          pageHeader: creativeHeader ? creativeHeader : "",
          pageTitle: creativeTitle,
          pageContent: pageContent,
          pageUrl: null,
          pageForm: {
            fields: [petBreedField, petColorField, petLastSeenField]
          },
          pageStyle: pageStyle,
          pageImageUrl: finalCreativeImageUrl ? finalCreativeImageUrl : "",
          feedbackEnabled: true,
          feedbackForm: feedbackForm,
          feedbackThankyouText: "Thank you for your help!",
          targetBudget: tenantPlan.usdPrice * onboardingInfo.region.usdMultiplier,
          targetLocations: ["00000000-0000-0000-0000-000000000000"],
          targetAdViews: 20_000,
          adSystem: "AMOBEE",
          targetStartTime: {"type": "ASAP"},
          targetEndTime: {"type": "ASAP"},
          status: "DRAFT",
        }

        if (isCreatingBroadcast) {
          log.debug("handleBroadcastContinueClick: create: payload=", payload)
          const {data} = await api.createBroadcast(user.tenantId, payload)
          log.debug("handleBroadcastContinueClick: create result=", data)
        }
        else {
          log.debug("handleBroadcastContinueClick: update: payload=", payload)
          const {data} = await api.updateBroadcast(user.tenantId, broadcastId, payload)
          log.debug("handleBroadcastContinueClick: update result=", data)
        }

        setAlert(null)
        setTouched(false)
        // setStep("PLANS")

        await nextStep()

      }
      // else {
      //   setAlert({error: intl.msg("error_invalid_form")})
      // }
    }
    catch (ex) {
      setTouched(false)
      setAlert({error: intl.msg("error_failed")})
      log.error("Save broadcast failed: ex=", ex)
      // handleError(ex)
    }
    finally {
      setBusy(null)
    }

  }

  function handleCustomOnboardingClick() {
    window.open(customOnboardingPage, "_blank")
    window.location = "/"
  }

  function handleChoosePlanBClick() {
    log.debug("handleChoosePlanBClick")
    setSelectedTenantPlan(getTenantPlan("BUSINESS_B"))
    openDialog("dlg_purchase_plan_b")
  }

  async function handleChoosePlanAClick() {

    log.debug("handleChoosePlanAClick")
    setSelectedTenantPlan(getTenantPlan("BUSINESS_A"))
    openDialog("dlg_purchase_plan_b")
  }

  async function handlePurchaseCreditsDialogComplete() {
    log.debug("handlePurchaseCreditsDialogComplete")
    await nextStep()
  }

  async function handlePurchaseCreditsDialogCancel() {
    log.debug("handlePurchaseCreditsDialogCancel")
  }

  async function handlePurchasePlan(payload) {

    log.debug("handlePurchasePlan: invoked: payload1=", payload, selectedTenantPlanRef.current)

    try {
      const description = intl.msg(`petsolv_plan_${selectedTenantPlanRef.current.tenantPlanId}`)
      payload = {
        ...payload,
        tenantPlanId: selectedTenantPlanRef.current.tenantPlanId,
        reason: "PURCHASE_TENANT_PLAN",
        description: description,
        creditsExpiresOn: dayjs().add(selectedTenantPlanRef.current.expireDays, "day").valueOf(),
        creditsStartsOn: dayjs().add(selectedTenantPlanRef.current.startDays, "day").valueOf(),
      }
    } catch (error) {
      log.error("ERROR!!", error)
    }

    log.debug("handlePurchasePlan: payload2=", payload)

    try {
      await api.purchaseTenantPlan(payload)
    }
    catch (error) {
      log.error(error)
      setAlert({error: intl.msg("error_failed")})
    }

  }

  function completeOnboarding() {
    log.debug("completeOnboarding: invoked")
    return api.updateOnboarded()
      .then(({data}) => {
        log.debug("completeOnboarding: updateOnboarded: res=", data)
        window.location = "/"
      })
      .catch((error) => {
        log.debug("completeOnboarding: error2=", error)
        setAlert({error: intl.msg("error_failed")})
      })
  }

  const readFileAsDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.addEventListener("load", function() {
        resolve(fileReader.result)
      })
      fileReader.readAsDataURL(file)
    })
  }

  function isEditable() {
    return true
  }

  function handleError(error) {

  }
  
  return (
    <>
      <MainContainer>
      {
        onboardingInfo &&
          <>
            <PageDialogContainer>
              <PageDialog header={PageDialogHeader} footer={PageDialogFooter} align="center">
                {
                  user.isOwner() && ("TENANT_PLAN" === step) ? (

                    <PageDialogMainPanel title={intl.msg("onboarding_plans_title")} alert={alert}>

                      <p style={{maxWidth: "800px"}}>
                        {intl.msg("onboarding_plans_text")}
                      </p>
                      <div className="pricing-plans mt-4">

                        <div className="pricing-plan pricing-plan-recommended">
                          <div className="pricing-plan-ribbon">
                            Most Popular
                          </div>
                          <div className="mb-4 d-flex flex-wrap">
                            <h3>{intl.msg("petsolv_plan_BUSINESS_B")}</h3>
                            {
                              discount &&
                                <div className="pricing-plan-discount">{discount * 100}% Off</div>
                            }
                          </div>
                          <div className="d-flex flex-row flex-wrap">
                            <h1>{intl.num((getTenantPlan("BUSINESS_B").usdPrice * (discount ? ( 1 - intl.num(discount) ) : 1.0) / onboardingInfo.tenant.usdMultiplier), "currency", {currencyCode: onboardingInfo.tenant.currencyCode})}</h1>
                            {
                              discount &&
                                <div className="pricing-plan-original">{intl.num((getTenantPlan("BUSINESS_B").usdPrice / onboardingInfo.region.usdMultiplier), "currency", {currencyCode: onboardingInfo.tenant.currencyCode})}</div>
                            }
                          </div>
                          <button className="btn btn-primary rounded-pill pl-4 pr-4 mt-4" onClick={handleChoosePlanBClick}>{intl.msg("buy_now")}</button>
                          <p className="mt-4">
                            {intl.msg("petsolv_plan_BUSINESS_B_description", {fhp: chunks => <p style={{height: "100px"}}>{chunks}</p>})}
                          </p>
                          <div style={{margin: "24px 0px 2px 0px", borderTop: "1px solid #555"}}></div>
                          <div className="features">
                            <ul>
                              {/*<li>Create a broadcast of your missing pet that is guaranteed to be viewed on at least 20,000 <a className="def-link" href="">devices</a></li>*/}
                              <li>{intl.msg("petsolv_plan_BUSINESS_B_feature_1")}</li>
                              <li>{intl.msg("petsolv_plan_BUSINESS_B_feature_2")}</li>
                              <li>{intl.msg("petsolv_plan_BUSINESS_B_feature_3")}</li>
                            </ul>
                          </div>
                        </div>

                        <div className="pricing-plan">
                          <div className="mb-4 d-flex flex-wrap">
                            <h3>{intl.msg("petsolv_plan_BUSINESS_A")}</h3>
                            {
                              discount &&
                                <div className="pricing-plan-discount">{discount * 100}% Off</div>
                            }
                          </div>
                          <div className="d-flex flex-row flex-wrap">
                            <h1>{intl.num((getTenantPlan("BUSINESS_A").usdPrice * (discount ? ( 1 - intl.num(discount) ) : 1.0) / onboardingInfo.region.usdMultiplier), "currency", {currencyCode: onboardingInfo.tenant.currencyCode})}</h1>
                            {
                              discount &&
                                <div className="pricing-plan-original">{intl.num((getTenantPlan("BUSINESS_A").usdPrice / onboardingInfo.region.usdMultiplier), "currency", {currencyCode: onboardingInfo.tenant.currencyCode})}</div>
                            }
                          </div>
                          <button className="btn btn-primary rounded-pill pl-4 pr-4 mt-4" onClick={handleChoosePlanAClick}>{intl.msg("buy_now")}</button>
                          <p className="mt-4">
                            {intl.msg("petsolv_plan_BUSINESS_A_description", {fhp: chunks => <p style={{height: "100px"}}>{chunks}</p>})}
                          </p>
                          <div style={{margin: "24px 0px 2px 0px", borderTop: "1px solid #555"}}></div>
                          <div className="features">
                            <ul>
                              {/*<li>Create a broadcast of your missing pet that is guaranteed to be viewed on at least 20,000 <a className="def-link" href="">devices</a></li>*/}
                              <li>{intl.msg("petsolv_plan_BUSINESS_A_feature_1")}</li>
                              <li>{intl.msg("petsolv_plan_BUSINESS_A_feature_2")}</li>
                              <li>{intl.msg("petsolv_plan_BUSINESS_A_feature_3")}</li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </PageDialogMainPanel>

                  ) : "BROADCAST" === step ? (

                    <PageDialogMainPanel title={intl.msg("onboarding_broadcast_title")} alert={alert}>

                      <div style={{maxWidth: "600px"}}>

                        <p className="mt-3">
                          {intl.msg("onboarding_broadcast_text")}
                        </p>

                        <div className="form-card d-flex justify-content-center mt-4">
                          <div className="form-group row" style={{
                              backgroundColor: "rgba(var(--form-background-color-rgb))",
                              borderRadius: "5px",
                              border: "1px solid var(--form-border-color)",
                              padding: "30px 20px 0px 20px"}}>
                            <div className="col-sm-6">

                              <div>
                                <input id="inp_broadcastTitle" type="text" className="form-control input"  value={creativeTitle} placeholder={intl.msg("petsolv_onboarding_pet_name_placeholder")} onChange={handleBroadcastTitleChange} disabled={!isEditable()}/>
                                <InfoBlock>
                                  <InfoText>{intl.msg("petsolv_onboarding_pet_name_description")}</InfoText>
                                  <InfoText>{intl.msg("helptext_required")}</InfoText>
                                </InfoBlock>
                              </div>

                              <div className="photo mt-4 mb-4">
                                <ImageDropBox
                                    id="inp_petPhoto"
                                    image={creativeImageUrl}
                                    imageClassName={"account-logo"}
                                    textClass={"color-white"}
                                    iconClass={"fas fa-paw"}
                                    width={140}
                                    height={180}
                                    backdrop={false}
                                    absolute={true}
                                    onChange={handlePetPhotoChange}
                                    onClear={handlePetPhotoClear}
                                    disabled={!isEditable()}/>
                                <InfoBlock>
                                  <InfoText>{intl.msg("petsolv_onboarding_pet_photo_description")}</InfoText>
                                  <InfoText>{intl.msg("helptext_optional")}</InfoText>
                                </InfoBlock>
                              </div>
                            </div>
                            <div className="col-sm-6">

                              <div className="">
                                <input id="inp_petBreed" type="text" className="form-control input"  value={petBreed} placeholder={intl.msg("petsolv_onboarding_pet_breed_placeholder")} onChange={handlePetBreedChange}/>
                                <InfoBlock>
                                  <InfoText>{intl.msg("petsolv_onboarding_pet_breed_description")}</InfoText>
                                  <InfoText>{intl.msg("helptext_optional")}</InfoText>
                                </InfoBlock>
                              </div>

                              <div className="mt-4">
                                <input id="inp_petColor" type="text" className="form-control input" placeholder={intl.msg("petsolv_onboarding_pet_color_placeholder")}  value={petColor} onChange={handlePetColorChange} disabled={!isEditable()}/>
                                <InfoBlock>
                                  <InfoText>{intl.msg("petsolv_onboarding_pet_color_description")}</InfoText>
                                  <InfoText>{intl.msg("helptext_optional")}</InfoText>
                                </InfoBlock>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>

                      <footer className="mt-4">
                        <button className="btn btn-primary" onClick={handleBroadcastContinueClick} ><i className="fas fa-caret-right"></i>{intl.msg("continue")}</button>
                      </footer>

                    </PageDialogMainPanel>

                  ) : "CUSTOM_ONBOARDING" === step ? (

                    <PageDialogMainPanel>

                      <header style={{width: "90%", padding: "20px"}}>
                        <h1>
                          {intl.msg("onboarding_custom_onboarding_page_title")}
                        </h1>
                      </header>
                      <main>
                        <div className="container-fluid" style={{maxHeight: "60vh", overflow: "scroll"}}>
                          <div className="row no-gutters">
                            <div className="col">
                              <p>{intl.msg("onboarding_custom_onboarding_page_text_1")}</p>
                              <p className="mt-3 mb-3">
                                <a href={customOnboardingPage} onClick={handleCustomOnboardingClick}>{customOnboardingPage}</a>
                              </p>
                              <p>{intl.msg("onboarding_custom_onboarding_page_text_2")}</p>
                            </div>
                          </div>
                        </div>
                      </main>
                      <footer style={{width: "90%", padding: "15px 0 15px 0"}}>
                        <button className="btn btn-primary" onClick={handleCustomOnboardingClick}>{intl.msg("continue")}</button>
                      </footer>

                    </PageDialogMainPanel>

                  ) : (
                    <>
                    </>
                  )
                }
            </PageDialog>
          </PageDialogContainer>
        </>
      }
      </MainContainer>
      (* selectedTenantPlan?.price *)
      {
        onboardingInfo &&
          <>
            <ImageDialog
                ref={creativeImagePickerDialogRef}
                id="dlg_petPhotoPicker"
                title="Photo of your Pet"
                imageInfo={creativeImageInfo}
                setTargetImageData={setCreativeImageUrl}
                setTouched={setTouched}
            />
            <PurchaseDialog
                id={"dlg_purchase_plan_b"}
                item={selectedTenantPlan}
                region={onboardingInfo.region}
                title={<h1>{selectedTenantPlan?.tenantPlanId ? (`Buy ` + intl.msg(`petsolv_plan_${selectedTenantPlan.tenantPlanId}`)) : ""}</h1>}
                amount={selectedPrice}
                currencyCode={onboardingInfo.region.currencyCode}
                usdMultiplier={onboardingInfo.region.usdMultiplier}
                paypalBrandName={`PetSOLV: Buy ${selectedTenantPlan?.title}`}
                onPurchase={handlePurchasePlan}
                onComplete={handlePurchaseCreditsDialogComplete}
                onCancel={handlePurchaseCreditsDialogCancel}>
              <div>
                {intl.msg("petsolv_onboarding_purchase_plan_text", {plan: intl.msg(`petsolv_plan_${selectedTenantPlan?.tenantPlanId}`), price: `${onboardingInfo.region.currencyCode} ${selectedPrice}`})}
                {/*Please choose a way to pay*/}
              </div>
            </PurchaseDialog>

          </>

      }
    </>
  )
}