import React, {Fragment, useState, useEffect, useRef} from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { default as Select } from "react-select"
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { AsyncPaginate } from "react-select-async-paginate";
import {useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils.js"
import {validateField, validateEmailAddress, validateUrl, showError, clearAllErrors, InfoBlock, InfoText, clearError} from './components/ValidationUtils.js';
import getLogger from "./components/Logging.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {processImageFile, uploadImage} from "./components/ImageUtils";
import ImageDialog from "./components/ImageDialog";
import {ActionButton, FormGroup, FormGroups, useAlert} from "./components/FormComps";
import {mkExtraParams} from "./components/TableComps";
import './App.css'

const log = getLogger("ClientInviteDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function ClientInviteDialog(props)  {

  const {brandId, api, session:{user}, setBusy} = useSolv()

  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [reseller, setReseller] = useState(null)
  const [emailAddress, setEmailAddress] = useState(null)
  const [tenantId, setTenantId] = useState(null)
  const [tenantName, setTenantName] = useState(null)
  const [contactName, setContactName] = useState(null)
  const [tenantPlan, setTenantPlan] = useState(null)
  const [broadcastTemplates, setBroadcastTemplates] = useState(null)
  const [broadcastTemplateOpts, setBroadcastTemplateOpts] = useState(null)
  const [homePage, setHomePage] = useState(null)
  const [contactInfo, setContactInfo] = useState(null)
  const [logoUrl, setLogoUrl] = useState(null)
  const [allowedDomains, setAllowedDomains] = useState(null)
  const [disallowedDomains, setDisallowedDomains] = useState(null)
  const [customOnboardingPage, setCustomOnboardingPage] = useState(null)
  const [currency, setCurrency] = useState({value: "USD", label: "USD"})
  const [reinviting, setReinviting] = useState(null)
  // const [inviter, setInviter] = useState(null)

  const [touched, setTouched] = useState(null)

  const [tenantPlanOpts, setTenantPlanOpts] = useState(null)

  const [logoImageInfo, setLogoImageInfo] = useState(null)
  const logoImagePickerDialogRef = useRef(null)

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        const tenant = props.tenant
        switch (tenant.tenantTypeId) {
          case "CLIENT":
            if (["INVITING", "ONBOARDING"].includes(tenant.status)) {
              setTenantId(tenant.tenantId)
              setTenantName(tenant.tenantName)
              setContactName(tenant.displayName)
              setEmailAddress(tenant.owner?.emailAddress)
              setHomePage(tenant.homePage)
              setContactInfo(tenant.contactInfo)
              setLogoUrl(tenant.logoUrl)
              setCurrency({value: tenant.currencyCode, label: tenant.currencyCode})
              if (tenant.reseller) {
                setReseller({
                  value: tenant.reseller.resellerId,
                  label: tenant.reseller.tenantName,
                  tenantId: tenant.reseller.tenantId,
                  owner: tenant.reseller.owner
                })
              }
              setReinviting(true)
            }
            else {
              setAlert({error: "error_failed"})
            }
            break
          case "RESELLER":
            setReseller({
              value: tenant.resellerId,
              label: tenant.tenantName,
              tenantId: tenant.tenantId,
              owner: tenant.owner
            })
            break
          default:
            break
        }
      }
    }
  }, [props])


  useEffect(() => {
    if (reseller) {
      log.debug("useEffect: reseller=", reseller)
      let plans = []
      switch (brandId) {
        case "SOLV":
          switch (reseller.tenantPlan) {
            case "COMMERCIAL_PREPAID":
            case "COMMERCIAL_USAGE":
              plans = [
                {value: "COMMERCIAL_USAGE", label: "Commercial"},
                {value: "DEMO", label: "Demo"},
                {value: "TRIAL", label: "Trial"},
              ]
              break
            case "TEST":
              plans = [
                {value: "TEST", label: "Test"},
              ]
              break
            default:
              plans = [
                {value: "DEMO", label: "Demo"},
              ]
              break
          }
          break
        case "PETSOLV":
          switch (reseller.tenantPlan) {
            case "COMMERCIAL_PREPAID":
            case "COMMERCIAL_USAGE":
              plans = [
                {value: "COMMERCIAL_PREPAID", label: "Commercial"},
                {value: "COMMERCIAL_USAGE", label: "Commercial"},
                {value: "DEMO", label: "Demo"},
                {value: "TRIAL", label: "Trial"},
              ]
              break
            case "TEST":
              plans = [
                {value: "TEST", label: "Test"},
              ]
              break
            default:
              plans = [
                {value: "DEMO", label: "Demo"},
              ]
              break
          }
          break
      }

      log.debug("useEffect: reseller: plans=", plans)
      setTenantPlanOpts(plans)
    }
  }, [reseller])

  function loadResellers(search, loadedOptions, cursor) {
    return api.listResellers({tenantId: user.tenantId, extraParams: mkExtraParams({filterParams:{tenantName: search}, sortParams:{tenantName: "ASC"}}), cursor: cursor})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return ({...c, value: c.resellerId, label: c.tenantName, tenantId: c.tenantId, owner: c.owner})
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
  }

  function loadCurrencies(search, loadedOptions, cursor) {
    return api.listCurrencies({tenantId: user.tenantId, extraParams: mkExtraParams({filterParams:{paymentProviders: "PAYPAL\tSTRIPE"}, sortParams:{currencyCode: "ASC"}}), cursor: cursor})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return ({...c, value: c.currencyCode, label: c.currencyCode})
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
  }

  function handleResellerChange(v) {
    setBroadcastTemplates(null)
    setReseller(v)
    setTouched(true)
  }  

  function handleTenantPlanChange(v) {
    setTenantPlan(v)
    setTouched(true)
  }

  function handleBroadcastTemplateChange(value) {
    log.debug("handleBroadcastTemplateChange: value=", value)
    setBroadcastTemplates(value)
    setTouched(true)
  }  
        
  function handleCustomOnboardingPageChange(e) {
    setCustomOnboardingPage(e.target.value)
  }

  function handleEmailAddressChange(e) {
    let v = e.target.value
    if (v) {
      v = v.toLowerCase()
    }
    setEmailAddress(v)
    setTouched(true)
  }

  function handleTenantNameChange(e) {
    setTenantName(e.target.value)
    setTouched(true)
  }

  function handleCurrencyChange(v) {
    setCurrency(v)
    setTouched(true)
  }

  function handleContactNameChange(e) {
    setContactName(e.target.value)
    setTouched(true)
  }

  function handleHomePageChange(e) {
    const value = e.target.value
    setHomePage(value)
    setTouched(true)
  }

  function handleContactInfoChange(e) {
    setContactInfo(e.target.value)
    setTouched(true)
  }

  function handleHomePageBlur() {
    let hp = homePage
    if (hp && hp.trim().length > 0) {
      try {
        if (!hp.startsWith("https://")) {
          hp = `https://${hp}`
        }
      }
      catch (e) {
      }
    }
    setHomePage(hp)
    // addToAllowedDomains(hp)
  }

  // function removeFromAllowedDomains(hp) {
  //   log.debug("removeFromAllowedDomains: hp=", hp)
  //   if (allowedDomains && allowedDomains.length > 0) {
  //     try {
  //       const url = new URL(hp)
  //       let host = url.host
  //       if (host.startsWith("www.")) {
  //         host = host.substring(4)
  //       }
  //       log.debug("removeFromAllowedDomains: host=", host)
  //       let a = allowedDomains
  //       log.debug("removeFromAllowedDomains: a=", a)
  //       const index = allowedDomains.findIndex(d => d.value === host)
  //       log.debug("removeFromAllowedDomains: index=", index)
  //       if (index >= 0) {
  //         a.splice(index, 1)
  //         log.debug("removeFromAllowedDomains: 2, a=", a)
  //         setAllowedDomains(a)
  //       }
  //     }
  //     catch (e) {
  //
  //     }
  //   }
  // }

  // function removeFromDisallowedDomains(hp) {
  //   log.debug("removeFromDisallowedDomains: hp=", hp)
  //   if (disallowedDomains && disallowedDomains.length > 0) {
  //     try {
  //       const url = new URL(hp)
  //       let host = url.host
  //       if (host.startsWith("www.")) {
  //         host = host.substring(4)
  //       }
  //       log.debug("removeFromDisallowedDomains: host=", host)
  //       let a = disallowedDomains
  //       log.debug("removeFroDisallowedDomains: a=", a)
  //       const index = disallowedDomains.findIndex(d => d.value === host)
  //       log.debug("removeFromDisallowedDomains: index=", index)
  //       if (index >= 0) {
  //         a.splice(index, 1)
  //         log.debug("removeFromDisallowedDomains: 2, a=", a)
  //         setDisallowedDomains(a)
  //       }
  //     }
  //     catch (e) {
  //
  //     }
  //   }
  // }

  // function addToAllowedDomains(hp) {
  //   log.debug("addToAllowedDomains: hp=", hp)
  //   if (hp) {
  //     try {
  //       const url = new URL(hp)
  //       let host = url.host
  //       if (host.startsWith("www.")) {
  //         host = host.substring(4)
  //       }
  //       host = host.trim()
  //       if (!["discord.com", "fb.com", "facebook.com", "instagram.com", "linkedin.com", "mastodon.social", "naver.com", "pinterest.com", "qq.com", "quora.com", "reddit.com", "snapchat.com", "twitter.com", "tumblr.com", "weibo.com", "youtube.com"].includes(host)) {
  //         log.debug("addToAllowedDomains: host=", host)
  //         if (allowedDomains && allowedDomains.length > 0) {
  //           const index = allowedDomains.findIndex(d => d.value === host)
  //           log.debug("addToAllowedDomains: index=", index)
  //           if (index == -1) {
  //             setAllowedDomains([
  //               ...allowedDomains,
  //               {value: host, label: host}
  //             ])
  //           } else {
  //             setAllowedDomains([
  //               ...allowedDomains.slice(0, index),
  //               {value: host, label: host},
  //               ...allowedDomains.slice(index + 1),
  //             ])
  //           }
  //         } else {
  //           setAllowedDomains([{value: host, label: host}])
  //         }
  //       }
  //     }
  //     catch (e) {
  //     }
  //   }
  // }

  async function handleLogoChange(e) {

    log.debug("handleLogoChange: ", e.target.files)

    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: 100_000,
          width: 100,
          height: 100,
        })
      }
      catch (ex) {
        setAlert({error: "error_image_process_failed"})
      }
      finally {
        setBusy(null)
      }

      log.debug("handleLogoChange: result=", result)

      if (result.error) {
        // if (result.warning || result.error) {
        setLogoImageInfo(result)
        const el = document.getElementById("inp_dlg_logoImagePicker")
        el.click()
      }
      else {
        setLogoUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_logoUrl")
    }
  }

  function handleLogoClear() {
    setLogoUrl(null)
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_emailAddress", validateEmailAddress(emailAddress), "valid", opt)

    validateField("inp_tenantName", tenantName && (tenantName.trim().length > 0) && (tenantName !== emailAddress), "valid", opt)

    validateField("inp_currency", currency, "required", opt)

    if (homePage && homePage.trim().length > 0) {
      validateField("inp_homePage", validateUrl(homePage), "valid", opt)
    }

    if (customOnboardingPage && customOnboardingPage.trim().length > 0) {
      validateField("inp_customOnboardingPage", validateUrl(customOnboardingPage), "valid", opt)
    }

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleInviteClick() {

    try {

      if (validate()) {

        setBusy(intl.msg("working"))

        let tid
        if (!tenantId) {
          const {data} = await api.createUuid()
          tid = data.uuid
        }
        else {
          tid = tenantId
        }

        let finalLogoUrl = logoUrl

        if (finalLogoUrl && finalLogoUrl.startsWith("data:")) {
          finalLogoUrl = await uploadImage({
            api: api,
            brandId: brandId,
            imageData: finalLogoUrl,
            imagePath: `t/${tid}/logo`,
            setImageUrl: setLogoUrl,
          })
        }

        let createdBy
        if (user.isSystem()) {
          createdBy = reseller.owner.userId
        }
        else {
          if (user.tenantId.replace(/-/g, "") === reseller.tenantId.replace(/-/g, "")) {
            createdBy = user.userId
          }
          else {
            createdBy = reseller.owner.userId
          }
        }

        let payload = {
          emailAddress: emailAddress,
          tenantId: tid,
          tenantName: tenantName,
          tenantPlanId: 'BUSINESS_A',
          currencyCode: currency.value,
          homePage: homePage,
          contactInfo: contactInfo,
          logoUrl: finalLogoUrl,
          customOnboardingPage: customOnboardingPage,
          invokedById: createdBy
        }

        if (reinviting) {
          await api.reinviteClient(payload)
        }
        else {
          await api.inviteClient(payload)
        }

        window.location.reload()

      }
      else {
        setAlert({error: intl.msg("error_invalid_form")})
      }
    }
    catch (error) {
      log.error("Validation error: ", error)
      handleError(error)
    }
    finally {
      setBusy(null)
    }
  }


  function canEdit() {
    return true
  }

  function handleOpenDialog() {
    setAlert(null)
    setTouched(false)
  }

  function handleCloseDialog() {
    closeDialog("dlg_client_invite")
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError", error.code)
      const code = error.code
      const details = error.details
      switch (code) {
        case "BANNED_INVITER":
          setAlert({error: intl.msg("error_banned_user", details)})
          break
        case "UNKNOWN_INVITER":
        case "INVALID_INVITER":
          setAlert({error: intl.msg("invite_error_invalid_inviter", details), field: "inp_emailAddresses", constraint: "valid"})
          break
        case "DUPLICATE_INVITEE_USER":
          setAlert({error: intl.msg("invite_error_duplicate_user", details), field: "inp_emailAddresses", constraint: "unique"})
          break
        case "DUPLICATE_INVITEE_TENANT":
          setAlert({error: intl.msg("invite_error_duplicate_tenant", details), field: "inp_tenantName", constraint: "unique"})
          break
        case "BANNED_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_banned_email_address", details), field: "inp_emailAddresses", constraint: "valid"})
          break
        default:
          setAlert({error: intl.msg("error_failed", details)})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  return (

    props?.tenant &&
      <>

        <ModalDialog
          dialogId="dlg_client_invite"
          size="lg"
          keyboard="true"
          backdrop="static"
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}>

          <ModalDialog.Header
            title={reinviting ? intl.msg("client_reinvite_title") : intl.msg("client_invite_title")}
            description={reinviting ? intl.msg("client_reinvite_description") : intl.msg("client_invite_description")}
            alert={alert}
          />

          <ModalDialog.Body>

            {
              props.tenant.isSystem() &&
                <FormGroup>
                  <FormGroup.Label htmlFor="inp_reseller" text={intl.msg("reseller")} description={intl.msg("client_invite_reseller_description")}/>
                  <FormGroup.Controls>
                    <AsyncPaginate
                      id="inp_resellers"
                      className="react-select"
                      classNamePrefix="react-select"
                      cacheOptions
                      value={reseller}
                      debounceTimeout={800}
                      defaultOptions={true}
                      loadOptions={loadResellers}
                      onChange={handleResellerChange}
                      isDisabled={!canEdit()}/>
                    <InfoBlock>
                      <InfoText validate="required" disabled={!canEdit()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>
            }
            {
              reseller &&
                <>
                  <FormGroups>
                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_emailAddress" text={intl.msg("email_address")} description={intl.msg("account_invite_email_address_description")}/>
                      <FormGroup.Controls>
                        <input  id="inp_emailAddress" className="form-control" type="text" value={emailAddress} autoFocus onChange={handleEmailAddressChange}/>
                        <InfoBlock>
                          <InfoText validate="valid" disabled={!canEdit()}>{intl.msg("helptext_email_address_valid")}</InfoText>
                          <InfoText validate="unique" disabled={!canEdit()}>{intl.msg("helptext_email_address_unique")}</InfoText>
                          <InfoText validate="required" disabled={!canEdit()}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>

                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_tenantName" text={intl.msg("account_name")} description={intl.msg("account_invite_account_name_description")}/>
                      <FormGroup.Controls>
                        <input  id="inp_tenantName" className="form-control" type="text" value={tenantName} onChange={handleTenantNameChange}/>
                        <InfoBlock>
                          <InfoText validate="unique" disabled={!canEdit()}>{intl.msg("account_name_helptext_unique")}</InfoText>
                          <InfoText validate="required" disabled={!canEdit()}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>

                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_currency" text={intl.msg("currency")} description={intl.msg("client_invite_currency_description")}/>
                      <FormGroup.Controls>
                        <div style={{width: "200px"}}>
                        <AsyncPaginate
                            id="inp_currency"
                            className="react-select"
                            classNamePrefix="react-select"
                            cacheOptions
                            value={currency}
                            debounceTimeout={800}
                            defaultOptions={true}
                            loadOptions={loadCurrencies}
                            onChange={handleCurrencyChange}
                            isDisabled={!canEdit()}/>
                        </div>
                        <InfoBlock>
                          <InfoText validate="required" disabled={!canEdit()}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>

                  </FormGroups>

                  {/*<FormGroups title={intl.msg("account_invite_contact_info_section_title")} description={intl.msg("account_invite_contact_info_section_description")}>*/}

                  {/*  <FormGroup>*/}
                  {/*    <FormGroup.Label htmlFor="inp_contactName" text={intl.msg("account_contact_name")} description={intl.msg("account_contact_name_description")}/>*/}
                  {/*    <FormGroup.Controls>*/}
                  {/*      <input  id="inp_contactName" className="form-control" type="text" value={contactName} onChange={handleContactNameChange}/>*/}
                  {/*      <InfoBlock>*/}
                  {/*        <InfoText validate="optional" disabled={!canEdit()}>{intl.msg("helptext_optional")}</InfoText>*/}
                  {/*      </InfoBlock>*/}
                  {/*    </FormGroup.Controls>*/}
                  {/*  </FormGroup>*/}

                  {/*  <FormGroup>*/}
                  {/*    <FormGroup.Label htmlFor="inp_contactInfo" text={intl.msg("account_contact_info")} description={intl.msg("account_contact_info_description")}/>*/}
                  {/*    <FormGroup.Controls>*/}
                  {/*      <textarea id="inp_contactInfo" className="form-control" rows="2" autoComplete="no" value={contactInfo} disabled={!canEdit()} onChange={handleContactInfoChange} style={{whiteSpace: "pre-line"}}/>*/}
                  {/*      <InfoBlock>*/}
                  {/*        <InfoText>{intl.msg("helptext_optional")}</InfoText>*/}
                  {/*      </InfoBlock>*/}
                  {/*    </FormGroup.Controls>*/}
                  {/*  </FormGroup>*/}

                  {/*  <FormGroup>*/}
                  {/*    <FormGroup.Label htmlFor="inp_homePage" text={intl.msg("account_home_page")} description={intl.msg("account_home_page_description")}/>*/}
                  {/*    <FormGroup.Controls>*/}
                  {/*      <div className="input-group">*/}
                  {/*        <input id="inp_homePage" type="text" className="form-control" value={homePage} onChange={handleHomePageChange} onBlur={handleHomePageBlur}/>*/}
                  {/*        {*/}
                  {/*          validateUrl(homePage) &&*/}
                  {/*          <div className="input-group-append">*/}
                  {/*            <button className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="View page in new browser tab" onClick={(e) => window.open(homePage)}>*/}
                  {/*              <i className="fas fa-external-link-alt"></i>*/}
                  {/*            </button>*/}
                  {/*          </div>*/}
                  {/*        }*/}
                  {/*      </div>*/}
                  {/*      <InfoBlock>*/}
                  {/*        <InfoText validate="valid">{intl.msg("helptext_web_address_valid")}</InfoText>*/}
                  {/*        <InfoText>{intl.msg("helptext_optional")}</InfoText>*/}
                  {/*      </InfoBlock>*/}
                  {/*    </FormGroup.Controls>*/}
                  {/*  </FormGroup>*/}

                  {/*  <FormGroup>*/}
                  {/*    <FormGroup.Label htmlFor="inp_logoUrl" text={intl.msg("account_logo")} description={intl.msg("account_logo_description")}/>*/}
                  {/*    <FormGroup.Controls>*/}
                  {/*      <ImageDropBox*/}
                  {/*          id="inp_logoUrl"*/}
                  {/*          image={logoUrl}*/}
                  {/*          imageClassName={"account-logo"}*/}
                  {/*          width={100}*/}
                  {/*          height={100}*/}
                  {/*          onChange={handleLogoChange}*/}
                  {/*          onClear={handleLogoClear}*/}
                  {/*          disabled={!canEdit()}/>*/}
                  {/*      <InfoBlock>*/}
                  {/*        <InfoText validate="valid_size">{intl.msg("account_invite_logo_helptext_valid_size")}</InfoText>*/}
                  {/*        <InfoText>{intl.msg("helptext_optional")}</InfoText>*/}
                  {/*      </InfoBlock>*/}
                  {/*    </FormGroup.Controls>*/}
                  {/*  </FormGroup>*/}

                  {/*</FormGroups>*/}

                </>
              }

          </ModalDialog.Body>

          <ModalDialog.Footer>
            <ModalDialog.Footer.Controls>
              {
                (touched || reinviting) &&
                  <ActionButton name="apply" text={intl.msg("add_new")} flavor="primary" onClick={handleInviteClick}/>
              }
              <ActionButton name="cancel" onClick={handleCloseDialog}/>
            </ModalDialog.Footer.Controls>
          </ModalDialog.Footer>

        </ModalDialog>

        <ImageDialog
            ref={logoImagePickerDialogRef}
            id="dlg_logoImagePicker"
            title="Logo Image"
            imageInfo={logoImageInfo}
            setTargetImageData={setLogoUrl}
            setTouched={setTouched}/>
      </>
  )
}