import React, {useState, useEffect, useRef} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {SolvError, useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {closeDialog, ModalDialog, openDialogCurried} from "./components/DialogUtils";
import {clearAllErrors, InfoBlock, InfoText, showError, validateField} from "./components/ValidationUtils";
import NumericInput from "react-numeric-input";
import {PayPalButton} from "react-paypal-button-v2";
import useStateRef from "react-usestateref";
import {ActionButton, FormBody, FormControl, FormGroup, FormGroups, Icon} from "./components/FormComps";
import CreditsOfflinePaymentDialog from "./CreditsOfflinePaymentDialog";
import CreditsTestPaymentDialog from "./CreditsTestPaymentDialog";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("CreditsTopupDialog")

export default function CreditTransactionTopupDialog(props)  {

  const {api, session: {user}, refreshSignin, env, setFatal, setBusy} = useSolv()

  const {intl} = useIntlEx()

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  const [credits, setCredits] = useState(0.0)
  const [minimumCredits, setMinimumCredits] = useState(0.0)
  const [tenant, setTenant] = useState(null)
  const [usdMultiplier, setUsdMultiplier] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(null)
  const [topupReason, setTopupReason] = useState(null)
  const [topupReasonOpts, setTopupReasonOpts] = useState(null)
  const [bonusReason, setBonusReason] = useState(null)
  const [bonusReasonOpts, setBonusReasonOpts] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [paymentMethodOpts, setPaymentMethodOpts] = useState(null)
  const [description, setDescription] = useState(`${intl.msg("credit_transaction_description_bonus")} `)
  const [refInfo, setRefInfo] = useState(null)
  const [details, setDetails] = useState(null)

  const [paymentTransactionId, setPaymentTransactionId, paymentTransactionIdRef] = useStateRef(null)

  const paypalContainerRef = useRef(null)
  const bankTransferContainerRef = useRef(null)

  useEffect(() => {
    if (props.tenant) {
      log.debug("useEffect: tenant=", props.tenant)
      setTenant(props.tenant)
    }
  }, [props.tenant])

  useEffect(() => {
    if (tenant) {
      api.getCurrency(tenant.currencyCode)
        .then(({data}) => {
          setUsdMultiplier(data.usdMultiplier)
          setCurrencyCode(data.currencyCode)
          const minCredits = Math.ceil(10.00 / data.usdMultiplier)
          setMinimumCredits(minCredits)
          setCredits(0.00)
        })
    }
  }, [tenant])

  useEffect(() => {

    let topupOpts = [
      {
        value: "BONUS",
        label: intl.msg("credits_add_reason_bonus"),
      },
      {
        value: "PURCHASE",
        label: intl.msg("credits_add_reason_purchase"),
      },
    ]

    setTopupReasonOpts(topupOpts)
    setTopupReason(topupOpts[0])

    let bonusOpts = []

    if (user.isSystemUser()) {
      bonusOpts = [
        {
          value: "GOODWILL",
          label: intl.msg("credits_bonus_reason_goodwill"),
        },
        {
          value: "PROMOTION",
          label: intl.msg("credits_bonus_reason_promotion"),
        },
        {
          value: "REWARD",
          label: intl.msg("credits_bonus_reason_reward"),
        },
        {
          value: "RELATIONSHIP",
          label: intl.msg("credits_bonus_reason_relationship"),
        },
        // {
        //   value: "COMPENSATION",
        //   label: intl.msg("credits_add_reason_opts_compensation"),
        // },
      ]
    }
    else {
      bonusOpts = []
    }
    if (bonusOpts) {
      bonusOpts.sort((a, b) => a.label.localeCompare(b.label))
    }
    bonusOpts.push({
      value: "OTHER",
      label: intl.msg("credits_bonus_reason_other"),
    })
    setBonusReasonOpts(bonusOpts)
    setBonusReason(bonusOpts.find(e => e.value === "PROMOTION"))

    let paymentMethodOpts

    if (user.isSystemUser()) {
      paymentMethodOpts = [
        {
          value: "BANK",
          label: intl.msg("payment_method_bank"),
        },
        // {
        //   value: "CASH",
        //   label: intl.msg("payment_method_cash"),
        // },
        {
          value: "CRYPTO",
          label: intl.msg("payment_method_crypto"),
        },
        {
          value: "WISE",
          label: intl.msg("payment_method_wise"),
        },
        {
          value: "PAYPAL",
          label: intl.msg("payment_method_paypal"),
        },
      ]

      if (paymentMethodOpts) {
        paymentMethodOpts.sort((a, b) => a.label.localeCompare(b.label)).push(
            {
              value: "OTHER",
              label: intl.msg("payment_method_other"),
            }
        )
      }
      else {
        paymentMethodOpts = []
      }

      setPaymentMethodOpts(paymentMethodOpts)
      setPaymentMethod(paymentMethodOpts.find(e => e.value === "BANK"))
    }

  }, [])

  function handleCreditsChange(v) {
    setCredits(v)
    setTouched(true)
  }

  function handleTopupTypeChange(v) {
    setTopupReason(v)
    setTouched(true)
  }

  function handleBonusReasonChange(v) {
    setBonusReason(v)
    setTouched(true)
  }

  function handlePaymentMethodChange(v) {
    setPaymentMethod(v)
    setTouched(true)
  }

  function handleDescriptionChange(e) {
    setDescription(e.target.value)
    setTouched(true)
  }

  function handleRefInfoChange(e) {
    setRefInfo(e.target.value)
    setTouched(true)
  }

  function handleBankTransferClick() {
    paypalContainerRef.current.style.display = "none"
    bankTransferContainerRef.current.classList.toggle("selected")
  }

  function handleOpenDialog() {
  }

  function handleCloseDialog() {
    closeDialog("dlg_credits_add")
  }

  async function handlePaypalCreateOrder(orderData, actions) {

    log.debug("handlePaypalCreateOrder: orderData=", orderData)

    setBusy(intl.msg("working"))

    try {
      const { data } = await api.createPaymentTransaction({
        paymentTransactionType: "PURCHASE",
        paymentMethodId: "PAYPAL_CHECKOUT",
        amount: credits,
        usdMultiplier: usdMultiplier,
        details: orderData
      })
      if (data) {
        log.debug("handlePaypalCreateOrder: data=", data)
        setPaymentTransactionId(data.paymentTransactionId)
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: `${currencyCode ? currencyCode : "USD"}`,
                value: credits
              }
            }
          ],
          application_context: {
            brand_name: "SOLV: Top up Credits",
            shipping_preference: "NO_SHIPPING"
          }
        })
      }
      else {
        log.debug("handlePaypalCreateOrder: error=", data.error)
        setAlert({error: intl.msg("error_paypal_failed")})
        return false
      }
    }
    catch (error) {
      setAlert({error: intl.msg("error_paypal_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  async function handlePaypalOnApprove(orderData, actions) {

    log.debug("handlePaypalOnApprove: orderData=", orderData)

    setBusy(intl.msg("working"))
    try {

      const details = await actions.order.capture()

      const { data } = await api.purchaseCredits(tenant.tenantId, {
        paymentTransactionId: paymentTransactionIdRef.current,
        purchaseType: "CREDITS",
        description: intl.msg("credit_transaction_description_purchase", {paymentMethod: "Paypal Checkout"}),
        details: details,
      })

      return actions.order
        .capture()
          .then((details) => {
            log.debug("handlePaypalOnApprove: details=", details)
            window.location.reload()
          })
          .catch((err) => {
            log.debug("handlePaypalOnApprove: err=", err)
            setAlert({error: intl.msg("error_paypal_failed")})
          })
    }
    catch (err) {
      log.debug("handlePaypalOnApprove: error=", JSON.stringify(err))
      setAlert({error: intl.msg("error_paypal_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  async function handleTopupClick() {

    if (tenant && validate()) {
      setBusy(intl.msg("saving"))
      try {

        if ("PURCHASE" === topupReason.value) {

          try {

            let payload = {
              paymentTransactionType: "PURCHASE",
              paymentMethodId: paymentMethod.value,
              tenantId: tenant.tenantId,
              amount: credits,
              usdMultiplier: usdMultiplier,
              reason: topupReason.value,
              details: {
                "refInfo": refInfo
              }
            }

            log.debug("createPaymentTransaction: payload=", payload)

            const res = await api.createPaymentTransaction(payload)

            log.debug("createPaymentTransaction: data=", res)

            if (res && res.data) {

              try {

                let desc = ""
                switch (paymentMethod.value) {
                  case "OTHER":
                    desc = `Top up (${description})`
                    break
                  default:
                    desc = `Top up (Ref: ${refInfo})`
                    break
                }
                desc = intl.msg("credit_transaction_description_add")

                let payload = {
                  paymentTransactionId: res.data.paymentTransactionId,
                  purchaseType: "CREDITS",
                  description: intl.msg("credit_transaction_description_purchase"),
                  details: {
                    "refInfo": refInfo
                  }
                }

                log.debug("purchaseCredits: payload=", payload)

                await api.purchaseCredits(tenant.tenantId, payload)

                window.location.reload()

              }
              catch (error) {
                handleError(error)
              }
            }
            else {
              handleError(new SolvError())
            }

          }
          catch (error) {
            log.debug("Error: error=", error)
            handleError(error)
          }

        }
        else {

          const payload = {
            creditTransactionType: "BONUS",
            paymentMethod: "CREDITS",
            tenantId: tenant.tenantId,
            credits: credits,
            refType: null,
            refId: null,
            reason: bonusReason.value,
            description: description.trim(), //intl.msg("credit_transaction_description_add", {reason: description}),
          }

          log.debug("handleTopupClick: payload=", payload)

          try {

            const data = await api.adjustCredits(tenant.tenantId, payload)

            log.debug("handleTopupClick: res=", data)

            window.location.reload()

          }
          catch (error) {
            handleError(error)
          }

        }

      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleError(error) {
    log.debug("handleError: >>>error=", error)
    if (error && error.code) {
      switch (error.code) {
        case "TENANT_NOT_FOUND":
          setAlert({error: intl.msg("error_failed")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_credits", credits && credits >= minimumCredits, "min-value", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  function isEditing() {
    return true
  }

  return (

    tenant &&
      <>
        <ModalDialog
          dialogId="dlg_credits_add"
          size={props.size || "md"}x
          keyboard={"true"}
          backdrop={props.backdrop || "static"}
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}>

          <ModalDialog.Header
            title={intl.msg("credits_add_title")}
            description={intl.msg("credits_add_subtitle")}
            alert={alert}
          />

          <ModalDialog.Body>

            {
              tenant.accessingAs("*/MEMBER/*") ? (
                <>

                  <FormGroup>
                    <FormGroup.Label htmlFor="inp_credits" text={intl.msg("credits_add_credits")} description={intl.msg("credits_add_credits_description")}/>
                    <FormGroup.Controls>
                      <div style={{display: 'flex', alignItems: "center", width: "120px"}}>
                        <NumericInput id="inp_credits" className="form-control" style={false} min={minimumCredits} precision={2} disabled={!isEditing()} value={credits} onChange={handleCreditsChange}/>
                        <div className="ml-2">{currencyCode ? currencyCode : "USD"}</div>
                      </div>
                      <InfoBlock>
                        <InfoText validate="min-value" disabled={!isEditing()}>{intl.msg("credits_add_credits_helptext_min_value", {minCredits: intl.num(minimumCredits), currencyCode: currencyCode})}</InfoText>
                        <InfoText disabled={!isEditing()}>{intl.msg("credits_add_credits_helptext_note_1")}</InfoText>
                        <InfoText disabled={!isEditing()}>{intl.msg("credits_add_credits_helptext_note_2")}</InfoText>
                        <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                      </InfoBlock>
                    </FormGroup.Controls>
                  </FormGroup>

                  {
                    credits > 0.00 &&

                      <FormGroup>
                        <FormGroup.Label htmlFor="inp_paymentMethod" text={intl.msg("credits_payment_method")} description={intl.msg("credits_add_payment_method_description")}/>
                        <FormGroup.Controls>

                          <div ref={paypalContainerRef} className="paypal-buttons-container" style={{maxWidth: "400px"}}>
                            {
                              tenant && currencyCode &&
                                <PayPalButton
                                  options={{
                                    clientId: (("SYSTEM" === tenant.tenantTypeId) || ["DEMO", "TEST"].includes(tenant.tenantPlanId) ? env.PAYPAL_TEST_CLIENT_ID : env.PAYPAL_LIVE_CLIENT_ID),
                                    currency: `${currencyCode ? currencyCode : "USD"}`
                                  }}
                                  createOrder={handlePaypalCreateOrder}
                                  onApprove={handlePaypalOnApprove}
                                />
                            }
                          </div>

                          <div ref={bankTransferContainerRef} className="bank-transfer-container">
                            <hr/>
                            <button type="button" className="btn btn-primary-blue btn-bank-transfer-toggle" onClick={openDialogCurried("dlg_credits_offline_payment")}>
                              <Icon name="offline_payment" width="2em" height="2em"/>
                              {intl.msg("credits_offline_payment")}
                            </button>
                          </div>

                          {
                            (("DEV" === env.STAGE) || ("SYSTEM" === tenant.tenantTypeId)) &&
                              <div className="credits-payment-container">
                                <button type="button" className="btn btn-primary" onClick={openDialogCurried("dlg_credits_test_payment")}>
                                  <Icon name="test" width="1.5em" height="1.5em"/>
                                  {intl.msg("test")}
                                </button>
                              </div>
                          }

                        </FormGroup.Controls>
                      </FormGroup>

                  }

                </>

              ) : (
                <>
                  <FormGroup>
                    <FormGroup.Label htmlFor="inp_credits" text={intl.msg("credits_add_credits")} description={intl.msg("credits_add_credits_description")}/>
                    <FormGroup.Controls>
                      <div style={{display: 'flex', alignItems: "center", width: "120px"}}>
                        <NumericInput id="inp_credits" className="form-control" style={false} strict={true} min={minimumCredits} precision={2} disabled={!isEditing()} value={credits} onChange={handleCreditsChange}/>
                        <div className="ml-2">{currencyCode ? currencyCode : "USD"}</div>
                      </div>
                      <InfoBlock>
                        <InfoText validate="min-value" disabled={!isEditing()}>{intl.msg("credits_add_credits_helptext_min_value", {minCredits: intl.num(minimumCredits), currencyCode: currencyCode})}</InfoText>
                        <InfoText validate="required" disbled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                      </InfoBlock>
                    </FormGroup.Controls>
                  </FormGroup>

                  <FormGroup>
                    <FormGroup.Label htmlFor="inp_description" text={intl.msg("credits_add_description")} description={intl.msg("credits_add_description_description")}/>
                    <FormGroup.Controls>
                      <input id="inp_description" className="form-control" type="text" value={description} disabled={!isEditing()} onChange={handleDescriptionChange}/>
                      <InfoBlock>
                        <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                      </InfoBlock>
                    </FormGroup.Controls>
                  </FormGroup>
                </>
              )
            }

          </ModalDialog.Body>

          {
            !tenant.accessingAs("*/MEMBER/*") &&
              <ModalDialog.Footer>
                <ModalDialog.Footer.Controls>
                  <ActionButton name="cancel" onClick={handleCloseDialog}/>
                  <ActionButton name="add_new" flavor="primary"  onClick={handleTopupClick}/>
                </ModalDialog.Footer.Controls>
              </ModalDialog.Footer>
          }

        </ModalDialog>

        <CreditsOfflinePaymentDialog
          tenant={tenant}
          credits={credits}
          currencyCode={currencyCode}
        />

        <CreditsTestPaymentDialog
          tenant={tenant}
          credits={credits}
          currencyCode={currencyCode}
          usdMultiplier={usdMultiplier}
        />

      </>
    )
  }