import React from "react"
import getLogger from "../components/Logging"
const log = getLogger("ReactUtils")

export function simpleReducer(state, action) {
    state = {...state, ...action}
    return state
}

export function pathReducer(state, action) {
    const { path, value } = action;

    log.debug(">>>Z: pathReducer: state=, action=", state, action)

    if (path === ".") {
        // Replace the entire state
        return { ...value };
    }

    // Handle nested updates
    const keys = path.split('.');
    const updatedState = { ...state };
    let current = updatedState;

    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        current[key] = current[key] || {};
        current = current[key];
    }

    current[keys[keys.length - 1]] = value;

    return updatedState;
}

export function booleanProp(prop, name) {
    const v = (name ? prop?.[name] : prop)
    // log.debug(">>>Z: booleanProp: v=", v)
    switch (typeof (v)) {
        case "boolean":
            return (v === true)
        case "string":
            return ["TRUE", "YES", name?.toUpperCase()].includes(v.toUpperCase())
        default:
            return false
    }
}


export function isNullOrUndefined(v) {
    switch (typeof(v)) {
        case "null":
        case "undefined":
            return true
        default:
            return false
    }
}

// Flatten children, handling React.Fragment
export function flattenChildren(children) {
    return React.Children.toArray(children).flatMap((child) =>
      React.isValidElement(child) && child.type === React.Fragment
        ? flattenChildren(child.props.children)  // Recursively flatten fragments
        : child
    );
}

// Find children by type (ignores fragments)
export function findChildren(children, type) {
    const flatChildren = flattenChildren(children);
    return flatChildren.filter((child) => React.isValidElement(child) && child.type === type);
}

// Add props to children
export function addPropsToChildren(children, extraProps) {
    return React.Children.map(children, (child) =>
      React.isValidElement(child) ? React.cloneElement(child, extraProps) : child
    );
}
