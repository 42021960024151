import React, { useState } from 'react'
import axios from 'axios'
import imageCompression from 'browser-image-compression'
import { GifReader } from "omggif"
import { v4 as uuidv4 } from 'uuid'
import {useIntlEx} from "./IntlUtils";
import getLogger from "./Logging.js"
import {booleanProp} from "./ReactUtils";
import styles from "./ImageUtils.module.css"
import {parseEnv} from "./SolvProvider";

const log = getLogger("ImageUtils")

export function tryCompressImage(imageFile, maxSize) {
  const options = { 
    maxSizeMB: maxSize,   
    useWebWorker: true,   
    maxIteration: 50,     
  }
  return imageCompression(imageFile, options)
}

export const readFileAsDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.addEventListener("load", function() {
      resolve(fileReader.result)
    })
    fileReader.readAsDataURL(file)
  })
}

export const getImageFromUrl = (url) => {
  return new Promise(function(resolve, reject) {
    const img = new Image()
    img.src = url
    img.onload = function() {
      resolve(img)
    }
  })
}

export const getGifFileInfo = async (file) => {
  
  if ("image/gif" !== file.type)  {
    return null
  }

  let result = {
    frameCount: 0,
    loopCount: 0,
    duration: 0, 
    fps: 0
  }

  let buf = null
  
  try {
    
    const buf = await file.arrayBuffer()
    
    const gif = new GifReader(new Uint8Array(buf))

    if (gif) {
  
      result.frameCount = gif.numFrames()
      result.loopCount = gif.loopCount()

      log.debug(`processGifFile: result=${result}`)

      if (result.frameCount > 0) {
        for (let f = 0; f < result.frameCount; f++) {
          const frame = gif.frameInfo(f)
          result.duration += frame.delay
        }
      }
      if (result.duration > 0) {
        result.duration = result.duration / 100
      }
      result.fps = result.frameCount / result.duration

      log.debug(`processGifFile: result=${result}`)

    }
  }
  finally {
    buf = null
  }

  return result

}

export const processImageFile = async ({file, maxFileSize, width, height, gifOpts}) => {

  const result = {
    width: width,
    height: height,
    fileSize: file.size,
    maxFileSize: maxFileSize,
    isAnimated: false,
    loopCount: -1, 
    frameCount: -1, 
    fps: -1,
    duration: -1,
    error: null,
    warning: null,
    info: []
  }

  log.debug("proccessImageFile: filel=", file)

  try {

    const gifInfo = await getGifFileInfo(file)

    if (gifInfo) {

      log.debug("proccessImageFile: gifInfo=", gifInfo, gifOpts)

      result.isAnimated = (gifInfo.frameCount > 0)
      result.frameCount = gifInfo.frameCount
      result.loopCount = gifInfo.loopCount
      result.duration = gifInfo.duration
      result.fps = gifInfo.fps

      if (gifInfo.frameCount > 0) {
        if (gifInfo.loopCount === 0) {
          gifInfo.loopCount = Number.MAX_SAFE_INTEGER
        }
        if (gifInfo.loopCount > (gifOpts?.maxLoopCount || Number.MAX_SAFE_INTEGER)) {
          result.error = "ANIMATED_GIF_ENDLESS_LOOP" //"Invalid Animated GIF Image - Animation loop count exceed 3 times."
        }
        else if (gifInfo.fps > (gifOpts?.maxFps || 24)) {
          result.error = "ANIMATED_GIF_FPS" //"Invalid Animated GIF Image - Animation count exceed 24 frames per second (fps)."
        }
        else if (gifInfo.duration > (gifOpts?.maxDuration || 15)) {
          result.error = "ANIMATED_GIF_DURATION" //"Invalid Animated GIF Image - Animation count run for more than 15 minutes."
        }
      }

    }

    if (file.size > maxFileSize) {

      log.debug("proccessImageFile: file.size=", file.size, maxFileSize)

      const maxFileSizeKB = maxFileSize / 1000

      if (gifInfo && gifInfo.frameCount > 0) {
        result.compressedFile = file.size
        result.error = "ANIMATED_GIF_SIZE" //`Invalid Animated GIF Image - File size cannot exceed ${maxFileSizeKB}KB`
      }
      else {
        file = await tryCompressImage(file, maxFileSizeKB / 1000)
        result.compressedFileSize = file.size
        if (file.size > maxFileSize) {
          result.error = "COMPRESSION_FAILED" //`Invalid Image - Auto-compress failed`
        }
        else {
          result.warning = "COMPRESSED"  //"Image automatically compressed and scaled. However, it may also have resulted in reduced quality. Please make sure you're satisfied with the quality."
        }
      }

    }

    result.dataUrl = await readFileAsDataUrl(file)

    const img = await getImageFromUrl(result.dataUrl)

    result.scaledWidth = img.naturalWidth
    result.scaledHeight = img.naturalHeight

    if (img.naturalWidth != width || img.naturalHeight != height) {
      if (img.naturalWidth != width) {
        result.scaledWidth = width
      }
      if (img.naturalHeight != height) {
        result.scaledHeight = height
      }
      result.warning = result.warning ? `${result.warning}_SCALED` : "SCALED"
    }
    
  }
  finally {
  }

  return result

}

export function extractUrlFileName(u) {
  if (u.startsWith("http")) {
    const pathname = new URL(u).pathname
    return pathname.split("/").pop().split(".")[0]
  }
  else {
    return u
  }
}

export function dataUrlToBlob(dataUrl) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataUrl.split(',')[1]);

  // separate out the mime component
  var mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], {type: mimeString});
}

export function makeUniq(length) {
  length = length || 32
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function cacheBustUrl(url) {
  return url
  if (url && url.startsWith("http")) {
    return `${url}?${makeUniq(10)}`
  }
  else {
    return url
  }
}

export async function uploadImage(config) {

  const brandId = config.brandId || "SOLV"
  const uassetsEndpoint = parseEnv(brandId, process.env.REACT_APP_UASSETS_ENDPOINT)

  let blob = dataUrlToBlob(config.imageData)

  let payload = {
    objectPrefix: `${config.imagePath}`,
    contentType: blob.type
  }

  log.debug(`uploadImage: payload=`, payload)
  let data
  if (config.api) {
    data = await config.api.createPresignedUrl(payload)
    if (data && data.data) {
      data = data.data
    } else {
      throw Error("Uploading failed")
    }
  }
  else {
    let url = `/v1/aws/presignedUrls/`
    let res = await axios.post(url, payload)
    if (res && res.data && res.data.data) {
      data = res.data.data
    } else {
      throw Error("Uploading failed")
    }
  }

  log.debug(`uploadImage: data=`, data)
  let res = await axios.put(data.url, blob, {
    headers: {
      "Content-Type": blob.type,
      "Cache-Control": data.cacheControl,
      withCredentials: true
    }
  })
  log.debug(`uploadImage: res=`, res)
  let u = `${uassetsEndpoint}/${data.objectKey}`
  if (res.headers && res.headers["x-amz-version-id"]) {
    u = u + `?version-id=${res.headers["x-amz-version-id"]}`
  }
  else {
    u = u + `?uniq=${uuidv4().replace(/[\-]+/g,'')}`
  }
  log.debug("uploadImageToS3: u", u)
  if (config.setImageUrl) {
    config.setImageUrl(u)
  }
  return u

}

export function PhotoBox(props) {
  return (
    <div style={{display: "flex", flexDirection: "column", color: "black", height: "100%", alignItems: "center", justifyContent: "center"}}>
      <div style={{}}>
        <i className={props.iconClass || "fas fa-image"} style={{fontSize: "80px"}}></i>
      </div>
      <div className={props.textClass || ""} style={{fontWeight: "bold", marginTop: "-4px"}}>
        {props.text}
      </div>
    </div>
  )
}

export function ImageDropBox(props) {

  const {intl} = useIntlEx()

  function handleInputClick(e) {
    if (e.target.value) {
      e.target.value = ""
    }
  }

  function _Image(props) {
    if (booleanProp(props.disabled) && props.clickUrl) {
      return (
        <a href={props.clickUrl} target="_blank" title={intl.msg("broadcast_click_to_test_click_url")}>
          <__Image {...props}/>
        </a>
      )
    }
    else {
      return (<__Image {...props}/>)
    }
  }

  function __Image(props) {
    return (
      <>
        {
          !booleanProp(props.disabled) &&
            <button className={styles["image-clear-button"]} type="button" disabled={props.disabled} onClick={props.onClear}>
              <div style={{marginBottom: "2px"}}>&times;</div>
            </button>
        }
        {
          props.image && props.image.trim().length > 0 ? (
            <img src={props.image} style={{objectFit: "contain", width: props.width, height: props.height}}/>
          ) : (
            <PhotoBox text={`${props.width}x${props.height}`} textClass="text-muted" iconClass={props.iconClass}/>
          )
        }
      </>
    )
  }

  return (
      <div className={styles["image-drop-box"]} style={{position: "relative", width: `${props.width + 2}px`, height: `${props.height + 2}px`, textAlign: "center"}}>
        <input id={props.id} className={styles.upload} type="file" accept="image/*" disabled={props.disabled} onChange={props.onChange} onClick={handleInputClick}/>
        <label className={`${styles["image-backdrop"]} ${booleanProp(props.disabled) ? styles["readonly"] : ""}`} style={{width: `${props.width + 2}px`, height: `${props.height + 2}px`}} title={!booleanProp(props.disabled) ? intl.msg("image-dropbox-tooltip") : null} htmlFor={props.id}>
          <_Image {...props}/>
        </label>
      </div>
  )
}