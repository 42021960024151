import React, { Fragment, Component, useState, useRef, useEffect }  from 'react'
import './App.css'
import {useSolv} from "./components/SolvProvider";
import {Icon} from "./components/FormComps";

export default function Page503() {

  const {brandId} = useSolv()

  return (
    <Fragment>
        <main>

          <div class="jumbotron m-3">
            <center>
              <h1 style={{color: "orange", fontSize: "4.5rem"}}><Icon name="under_maintenance"/></h1>
              <h1 style={{fontSize: "3rem", color: "#fff"}}>Service Unavailable</h1>
              <p className="lead" style={{color: "#ccc"}}>
                {brandId} is currently down for maintenance. Sorry for the inconvenience. We will be back very shortly.
                <div className="d-flex flex-row justify-content-center gap-2 mt-4">
                  <a className="btn btn-secondary" style={{fontSize: "1.171875rem;"}} href="https://console.solv.technology">Retry</a>
                  <a href="https://help.solv.technology" className="btn btn-secondary" style={{fontSize: "1.171875rem;"}} >Help</a>
                </div>
              </p>
            </center>
          </div>            

        </main>
      </Fragment>
    )
}
