import React, {Fragment, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useQueryClient} from 'react-query'
import {useSolv, useTenant} from "../components/SolvProvider"
import MainMenu from "../MainMenu"
import {FormHeader, FormBody, MainContainer, ActionButton} from "../components/FormComps.js"
import { useIntlEx } from "../components/IntlUtils"
import {HumanTimestamp} from "../components/DateUtils";
import {DataTable, useCols} from "../components/TableComps";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Currencies")

export default function Currencies() {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [search, setSearch] = useState(null)
  const [sortSpec, setSortSpec] = useState({field: "currencyCode", direction: "ASC"})

  const [alert, setAlert] = useState(null)

  const [reloadId, setReloadId] = useState(null)

  const queryClient = useQueryClient()

  const cnumFormat = new Intl.NumberFormat(intl.locale,{ minimumFractionDigits: 4, maximumFractionDigits: 4 })

  useEffect(() => {
    if (tenant) {
      log.debug("Region: tenant=", tenant.accessingAs("ADMIN/*/SYSTEM"))
    }
  }, [tenant])

  const {cols} = useCols({
    resourceName: "currencies",
    specs: [
      {
        field: "currencyCode",
        label: intl.msg("currency_code"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "currencyName",
        label: intl.msg("currency_name"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "usdMultiplier",
        label: intl.msg("usd_multiplier"),
        filter: null,
        sort: "",
        align: "right",
      },

      {
        field: "paymentProviders",
        label: intl.msg("currency_payment_providers"),
        filter: {
          type: "select",
          options: () => {
            return ([
              {
                value: "PAYPAL",
                label: intl.msg("payment_provider_paypal")
              },
            ])
          }
        },
        sort: "",
        align: "left",
      },
      {
        field: "modifiedOn",
        label: intl.msg("last_synced"),
        filter: "select:reportDateRange",
        sort: "",
        align: "left",
      },
    ],
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listCurrencies({extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "currencyCode":
        n = <span>{row.currencyCode}</span>
        break
      case "currencyName":
        n = <span>{row.currencyName}</span>
        break
      case "usdMultiplier":
        n = <span>{intl.num(row.usdMultiplier)}</span>
        break
      case "paymentProviders":
        n = <span>{row.paymentProviders.map(p => intl.msg(`payment_provider_${p.toLowerCase()}`)).join(", ")}</span>
        break
      case "modifiedOn":
        n = <HumanTimestamp timestamp={row.modifiedOn} else={intl.msg("never")}/>
        break
    }
    return (
      n
    )
  }

  function handleRowClick(row) {
    window.location = `/sys/currencies/${row.currencyCode}`
  }

  function handleFetchClick() {
    setBusy(intl.msg("working"))
    return api.fetchCurrencies()
      .then(({data}) => {
        window.location.reload()
      })
      .catch((err) => {
        setAlert({error: "error_failed"})
      })
      .finally(() => {
        setBusy(null)
      })
  }

  return (

    tenant &&
    <>

      <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

        <FormHeader>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("currencies")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>

              <ActionButton name="refresh" onClick={handleFetchClick}/>

            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <DataTable
            tenant={tenant}
            cols={cols}
            resourceName="currencies"
            dataKey="currencyCode"
            onFetchDataPage={handleFetchDataPage}
            onRenderDataCol={handleRenderDataCol}
            onClickDataRow={handleRowClick}
          />

        </FormBody>
      </MainContainer>

    </>
  )
}