import React, {useEffect, useState} from "react"
import {CLIENT_USER_TYPE, SYSTEM_USER_TYPE, useSolv, useTenant} from "../components/SolvProvider";
import getLogger from "../components/Logging.js"
import {Markdown} from "../components/MarkdownUtils";
import {TagBox, TagLabel} from "../components/TagBox";
import {VersionFeaturedImage} from "../sysadmin/Version";
import versionStyles from "../sysadmin/Version.module.css"
import whatsNewStyles from "./WhatsNew.module.css"
import {useIntlEx} from "../components/IntlUtils";
import MainMenu from "../MainMenu";
import {MainContainer} from "../components/FormComps";
import "./Solv.css"
import "../App.css"
import {useParams} from "react-router-dom";

const log = getLogger("WhatsNew")

export function WhatsNew(props) {

  const {api, env, session, publicHttp} = useSolv()
  const {intl} = useIntlEx()

  const params = useParams()
  const {tenant} = useTenant(params.tenantId)

  const [version, setVersion] = useState(null)

  const markdown = new Markdown({
    homePage: null,
    clickUrls: null,
    allowedDomains: null,
    disallowedDomains: null,
    allowLinks: true,
  })

  useEffect(() => {

    if (!params.versionCode || params.versionCode.toLowerCase() === "latest") {
      api.getLatestVersion()
        .then(({data: version}) => {
          setVersion(version)
        })
    }
    else {
      api.getVersion(params.versionCode)
        .then(({data: version}) => {
          setVersion(version)
        })
    }


  }, [])

  const tenantTypes = ["CLIENT", "RESELLER", "SYSTEM"]

  function canShow(level) {
    return tenantTypes.indexOf(session.user?.userTypeId) >= tenantTypes.indexOf(level)
  }

  function renderTitle(rn) {
    return (
      <>
        <a href={`#${rn.slug}`} style={{visibility: "hidden"}}></a>
        <h1 id={rn.slug} style={{scrollMarginTop: "3em"}}>
          {rn.title}
        </h1>
      </>
    )
  }

  return (
    tenant &&
    <MainContainer tenant={tenant} menu={MainMenu}>
      <div className={`container-fluid ${whatsNewStyles["whatsnew-container"]} p-0`}>

        <div className={`row ${whatsNewStyles["whatsnew-hero"]} p-4`} style={{background: "var(--main-background)", borderBottom: "1px solid #333"}}>
          <div className="col-12">
            <div className="row">
              <div className="col d-flex justify-content-start">
                <div>
                  <h2>
                    What's New
                  </h2>
                  <h1>
                    Version {version?.versionCode}
                  </h1>
                  {
                    "RELEASED" === version?.status ? (
                      <small>
                        Released {intl.date(version?.releasedOn, "LONG")}
                      </small>
                    ) : (
                      <small className="text-warning">
                        Pre-release Version
                      </small>
                    )
                  }
                </div>
              </div>
              <div className="col-auto">
                <img src="https://sassets.solv.technology/brand/solv-logo-with-tagline-color-dark-theme.svg" width="auto" height="60"/>
              </div>
            </div>
            {
              version?.summary &&
                <div className="row pt-3">
                  <div className="col-12">
                    {version.summary}
                  </div>
                </div>
            }
            <div className="row pt-3">
              <div className="col-12 d-flex flex-column gap-1">
                <ul className={whatsNewStyles["features-toc"]}>
                {
                  version?.releaseNotes?.map((rn, index) =>
                    canShow(rn.targetLevel) ? (
                      // <div className="d-flex flex-row gap-1 align-items-center">
                        <li>
                          <div className="d-flex flex-row gap-1 align-items-center">
                            {/*<TagLabel className="tag-label-warning" label={rn.changeType ?? "NEW"} size="sm"/>*/}
                            <a className="text-link" href={`#${rn.slug}`} style={{color: "#fff"}}>
                              {rn.title}
                            </a>
                          </div>
                        </li>
                      // </div>
                    ) : (
                      <></>
                    )
                  )
                }
                </ul>
              </div>
            </div>
          </div>
        </div>

        {
          version?.releaseNotes?.map((rn, index) =>
            canShow(rn.targetLevel) ? (
              <>
                <div className={`row pt-0 pr-4 pb-2 pl-4`}>
                  <div className="col-12">
                    <div className={`d-flex flex-column gap-1 pt-4 ${whatsNewStyles["title"]}`}>
                      {
                        renderTitle(rn)
                      }
                      <TagBox>
                        <TagLabel className="tag-label-warning" label={rn.changeType ?? "NEW"} size="md"/>
                        {
                          rn.categories?.sort()?.map(l =>
                            <TagLabel className="tag-label-done" label={l} size="md"/>
                          )
                        }
                        {
                          ["RESELLER", "SYSTEM"].includes(session.user?.userTypeId) && rn.targetLevel &&
                            <TagLabel label={rn.targetLevel} size="md"/>

                        }
                      </TagBox>
                    </div>
                  </div>
                </div>
                <div className={`row  pt-1 pl-4 pr-4 pb-5`} style={{borderBottom: "1px solid #333"}}>
                  <div className={`col-sm`}>
                    <div className={whatsNewStyles["content"]} dangerouslySetInnerHTML={{ __html: markdown.toHtml(rn.text)}}/>
                  </div>
                  {
                    rn.featuredImage &&
                      <div className={`col-sm-auto`}>
                        <div className={versionStyles["image-container"]}>
                          <VersionFeaturedImage versionCode={version.versionCode} featuredImage={rn.featuredImage}/>
                        </div>
                      </div>
                  }
                </div>
              </>
            ) : (
              <></>
            )
          )
        }
      </div>
    </MainContainer>
  )
}



